import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 800, maxWidth: 991 });
  return isTablet ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 799 });
  return isMobile ? children : null;
};

function ScreenType() {
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 776px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 799px)' }); //
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 991 });

  return { isTabletOrDesktop, isMobile, isTabletOrMobile, isDesktop, isTablet };
}

export default { Desktop, Mobile, Tablet, ScreenType };

// import { useEffect, useRef } from 'react';

// import { useMediaQuery } from 'react-responsive';

// function useIsMounted() {
//   const isMounted = useRef(false);

//   useEffect(() => {
//     isMounted.current = true;

//     return () => {
//       isMounted.current = false;
//     };
//   }, []);

//   return isMounted.current;
// }

// const Desktop = ({ children }) => {
//   const isDesktop = useMediaQuery({ minWidth: 1025 });
//   const isMounted = useIsMounted();

//   return isDesktop && isMounted ? children : null;
// };

// const Tablet = ({ children }) => {
//   const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
//   const isMounted = useIsMounted();

//   return isMounted && isTablet ? children : null;
// };

// const Mobile = ({ children }) => {
//   const isMobile = useMediaQuery({ maxWidth: 481 });

//   return isMobile ? children : null;
// };

// function ScreenType() {
//   const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 767px)' });
//   const isMobile = useMediaQuery({ query: '(max-width: 481px)' });
//   const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
//   const isDesktop = useMediaQuery({
//     query: '(min-width: 1025px)',
//   });
//   const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });

//   return { isTabletOrDesktop, isMobile, isTabletOrMobile, isDesktop, isTablet };
// }

// export default { Desktop, Mobile, Tablet, ScreenType };
