import React, { useState, useEffect, useContext } from 'react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ButtonLoader from 'components/Shared/ButtonLoader';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import classnames from 'classnames';

const Paymentpopup = ({ closeModal, visible }) => {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const [mounted, setMounted] = useState(false);
  const { employeeId } = new useEmployeeId();
  const { getParam, showPageId } = useQueryMethods();
  let customCheckout = null;
  var isCardNumberComplete = false;
  var isCVVComplete = false;
  var isExpiryComplete = false;
  let { isLoading } = userStore;

  useEffect(() => {
    if (visible && !mounted) {
      createInputs();
      addListeners();
      setMounted(true);
    }
  }, [visible]);

  const createInputs = () => {
    customCheckout = window.customcheckout();
    var options = {};

    // Create and mount the inputs
    options.placeholder = 'Card number';
    customCheckout.create('card-number', options).mount('#card-number');

    options.placeholder = 'CVV';
    customCheckout.create('cvv', options).mount('#card-cvv');

    options.placeholder = 'MM / YY';
    customCheckout.create('expiry', options).mount('#card-expiry');
  };

  const removeListeners = () => {
    document.getElementById('checkout-form').removeEventListener('submit', onSubmit);
  };

  const addListeners = () => {
    // listen for submit button
    if (document.getElementById('checkout-form') !== null) {
      document.getElementById('checkout-form').addEventListener('submit', onSubmit);
    }

    customCheckout.on('brand', event => {
      var cardLogo = 'none';
      if (event.brand && event.brand !== 'unknown') {
        var filePath = 'https://cdn.na.bambora.com/downloads/images/cards/' + event.brand + '.svg';
        cardLogo = 'url(' + filePath + ')';
      }
      document.getElementById('card-number').style.backgroundImage = cardLogo;
    });

    customCheckout.on('empty', event => {
      if (event.empty) {
        if (event.field === 'card-number') {
          isCardNumberComplete = false;
        } else if (event.field === 'cvv') {
          isCVVComplete = false;
        } else if (event.field === 'expiry') {
          isExpiryComplete = false;
        }
        setPayButton(false);
      }
    });

    customCheckout.on('complete', event => {
      if (event.field === 'card-number') {
        isCardNumberComplete = true;
        hideErrorForId('card-number');
      } else if (event.field === 'cvv') {
        isCVVComplete = true;
        hideErrorForId('card-cvv');
      } else if (event.field === 'expiry') {
        isExpiryComplete = true;
        hideErrorForId('card-expiry');
      }

      setPayButton(isCardNumberComplete && isCVVComplete && isExpiryComplete);
    });

    customCheckout.on('error', function (event) {
      if (event.field === 'card-number') {
        isCardNumberComplete = false;
        showErrorForId('card-number', event.message);
      } else if (event.field === 'cvv') {
        isCVVComplete = false;
        showErrorForId('card-cvv', event.message);
      } else if (event.field === 'expiry') {
        isExpiryComplete = false;
        showErrorForId('card-expiry', event.message);
      }
      setPayButton(false);
    });
  };

  const onSubmit = event => {
    store.userStore.setLoader(true);

    event.preventDefault();
    setPayButton(false);

    var callback = function (result) {
      if (result.error) {
        processTokenError(result.error);
      } else {
        processTokenSuccess(result.token);
      }
    };

    customCheckout.createToken(callback);
  };

  const hideErrorForId = id => {
    var element = document.getElementById(id);

    if (element !== null) {
      var errorElement = document.getElementById(id + '-error');
      if (errorElement !== null) {
        errorElement.innerHTML = '';
      }

      var bootStrapParent = document.getElementById(id + '-bootstrap');
      if (bootStrapParent !== null) {
        bootStrapParent.className = 'form-group has-feedback has-success';
      }
    }
  };

  const showErrorForId = (id, message) => {
    var element = document.getElementById(id);

    if (element !== null) {
      var errorElement = document.getElementById(id + '-error');
      if (errorElement !== null) {
        errorElement.innerHTML = message;
      }

      var bootStrapParent = document.getElementById(id + '-bootstrap');
      if (bootStrapParent !== null) {
        bootStrapParent.className = 'form-group has-feedback has-error ';
      }
    }
  };

  const setPayButton = enabled => {
    var payButton = document.getElementById('pay-button');
    if (enabled) {
      payButton.disabled = false;
      payButton.className = 'btn btn-primary';
    } else {
      payButton.disabled = true;
      payButton.className = 'btn btn-primary disabled';
    }
  };

  const processTokenError = error => {
    error = JSON.stringify(error, undefined, 2);

    store.userStore.setLoader(false);

    setPayButton(true);
  };

  const processTokenSuccess = token => {
    store.userStore.setLoader(false);
    setPayButton(true);

    addCardToProfile(token);
  };

  const addCardToProfile = token => {
    let payload = {
      token,
      user_id: showPageId(),
    };
    store.userStore.setLoader(true);

    store.userManagementStore
      .addEmployeeCard(payload, { employee_id: employeeId, user_id: showPageId() })
      .then(response => {
        store.userStore.setLoader(false);
        closeModal();
      });
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px', minHeight: '20%' }}
        aria-labelledby="modal-headline">
        <div className="overflow-scroll max-h-screen p-8">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <strong className="font-inter-semibold text-lg">Add a Card</strong>

          <div className="mt-5">
            <div className="container">
              <form id="checkout-form">
                <div id="card-number"></div>
                <label for="card-number" id="card-number-error"></label>

                <div id="card-cvv"></div>
                <label for="card-cvv" id="card-cvv-error"></label>

                <div id="card-expiry"></div>
                <label for="card-expiry" id="card-expiry-error"></label>

                <input
                  autoComplete="off"
                  id="pay-button"
                  type="submit"
                  className="btn disabled"
                  value="Add Card"
                  disabled="true"
                />

                {isLoading && (
                  <div className="d-row w-full justify-center my-4">
                    <ButtonLoader dark />
                  </div>
                )}
              </form>
            </div>
          </div>

          <p className="text-xxs font-inter-regular leading-light text-center text-dark-gray mt-12">
            We use Bambora Inc. to process all credit card payments. Processing a payment or saving
            a payment method to your profile does not provide hungerhub Canada Inc. with access to
            nor allow for the retention of any credit card information.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Paymentpopup;
