import React, { useEffect, useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import Screen from 'utils/helpers/ScreenSize';
import classnames from 'classnames';

const roles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Employee', value: 'employee' },
];

const _dummyData = [
  { name: 'Rohan Admin', email: 'rohan3+freshchat@tuffmail.com', team: 'Front End' },
  { name: 'Rohan Admin', email: 'rohan3+freshchat@tuffmail.com', team: 'Front End' },
  { name: 'Rohan Admin', email: 'rohan3+freshchat@tuffmail.com', team: 'Front End' },
];

function MealMgmtBill({ visible, closeModal, employeeDetails, removeEmployee }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const screenType = Screen.ScreenType();
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    changePassword: '',
    role: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (employeeDetails?.id) {
      dispatch({ field: 'phone', value: employeeDetails.phone });
      dispatch({ field: 'email', value: employeeDetails.email });
      dispatch({ field: 'firstName', value: employeeDetails.first_name });
      dispatch({ field: 'lastName', value: employeeDetails.last_name });
      dispatch({
        field: 'role',
        value: { label: employeeDetails.role, value: employeeDetails.role },
      });
    } else {
      dispatch({ type: 'reset', payload: initialState });
      dispatch({ field: 'role', value: roles[0] });
    }
  }, [employeeDetails]);

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down h-5/6"
        role="dialog"
        aria-modal="true"
        style={{ minWidth: screenType.isDesktop ? '40%' : '95%' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <span className="font-inter-semibold text-lg my-5">Billing Details</span>
          <label className="flex items-center mt-2">
            <input
              autoComplete="off"
              // checked={billableDropdown}
              // onClick={e => setBillableDropdown(e.target.checked)}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-2 text-xs">Bill by Team</span>
          </label>
          <label className="flex items-center my-2">
            <input
              autoComplete="off"
              // checked={billableDropdown}
              // onClick={e => setBillableDropdown(e.target.checked)}
              type="checkbox"
              className="form-checkbox"
            />
            <span className="ml-2 text-xs">Bill by Billable</span>
          </label>
          <span className="font-inter-semibold text-sm">
            Bill to: <small className="input-label text-sm">hungerhub</small>{' '}
          </span>
          <span className="font-inter-semibold text-sm my-2">
            Bill Date: <small className="input-label text-sm">December 5, 2021</small>{' '}
          </span>
          <span className="font-inter-semibold text-sm">
            Billing Email: <small className="input-label text-sm">hi@compnay.com</small>{' '}
          </span>

          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg my-3">
            <table className="w-full md:table-fixed">
              <thead className="bg-white border-b">
                <tr>
                  <th
                    scope="col"
                    className="t-head px-2 border-r text-left"
                    style={{ width: screenType.isMobile ? '5%' : '30%' }}>
                    <small className="t-row">Name</small>
                  </th>
                  <th
                    scope="col"
                    className="t-head px-2 border-r text-left"
                    style={{ width: screenType.isMobile ? '10%' : '30%' }}>
                    <small className="t-row">Orders</small>
                  </th>
                  <th
                    scope="col"
                    className="t-head px-2 border-r text-left"
                    style={{ width: screenType.isMobile ? '20%' : '10%' }}>
                    <small className="t-row">Food Total</small>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {_dummyData?.map(({ name, email, team }) => (
                  <tr key={0} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                    <td className="py-3 px-2 align-middle font-inter-regular text-xs text-dark-gray break-words text-left">
                      Abdul Testing Restaurant
                    </td>

                    <td className="py-3 px-2 align-middle d-col">
                      <small className="input-label">Super Stack</small>
                      <small className="input-label mt-1">Bread: Whole Wheat</small>
                    </td>

                    <td className="py-3 px-2 align-middle break-words text-left">
                      <small className="input-label">$20.00</small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* <NotFound data={dummyData} message="No Users found" /> */}
          </div>

          <div className="bg-gray-200 my-4 h-0.5 w-full" />

          <div className="d-col mt-4">
            <div className="d-row justify-between">
              <span className="font-inter-semibold text-sm">Total:</span>
              <small className="input-label text-sm">$ 34.00</small>{' '}
            </div>
            <div className="d-row justify-between mt-2">
              <span className="font-inter-semibold text-sm">Tip:</span>
              <small className="input-label text-sm">$ 34.00</small>{' '}
            </div>
            <div className="d-row justify-between mt-2">
              <span className="font-inter-semibold text-sm">Service Fee:</span>
              <small className="input-label text-sm">$ 34.00</small>{' '}
            </div>
            <div className="d-row justify-between mt-2">
              <span className="font-inter-semibold text-sm">Delivery Fee:</span>
              <small className="input-label text-sm">$ 34.00</small>{' '}
            </div>
            <div className="d-row justify-between mt-2">
              <span className="font-inter-semibold text-sm">Tax:</span>
              <small className="input-label text-sm">$ 34.00</small>{' '}
            </div>
            <div className="d-row justify-between mt-2">
              <span className="font-inter-semibold text-sm">Grand Total:</span>
              <small className="input-label text-sm">$ 34.00</small>{' '}
            </div>
          </div>

          <button
            onClick={() => {
              closeModal();
            }}
            className="absolute bottom-4 right-4 btn-purple text-xs">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default MealMgmtBill;
