import React, { useContext, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { createBrowserHistory } from 'history';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import Role from 'config/roles';
import object from 'utils/helpers/object';
import PropagateLoader from 'react-spinners/PropagateLoader';
import LocalStorage from 'utils/helpers/localStorage';
import { ActionCableProvider } from 'react-actioncable-provider';
import useEmployeeId from 'utils/helpers/user';
import { ActionCableAlert } from 'components/Alerts';

import Layout from 'components/Layout';
const Login = React.lazy(() => import('containers/Authentication/Login'));
const PasswordReset = React.lazy(() => import('containers/Authentication/PasswordReset'));
const ForgotPassword = React.lazy(() => import('containers/Authentication/ForgotPassword'));
const Profile = React.lazy(() => import('containers/Profile/Profile'));
const PageNotFound = React.lazy(() => import('components/PageNotFound/PageNotFound'));
const ErrorAlert = React.lazy(() => import('components/Alerts/ErrorAlert'));
const NewAccount = React.lazy(() => import('containers/Authentication/NewAccount'));

const Loading = () => (
  <div className="d-row min-h-screen justify-center items-center">
    <PropagateLoader color={'#DA2D8B'} loading={true} size={15} />
  </div>
);

const RoutesPath = ({ role = null, currentUser = null }) => {
  let defaultRoute = role ? Role[role]?.routes[0] : null;

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <PublicRoute restricted component={Login} path="/login" exact />
        <PublicRoute restricted component={ForgotPassword} path="/forgot-password" exact />
        <PublicRoute restricted component={PasswordReset} path="/reset-password" exact />
        <PrivateRoute restricted component={Profile} path="/profile" exact />
        <PublicRoute restricted component={NewAccount} path="/new-account" exact />

        {role &&
          Role[role]?.routes?.map((route, index) => (
            <PrivateRoute
              restricted
              key={index}
              component={route.component}
              path={route.url}
              exact
            />
          ))}

        {currentUser ? (
          <Redirect from="/" to={defaultRoute ? defaultRoute.url : '/login'} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              search: window.location.search,
              state: { referrer: window.location.pathname },
            }}
          />
        )}

        <Route component={PageNotFound} path="*" />
      </Switch>
    </Suspense>
  );
};

function Routes() {
  const store = useContext(StoreContext);
  const history = createBrowserHistory();
  const userStore = toJS(store.userStore);
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  const { errorMessage, errorAlert, actionToPerform, defaultRegion, currentUser } = userStore;
  const { actionCablePayload } = restaurantManagementStore;
  const { employeeId } = new useEmployeeId();

  const afterCloseCallBack = () => {
    if (actionToPerform === 'navigateLogin') {
      history.push('login');
      window.location.reload();
    }
    store.userStore.setErrorAlert(false, '');
  };

  if (!window.store) window.store = store;

  window.addEventListener('beforeunload', ev => {
    ev.preventDefault();
  });

  useEffect(() => {
    if (!object.isEmpty(currentUser)) store.userStore.getStaffUserProfile();
  }, []);

  let access_token = LocalStorage.getAccessToken();
  let url = `wss://${process.env.REACT_APP_WEB_SOCKET_URL}/cable?employee_id=${employeeId}&subscribing_from=staff_app&access_token=${access_token}`;

  return (
    <div>
      <Router history={history}>
        <React.Fragment>
          <Suspense fallback={<Loading />}>
            <ActionCableProvider key={employeeId} url={url}>
              <div>
                <ErrorAlert
                  visible={errorAlert}
                  errorMessage={errorMessage}
                  dismissAlert={afterCloseCallBack}
                />
                {currentUser?.data && (
                  <Layout
                    routes={
                      <RoutesPath role={defaultRegion?.role} currentUser={currentUser?.data} />
                    }
                  />
                )}

                {!access_token && <RoutesPath key={currentUser?.data} />}

                {actionCablePayload?.success &&
                  actionCablePayload?.notification_for !== 'overwrite_menu' && (
                    <ActionCableAlert visible={true} />
                  )}
              </div>
            </ActionCableProvider>
          </Suspense>
        </React.Fragment>
      </Router>
    </div>
  );
}

export default observer(Routes);
