import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class AccountManagementStore {
  accounts = {};
  accountDetail = null;
  orders = [];
  date = new Date();
  officeEmployees = [];
  officeAdmins = [];
  officeTeams = [];
  locationMenus = [];
  mealSeries = [];
  meals = null;
  organisedMeals = [];
  locationRestaurants = [];
  selectedRestaurantList = [];
  locationInvoices = [];
  officeEmployeesPagy = null;
  officeAdminsPagy = null;
  officeEmployeeNames = [];
  dropoffRequirementList = [];

  officeGroupOrders = null;
  officeGroupOrdersPagy = null;

  constructor() {
    makeObservable(this, {
      accounts: observable,
      accountDetail: observable,
      officeEmployees: observable,
      officeAdmins: observable,
      officeTeams: observable,
      locationMenus: observable,
      mealSeries: observable,
      orders: observable,
      date: observable,
      setDate: action,
      locationInvoices: observable,
      officeEmployeesPagy: observable,
      officeAdminsPagy: observable,
      officeEmployeeNames: observable,
      dropoffRequirementList: observable,
      officeGroupOrders: observable,
      officeGroupOrdersPagy: observable,
    });
  }

  async searchAccounts(params) {
    this.restaurants = {};
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.clients()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.accounts = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAllAccounts(params) {
    this.restaurants = {};
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.clients()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addAccount(payload, params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.clients()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateAccount(locationId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.companyProfile()}/${locationId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getAccount(locationId, params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAccount(locationId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.clients()}/${locationId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.accountDetail = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async exportInvoiceReport(params) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.invoiceReport()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: 'Report generated',
            body: `Usage report generated from ${params.start_date} to ${params.end_date} and emailed.`,
          });
          return data;
        }),
        action('fetchError', error => {
          if (error.response.status === 422) {
            alertHandler({
              title: error.response?.data?.message,
              body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
            });
          } else
            alertHandler({
              title: 'Unable to generate invoice report',
              body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
            });
          return error;
        })
      );
  }

  async getLocationInvoices(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.invoiceReport()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.locationInvoices = data?.data;
          return data;
        }),
        action('fetchError', error => {
          return error;
        })
      );
  }

  async getAllOrders(params) {
    this.orders = [];
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.orders()}.json?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          let orders = data.data?.filter(order => order.attributes.status !== 'cancelled');
          this.orders = orders;

          return orders;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async cancelOrder(orderId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.orders()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAllEmployees(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.companyEmployees()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.officeEmployeesPagy = data?.data?.pagy;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOrderDetails(orderId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.orders()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getETA(orderId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.companyETA()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  setDate(date) {
    this.date = date;
  }

  updateEmployees(employees) {
    this.officeEmployees = employees;
  }

  async getAllOfficeEmployees(params, teamFilter = 'All', selectAll = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(
        `${api.officeEmployee()}?${paramsString}${
          teamFilter && teamFilter !== 'All' ? `&team_id_eq=${encodeURIComponent(teamFilter)}` : ''
        }`
      )
      .then(
        action('fetchSuccess', ({ data }) => {
          let fetchedEmployees = data?.data?.map(employee => {
            return {
              ...employee,
              active_weekly_subsidy: employee.attributes.subsidy_type == 'weekly' ? true : false,
              checked: selectAll,
            };
          });

          this.officeEmployees = [...this.officeEmployees, fetchedEmployees].flat();
          this.officeEmployeesPagy = data.pagy;
          return fetchedEmployees;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  resetOfficeEmployees() {
    this.officeEmployees = [];
    this.officeEmployeeNames = [];
  }

  async getAllOfficeEmployeeNames(params, teamFilter = 'All') {
    let paramsString = qs.stringify(params);

    return client()
      .get(
        `${api.officeEmployee()}?${paramsString}${
          teamFilter && teamFilter !== 'All' ? `&team_id_eq=${encodeURIComponent(teamFilter)}` : ''
        }`
      )
      .then(
        action('fetchSuccess', ({ data }) => {
          this.officeEmployeeNames = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateOfficeEmployee(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.officeEmployee()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateBulkOfficeEmployees(params, payload, chunks) {
    let paramsString = qs.stringify(params);

    return Promise.all(
      chunks.map(chunk => {
        let _payload = payload;
        _payload = { ...payload, emp_ids: chunk };
        let url = client().put(`${api.officeEmployee()}?${paramsString}`, _payload);
        return url;
      })
    ).then(
      axios.spread((...allData) => {
        return allData;
      })
    );
  }

  async deleteBulkOfficeEmployees(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.officeEmployee()}?${paramsString}`, { data: payload })
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployee(params, id) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.officeEmployee()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOfficeTeams(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.officeTeams()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.officeTeams = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addTeam(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.officeTeams()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getOfficeTeams(params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteTeam(id, params) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.officeTeams()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getOfficeTeams(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOfficeAdmins(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.officeEmployee()}?${paramsString}&location_employee_role_eq=1`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.officeAdmins = data?.data;
          this.officeAdminsPagy = data?.pagy;

          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async assignEmployeeToTeams(empId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.employeeTeams()}/${empId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getOfficeTeams(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployeeFromTeam(empId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.employeeTeams()}/${empId}?${paramsString}`, { data: payload })
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getOfficeTeams(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateTeam(teamId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.officeTeams()}/${teamId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getOfficeTeams(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async massAssignmentToTeam(teamId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.teamAssignments()}/${teamId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.getOfficeTeams(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  //*********Available Restaurants*************

  async getAllMenus(locationId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.locationMenus()}/${locationId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.locationMenus = data?.available_menus;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addMenu(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.locationMenus()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteMenu(locationId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.locationMenus()}/${locationId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  //**********Meal Management***********/

  async getAllMealSeries(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.mealSeries()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.mealSeries = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getMeals(params) {
    let sortedData = [];
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.meals()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.meals = data?.data;

          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getLocationRestaurants(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.locationRestaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.locationRestaurants = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateBulkMeals(locationId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.clients()}/${locationId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async clearMeal() {
    this.meals = null;
  }

  setSelectedRestaurantList(list) {
    this.selectedRestaurantList = list;
  }

  //*********Account Billing*************

  async saveAccountBillingDetails(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.accountBillings()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateAccountBillingDetails(locationId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.accountBillings()}/${locationId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getBillingDetails(locationId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.accountBillings()}/${locationId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createNewInvoice(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.invoiceReport()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getDropoffRequirements(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.dropoffRequirement()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.dropoffRequirementList = data?.data?.map(requirement => {
            return { label: requirement?.name, value: requirement.id };
          });
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async getPendingGroupOrders(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.officeGroupOrders()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.officeGroupOrders = data?.group_orders;
          this.officeGroupOrdersPagy = data?.pagy;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default AccountManagementStore;
