import moment from 'moment';
import Validation from 'utils/helpers/validation';
import { EmbedHTML } from 'components/Shared';

//for the restaurant orders (pick up)
const extractETA = data => {
  // Do not change the order of these if statements
  // This is to catch a special case when we don't want to display any ETA info
  let now = moment(new Date());
  let eta = moment(data?.pickup_eta).utc();
  let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());

  if (!data?.pickup_eta) return 'ETA not set';
  if (data?.pickup_started && !data?.pickup_eta) return 'Updating ETA';

  if (data?.delivered) return 'Completed';
  if (data?.picked_up) return 'In Transit';
  if (data?.pickup_delay_reason && data?.pickup_delay_reason?.replace(/\s/g, '') !== '')
    return data?.pickup_delay_reason;
  if (duration < 5) return 'Arriving';
  else return `ETA ${duration} Minutes`;
};

//for office user orders (drop off)
export const extractOfficeUserOrderETA = data => {
  // Do not change the order of these if statements
  // This is to catch a special case when we don't want to display any ETA info

  if (data?.picked_up && !data?.delivery_started) return 'In transit';

  if (!data?.delivery_eta) return 'ETA not set';
  if (data?.delivery_started && !data?.delivery_eta) return 'Updating ETA';

  if (!data?.delivery_started && data?.delivered) return 'ORDER SENT TO RESTAURANT';
  if (data?.delivered) return 'ORDER DELIVERED';
  if (!data?.delivery_eta) return 'ORDER SENT TO RESTAURANT';
  if (data?.delivery_delay_reason && data?.delivery_delay_reason.trim() !== '')
    return data?.delivery_delay_reason;
  let now = moment(new Date());
  let eta = moment(data?.delivery_eta).local();
  let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());

  if (duration > 45) return 'ETA ' + moment(eta).format('h:mm a');
  else if (duration > 2) return 'ETA ' + duration + ' minutes';
  else if (duration <= 2) return 'ORDER ARRIVING';
};

const filterOrdersPrepare = (search, data) => {
  return data?.data?.filter(item => {
    return (
      item.attributes?.bag_number === Number(search) ||
      item?.attributes?.order_items?.length === Number(search) ||
      item.attributes?.permanent_instructions?.toLowerCase().includes(search.toLowerCase()) ||
      item.attributes?.user_name.toLowerCase().includes(search.toLowerCase()) ||
      item.attributes.order_items.filter(orderItem => {
        return orderItem?.attributes?.category?.toLowerCase().includes(search.toLowerCase());
      }).length > 0 ||
      item.attributes.order_items.filter(orderItem => {
        return orderItem?.attributes?.menu_item?.title
          ?.toLowerCase()
          .includes(search.toLowerCase());
      }).length > 0 ||
      item.attributes.order_items.filter(orderItem => {
        return orderItem?.attributes?.special_instructions
          ?.toLowerCase()
          .includes(search.toLowerCase());
      }).length > 0 ||
      item.attributes.order_items.filter(orderItem => {
        return (
          orderItem?.attributes?.order_item_options?.filter(option =>
            option?.attributes?.option_name?.toLowerCase().includes(search.toLowerCase())
          ).length > 0
        );
      }).length > 0
      // || item.attributes?.order_items.filter(orderItem => {
      //   return Number(orderItem?.attributes?.price) === Number(search);
      // }).length > 0
    );
  });
};

const priceWithOptions = (basePrice, options) => {
  let total = Number(basePrice);
  options?.map(({ attributes: { price } }) => (total += Number(price)));
  return Validation.priceFormatter(total);
};

const simplePriceWithOptions = (basePrice, options) => {
  let total = Number(basePrice);
  options?.map(({ attributes: { price } }) => (total += Number(price)));
  return total;
};

const getOpacity = status => {
  return status === 'cancelled' ? 'opacity-50' : '';
};

const getLabelName = item => {
  let label = item?.menu_item?.display_name_with_html || item?.menu_item?.title;

  if (item?.order_item_options.length > 0)
    label +=
      ' - ' + item?.order_item_options.map(option => option.attributes.option_name).join(', ');

  return label;
};

const getLabel = (attributes, sortedData) => {
  let label =
    attributes.notes != '' && attributes.notes.split('#@').length + 1 == attributes.quantity
      ? `${attributes.quantity} - ${getLabelName(attributes)}`
      : `${
          attributes.notes != ''
            ? attributes.notes.split('#@').length + 1
            : attributes.notes.split('#@').length
        }-${attributes.quantity} - ${getLabelName(attributes)} -  ${sortedData.destination_code}`;

  return <EmbedHTML text={label} color="text-black" />;
};

const showExtraLabel = (attributes, sortedData) => {
  return (
    <>
      <>
        {attributes?.notes == '' && attributes?.quantity == 1 && (
          <p className="overflow-ellipsis text-black overflow-hidden mb-2 text-xs lg:text-xs font-inter-regular">
            <EmbedHTML
              text={`${attributes.quantity} - ${getLabelName(attributes)}`}
              color="text-black"
            />
          </p>
        )}
      </>

      {attributes?.notes?.split('#@').length < attributes?.quantity && (
        <p className="overflow-ellipsis text-black overflow-hidden mb-2 text-xs lg:text-xs font-inter-regular">
          {getLabel(attributes, sortedData)}
        </p>
      )}
    </>
  );
};

export const getStatus = status => {
  switch (status) {
    case 'pending':
      return 'Order Placed';
    case 'preview':
      return 'Sent to Restaurant';
    case 'sent':
      return 'Sent to Restaurant';
    case 'confirmed':
      return 'Tap For ETA';
    case 'completed':
      return 'Completed';
    case 'order completed':
      return 'Completed';
    case 'cancelled':
      return 'Cancelled';
    default:
      return status;
  }
};

export const getOrderStatus = status => {
  switch (status) {
    case 'pending':
      return 'Order Placed';
    case 'preview':
      return 'Sent to Restaurant';
    case 'sent':
      return 'Sent to Restaurant';
    case 'confirmed':
      return 'Sent to Restaurant';
    case 'completed':
      return 'Completed';
    case 'order completed':
      return 'Completed';
    case 'cancelled':
      return 'Cancelled';
    default:
      return status;
  }
};

const getCateringOrderStatus = shipment_acceptance_status => {
  return (
    <strong
      className={`font-inter-medium text-xs ${
        shipment_acceptance_status ? 'text-green-700' : 'text-red-700'
      }`}>
      {shipment_acceptance_status ? 'Confirmed' : 'Pending'}
    </strong>
  );
};

export const isGroupOrder = order => {
  return order?.type == 'group_order';
};

export const getTotalItemsForGroupOrder = order => {
  let totalItems = 0;

  order?.attributes?.attendees?.map(attendee => {
    totalItems += attendee?.group_order_items?.length;
  });

  return totalItems;
};

export const checkIfCapacityReached = str => {
  return str?.includes('cannot accept additional orders');
};

export default {
  extractETA,
  extractOfficeUserOrderETA,
  filterOrdersPrepare,
  priceWithOptions,
  simplePriceWithOptions,
  getOpacity,
  getStatus,
  getCateringOrderStatus,
  getOrderStatus,
  getLabelName,
  getLabel,
  showExtraLabel,
  checkIfCapacityReached,
};
