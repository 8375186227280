import { makeObservable, observable, action } from 'mobx';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import { alertHandler } from 'utils/middlewares/alertHandler';

class ClientRankStore {
  clientRanks = [];

  constructor() {
    makeObservable(this, {
      clientRanks: observable,
    });
  }

  async getClientRanks(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.clientRank()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.clientRanks = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateClientRank(locationId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.clientRank()}/${locationId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default ClientRankStore;
