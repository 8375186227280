import React, { useEffect, useReducer, useMemo, useContext } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import Screen from 'utils/helpers/ScreenSize';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { useQueryMethods } from 'hooks/useQueryMethods';
import useEmployeeId from 'utils/helpers/user';
import classnames from 'classnames';

function AddAdmin({
  visible,
  closeModal,
  officeAdmin,
  getOfficeAdmins,
  getEmployees,
  getOfficeTeams,
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const screenType = Screen.ScreenType();
  const accountManagementStore = toJS(store.accountManagementStore);
  const { officeEmployees, officeAdmins, officeTeams } = accountManagementStore;
  let { showPageId } = useQueryMethods();
  let { employeeId } = useEmployeeId();

  const initialState = {
    officeEmployees: [],
    officeTeams: [],
    officeAdmins: [],
    selected: null,
    error: '',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (visible) {
      dispatch({ type: 'reset', payload: initialState });

      dispatch({
        field: 'officeTeams',
        value: officeTeams.map(team => {
          return {
            ...team,
            checked: false,
          };
        }),
      });

      dispatch({
        field: 'officeEmployees',
        value: officeEmployees.map(emp => {
          return {
            label: `${emp.attributes.first_name} ${emp.attributes.last_name}`,
            value: emp.id,
            id: emp.id,
            name: `${emp.attributes.first_name} ${emp.attributes.last_name}`,
            first_name: emp.attributes.first_name,
            last_name: emp.attributes.last_name,
            email: emp.attributes.email,
            administered_teams: emp.attributes.administered_teams,
          };
        }),
      });

      dispatch({
        field: 'officeAdmins',
        value: officeAdmins.map(admin => admin.id),
      });
    }
  }, [visible]);

  useMemo(() => {
    if (officeAdmin) {
      dispatch({
        field: 'officeEmployees',
        value: provider.officeEmployees.filter(emp => !provider.officeAdmins.includes(emp.id)),
      });
    }
  }, [provider.officeEmployees?.length, provider.officeAdmins?.length]);

  useEffect(() => {
    if (provider?.selected) {
      provider?.selected.administered_teams?.forEach(team => {
        if (document.getElementById('checkbox-' + team.id)) {
          document.getElementById('checkbox-' + team.id).checked = true;
          document.getElementById('checkbox-' + team.id).disabled = true;
        }
      });
    }
  }, [provider?.selected]);

  const checkTeam = (value, teamId) => {
    let updatedList = provider.officeTeams.map(team => {
      if (team.id == teamId) {
        return {
          ...team,
          checked: value,
        };
      }
      return team;
    });

    dispatch({
      field: 'officeTeams',
      value: updatedList,
    });
  };

  const assignToTeams = () => {
    if (!provider.selected) {
      alertHandler({
        title: 'Select employee to make an admin for a team.',
      });
      return;
    }

    let selectedTeams = provider.officeTeams.filter(team => team.checked).map(team => team.id);

    if (selectedTeams.length == 0) {
      alertHandler({
        title: 'Select a team for which employee will be an admin.',
      });
      return;
    }

    let payload = {
      team_ids: selectedTeams,
      location_id: showPageId(),
      admin: true,
    };

    store.userStore.setLoader(true);

    store.accountManagementStore
      .assignEmployeeToTeams(
        provider.selected.id,
        {
          employee_id: employeeId,
          location_id: showPageId(),
        },
        payload
      )
      .then(response => {
        closeModal();

        getEmployees();
        getOfficeAdmins();
        alertHandler({
          title: response.messages,
        });
        store.userStore.setLoader(false);
      });
  };

  const addOfficeAdmin = () => {
    if (!provider.selected) {
      alertHandler({
        title: 'Select an employee',
      });
      return;
    }

    const params = {
      id: provider.selected.id,
      location_employee: {
        role: 'admin',
      },
    };

    store.userStore.setLoader(true);
    store.accountManagementStore
      .updateOfficeEmployee(
        provider.selected.id,
        { employee_id: employeeId, location_id: showPageId() },
        params
      )
      .then(response => {
        closeModal();
        getOfficeAdmins();
        getEmployees();
        getOfficeTeams();
        store.userStore.setLoader(false);
      });
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down h-1/2 md:h-5/6"
        role="dialog"
        aria-modal="true"
        style={{ minWidth: screenType.isDesktop ? '50%' : '90%' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <span className="font-inter-semibold text-lg">
            {officeAdmin ? 'Add Office Admin' : 'Add Admin'}
          </span>

          <div className="w-full md:w-1/2 d-col mt-5" key={provider.officeEmployees.length}>
            <small className="font-inter-regular">Select Employee</small>

            <Select
              placeholder="Select Employee"
              className="input-label capitalize overflow-visible mt-1"
              options={provider.officeEmployees}
              isClearable
              value={provider.selected}
              onChange={selected => {
                provider?.officeTeams?.forEach(team => {
                  if (document.getElementById('checkbox-' + team.id))
                    document.getElementById('checkbox-' + team.id).disabled = false;
                });

                dispatch({ field: 'selected', value: selected });
                dispatch({ field: 'error', value: '' });
              }}
            />
          </div>

          {!officeAdmin && provider.selected && (
            <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg mt-7">
              <table className="w-full md:table-fixed">
                <thead className="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      className="t-head text-left px-2 border-r"
                      style={{ width: screenType.isMobile ? '5%' : '30%' }}>
                      <small className="t-row">Team</small>
                    </th>

                    <th
                      scope="col"
                      className="t-head px-2 border-r text-left"
                      style={{ width: screenType.isMobile ? '20%' : '70%' }}>
                      <small className="t-row">Admin Acces</small>
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {provider.officeTeams?.map(team => (
                    <tr key={team.id} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                      <td className="py-3 px-2 align-middle break-words text-left">
                        <div className="d-row">
                          <small className="text-xs ml-0 md:ml-2 text-black font-inter-regular capitalize">
                            {team.attributes.name}
                          </small>
                        </div>
                      </td>

                      <td className="py-3 px-2 align-middle break-words text-left">
                        <div>
                          <label className="inline-flex items-center">
                            <input
                              autoComplete="off"
                              id={`checkbox-${team.id}`}
                              type="checkbox"
                              className="form-checkbox"
                              checked={team.checked}
                              value={team.checked}
                              onChange={e => checkTeam(!team.checked, team.id)}
                            />
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <button
            onClick={() => {
              !officeAdmin ? assignToTeams() : addOfficeAdmin();
            }}
            className="absolute bottom-4 right-4 btn-purple text-xs">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddAdmin;
