import React, { useReducer, useContext, useEffect } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import useEmployeeId from 'utils/helpers/user';

function BamkingInformation({ visible, closeModal, bankDetails }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const { id } = useParams();

  const initialState = {
    transitNumber: '',
    institutionNumber: '',
    accountNumber: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  const submit = e => {
    e.preventDefault();
    let payload = {
      transit_number: provider.transitNumber,
      account_number: provider.accountNumber,
      institution_number: provider.institutionNumber,
    };
    store.restaurantManagementStore
      .updateBankingInformation(id, { employee_id: employeeId }, payload)
      .then(response => {
        if (response?.data?.data) closeModal();
      });
  };

  useEffect(() => {
    dispatch({ field: 'transitNumber', value: bankDetails?.attributes?.transit_number });
    dispatch({
      field: 'institutionNumber',
      value: bankDetails?.attributes?.institution_number,
    });
    dispatch({
      field: 'accountNumber',
      value: bankDetails?.attributes?.account_number?.replace(/\s/g, ''),
    });
  }, [bankDetails]);

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll md:overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <h5 className="font-inter-semibold">Update Banking Information</h5>
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="w-full">
              <small className="font-inter-medium">Transit Number</small>
              <input
                autoComplete="off"
                className="input-light"
                name="transitNumber"
                id="transitNumber"
                type="number"
                onWheelCapture={e => {
                  e.target.blur();
                }}
                value={provider.transitNumber}
                placeholder="Please add transit number"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="w-full">
              <small className="font-inter-medium">Institution Number</small>
              <input
                autoComplete="off"
                className="input-light"
                name="institutionNumber"
                id="institutionNumber"
                value={provider.institutionNumber}
                placeholder="Please add institution number"
                type="number"
                onWheelCapture={e => {
                  e.target.blur();
                }}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="w-full">
              <small className="font-inter-medium">Account Number</small>
              <input
                autoComplete="off"
                className="input-light"
                placeholder="Please add account number"
                name="accountNumber"
                id="accountNumber"
                value={provider.accountNumber}
                type="number"
                onWheelCapture={e => {
                  e.target.blur();
                }}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="items-center flex flex-col pt-2 md:pt-24">
              <button className="btn-purple w-full text-sm" type="submit">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BamkingInformation;
