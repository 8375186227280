import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class RestaurantBatchPaymentStore {
  batchPayments = [];
  batchPaymentsPagy = null;
  batchBills = [];
  skippedReports = [];
  skippedReportsPagy = null;

  constructor() {
    makeObservable(this, {
      batchPayments: observable,
      batchPaymentsPagy: observable,
      batchBills: observable,
      skippedReports: observable,
      skippedReportsPagy: observable,
    });
  }

  async getBatches(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.paymentBatch()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.batchPayments = data?.data?.data;
          this.batchPaymentsPagy = data?.data?.pagy;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateBatch(batchId, params, status) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.paymentBatch()}/${batchId}?${paramsString}`, { status })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getBills(batchId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.batchBills(batchId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.batchBills = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  //bills are same as reports at the backend
  async updateReport(reportId, batchId, params, status) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.paymentBatch()}/${batchId}/reports/${reportId}?${paramsString}`, {
        payment_status: status,
      })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateReportBankindDetails(reportId, batchId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.paymentBatch()}/${batchId}/reports/${reportId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  resetSkippedReports() {
    this.skippedReports = [];
    this.skippedReportsPagy = null;
  }

  async fetchSkippedReports(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.reports()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let reports = data?.data?.reports?.data;

          this.skippedReports = [...this.skippedReports, reports]?.flat();

          this.skippedReportsPagy = data?.data?.pagy;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createNewBatch(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.paymentBatch()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addEditAdjustment(reportId, batchId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.paymentBatch()}/${batchId}/reports/${reportId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default RestaurantBatchPaymentStore;
