import React, { useReducer, useContext, useEffect } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { Dropdown, ModalBackground, SubsidySelector } from 'components/Shared';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { restaurantEmployeeRoles } from 'utils/constants/employee';
import useEmployeeId from 'utils/helpers/user';
import Select from 'react-select';
import { observer } from 'mobx-react';
import employee from 'utils/helpers/employee';
import { useEmployeeMethods } from 'hooks/useEmployeeMethods';
import Screen from 'utils/helpers/ScreenSize';
import classnames from 'classnames';

function CreateAssociatedAccount({ visible, closeModal, userDetails, getUserDetail }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const userManagementStore = toJS(store.userManagementStore);
  const { restaurantList, officeList, officeTeams, employeeDetail } = userManagementStore;
  const { getCreateOfficeUserRole, getOfficeUserSubsidy } = employee;
  let { isLoading } = userStore;
  const screenType = Screen.ScreenType();
  const { isOfficeUser, isRestaurantUser } = useEmployeeMethods();

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    user_type: '',
    account: '',
    role: '',
    locations: [],
    location: null,
    team: null,
    restaurant: null,
    error: '',
    subsidyType: 'daily',
    dailySubsidies: [
      { name: 'monday', value: 0.0 },
      { name: 'tuesday', value: 0.0 },
      { name: 'wednesday', value: 0.0 },
      { name: 'thursday', value: 0.0 },
      { name: 'friday', value: 0.0 },
      { name: 'saturday', value: 0.0 },
      { name: 'sunday', value: 0.0 },
    ],
    weeklySubsidy: 0,

    teamAdmin: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'reset', payload: initialState });

    if (isOfficeUser()) {
      store.accountManagementStore
        .getAllAccounts({ employee_id: employeeId, only_name_and_id: true })
        .then(response => {
          dispatch({
            field: 'locations',
            value: response.data?.map(loc => ({
              label: loc.name,
              value: loc.id,
            })),
          });
        });
    } else store.userManagementStore.getRestaurantList(employeeId);
  }, [userDetails, visible]);

  const submit = e => {
    e.preventDefault();

    if (isRestaurantUser()) {
      if (!provider.restaurant)
        dispatch({
          field: 'error',
          value: 'Please select a restaurant',
        });
      else if (!provider.role)
        dispatch({
          field: 'error',
          value: 'Please select a role',
        });
      else {
        store.userStore.setLoader(true);
        let payload = {
          email: employeeDetail?.attributes?.email,
          first_name: employeeDetail?.attributes?.first_name,
          last_name: employeeDetail?.attributes?.last_name,
          phone: employeeDetail?.attributes?.phone,
          restaurant_employee: {
            role: provider.role.value,
          },
        };

        store.userManagementStore
          .addRestaurantEmployee(
            { employee_id: employeeId, restaurant_id: provider.restaurant.value },
            payload
          )
          .then(response => {
            store.userStore.setLoader(false);

            if (response.employee?.id) {
              closeModal();
              store.userStore.setLoader(false);
              getUserDetail();

              alertHandler({
                title: `Restaurant user created successfully for ${provider.restaurant.label}.`,
                body: '',
              });
            }
          });
      }
    } else {
      if (!provider.location)
        dispatch({
          field: 'error',
          value: 'Please select an office',
        });
      else if (officeTeams?.length > 0 && !provider.team) {
        dispatch({
          field: 'error',
          value: 'Please select a team',
        });
      } else {
        let payload = {
          email: employeeDetail?.attributes?.email,
          first_name: employeeDetail?.attributes?.first_name,
          last_name: employeeDetail?.attributes?.last_name,
          first_reminder: employeeDetail?.attributes?.first_reminder,
          second_reminder: employeeDetail?.attributes?.second_reminder,
          third_reminder: employeeDetail?.attributes?.third_reminder,
          phone: employeeDetail?.attributes?.phone,
          send_email: true,
          password: provider.password,
          location_id: provider.location.value,
          location_employee: {
            subsidy_type: provider.subsidyType,
            role: provider.role?.value === 'admin' ? 'admin' : 'full_time',
          },
        };
        payload = getCreateOfficeUserRole(provider, payload);
        payload = getOfficeUserSubsidy(provider, payload);

        store.userStore.setLoader(true);
        store.userManagementStore
          .addOfficeEmployee({ employee_id: employeeId }, payload)
          .then(response => {
            store.userStore.setLoader(false);
            if (response.employee?.id) {
              getUserDetail();
              closeModal();
              alertHandler({
                title: `Office user created successfully for ${provider.location.label}.`,
                body: '',
              });
            }
          });
      }
    }
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{
          width: screenType.isTabletOrMobile ? '100%' : '30%',
          minHeight: '350px',
          maxHeight: '750px',
        }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <small className="input-label mt-6 mb-1">
            {isRestaurantUser() ? 'Select Restaurant*' : 'Select Office*'}
          </small>

          {isRestaurantUser() ? (
            <Dropdown
              label="Select"
              options={restaurantList}
              selected={provider.restaurant}
              onChange={selected => {
                dispatch({ field: 'restaurant', value: selected });
                dispatch({ field: 'error', value: '' });
              }}
            />
          ) : (
            <Dropdown
              label="Select"
              options={provider.locations}
              selected={provider.location}
              onChange={selected => {
                dispatch({ field: 'location', value: selected });
                dispatch({ field: 'team', value: null });
                dispatch({ field: 'error', value: '' });
                store.userManagementStore
                  .getOfficeTeams({
                    employee_id: employeeId,
                    location_id: selected.value,
                  })
                  .then(response => {
                    if (response?.data?.length === 1) {
                      dispatch({
                        field: 'team',
                        value: {
                          label: response?.data[0]?.attributes?.name,
                          value: response?.data[0]?.id,
                        },
                      });
                    }
                  });
              }}
            />
          )}

          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="d-row flex-wrap">
              {isOfficeUser() && provider.location && officeTeams?.length > 0 && (
                <div className="w-full d-col">
                  <small className="input-label">Team{officeTeams?.length > 0 && '*'}</small>

                  <Select
                    placeholder="Select"
                    className="input-label capitalize overflow-visible mt-1"
                    options={officeTeams}
                    closeMenuOnSelect
                    isClearable
                    value={provider.team}
                    onChange={selected => {
                      dispatch({ field: 'team', value: selected });
                      dispatch({ field: 'error', value: '' });
                    }}
                  />
                </div>
              )}
              {isOfficeUser() && provider?.role?.value !== 'admin' && (
                <div className="d-row justify-end mt-2 w-full">
                  <label className="inline-flex items-center">
                    <input
                      autoComplete="off"
                      type="checkbox"
                      className="form-checkbox"
                      onClick={e => dispatch({ field: 'teamAdmin', value: e.target.checked })}
                      checked={provider.teamAdmin}
                    />
                    <span className="ml-1 text-xs font-inter-regular">Team Admin</span>
                  </label>
                </div>
              )}

              <div className="w-full">
                <small className="input-label">Role</small>
                <Dropdown
                  isClearable={isOfficeUser()}
                  isSearchable={false}
                  selected={provider.role}
                  options={
                    isOfficeUser()
                      ? provider?.teamAdmin
                        ? [{ label: 'Full Time', value: 'full_time' }]
                        : [
                            { label: 'Full Time', value: 'full_time' },
                            { label: 'Account Admin', value: 'admin' },
                          ]
                      : restaurantEmployeeRoles
                  }
                  onChange={selected => {
                    dispatch({ field: 'role', value: selected });
                  }}
                />
              </div>
            </div>

            {isOfficeUser() && (
              <SubsidySelector
                subsidyType={provider.subsidyType}
                dailySubsidies={provider.dailySubsidies}
                dispatch={dispatch}
                provider={provider}
              />
            )}

            {provider?.error && <span className="my-4 text-red-500 text-xs">{provider.error}</span>}

            <div
              className={`d-row justify-end ${
                screenType.isDesktop && 'absolute bottom-5 right-5'
              } `}>
              <button type="submit" disabled={isLoading} className="btn-purple text-xs mr-2">
                Save
              </button>

              <button className="btn-outline text-xs" type="button" onClick={closeModal}>
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default observer(CreateAssociatedAccount);
