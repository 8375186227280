import React, { useReducer, useContext, useEffect, useState, useMemo } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEdit, faCog } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground, AddressInput } from 'components/Shared';
import { Dropdown } from 'components/Shared';
import { observer } from 'mobx-react';
import useEmployeeId from 'utils/helpers/user';
import Screen from 'utils/helpers/ScreenSize';
import object from 'utils/helpers/object';
import { useHistory } from 'react-router-dom';
import { alertHandler } from 'utils/middlewares/alertHandler';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import array from 'utils/helpers/array';
import classnames from 'classnames';

function AddRestaurant({ visible, closeModal }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const screenType = Screen.ScreenType();
  const [selectedMarket, setSelectedMarket] = useState(null);
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  const { zones, markets } = restaurantManagementStore;
  const { employeeId } = new useEmployeeId();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: 'reset' });

    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        closeModal();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [visible]);

  const initialState = {
    restaurantName: '',
    deliveryInstructions: '',
    phone: '',
    zones: [],

    suite: '',
    streetNumber: '',
    streetName: '',
    autoCompleteAddress: '',
    streetType: '',
    city: '',
    province: '',
    postalCode: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  const getZones = selected => {
    store.userStore.setLoader(true);
    store.restaurantManagementStore
      .getZones({
        employee_id: employeeId,
        market_id: selected?.value,
      })
      .then(response => store.userStore.setLoader(false));
  };

  const marketLabels = () => {
    return markets?.data?.map(market => {
      return { label: market.attributes?.name, value: market.attributes?.id };
    });
  };

  const zoneLabels = () => {
    return zones?.data?.map(zone => {
      return { label: zone.attributes?.name, value: zone.attributes?.id };
    });
  };

  const submit = e => {
    e.preventDefault();
    if (!selectedMarket)
      alertHandler({
        title: 'Please select the market.',
        body: '',
      });
    else if (array.isEmpty(provider.zones))
      alertHandler({
        title: 'Please select the zones.',
        body: '',
      });
    else if (provider.phone && !isValidPhoneNumber(provider.phone))
      alertHandler({
        title: 'You have entered an invalid phone number.',
        body: 'At this time only numbers with country code +1 are accepted. And in the following format: (123) 234-5678',
      });
    else if (
      !provider.streetNumber ||
      !provider.streetName ||
      !provider.city ||
      !provider.province ||
      !provider.postalCode
    )
      alertHandler({
        title: 'Please input the address fields.',
        body: 'Enter Street number, street name, city, province, postal code to continue.',
      });
    else {
      store.userStore.setLoader(true);
      let payload = {
        name: provider.restaurantName,
        delivery_instructions: provider.deliveryInstructions,
        contact_number: provider.phone,
        suite: provider.suite,
        street_number: provider.streetNumber,
        street_name: provider.streetName,
        city: provider.city,
        province: provider.province,
        active: true,
        postal_code: provider.postalCode,
        market_id: selectedMarket?.value,
        zone_ids: provider.zones?.map(zone => zone.value),
      };
      store.restaurantManagementStore
        .createRestaurant(object.removeExtraKeys(payload), {
          employee_id: employeeId,
        })
        .then(response => {
          store.restaurantManagementStore.resetDefaultMenu();
          closeModal();
          history.push(`/restaurant-detail/${response.id}?tab=1`);
          store.userStore.setLoader(false);
        });
    }
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-visible shadow-md transform transition-all animate-fade-in-down p-5"
        role="dialog"
        aria-modal="true"
        style={{ width: screenType.isDesktop ? '55%' : '100%' }}
        aria-labelledby="modal-headline">
        <h5 className="font-inter-semibold">Create new restaurant</h5>
        <button
          onClick={() => {
            closeModal();
          }}>
          <FontAwesomeIcon
            icon={faTimes}
            color={'#ADB4C3'}
            size="lg"
            className="absolute top-4 right-4"
          />
        </button>
        <form onSubmit={e => submit(e)}>
          <div className="d-row flex-wrap items-start">
            <div className="w-full md:w-1/2">
              <small className="input-label">Restaurant Name*</small>
              <input
                autoComplete="off"
                className="input-light"
                name="restaurantName"
                id="restaurantName"
                value={provider.restaurantName}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full md:w-1/2 px-0 md:px-3">
              <AddressInput
                isDisabled={false}
                provider={provider}
                dispatch={dispatch}
                label="Restaurant Address*"
              />
            </div>
          </div>

          <div className="d-row items-start flex-wrap mt-2">
            <div className="w-full md:w-1/2">
              <div className="d-col">
                <small className="input-label">Restaurant Market*</small>
                <Dropdown
                  label="Select"
                  options={marketLabels()}
                  onFocus={() => {
                    store.restaurantManagementStore.getMarkets({ employee_id: employeeId });
                  }}
                  selected={selectedMarket}
                  onChange={value => {
                    setSelectedMarket(value);
                    dispatch({ field: 'zones', value: [] });
                    getZones(value);
                  }}
                />
              </div>
            </div>

            <div
              className={classnames('w-full mt-2 md:mt-0 md:w-1/2', {
                'opacity-100': selectedMarket,
                'opacity-50 cursor-not-allowed': !selectedMarket,
              })}>
              <div className="mx-0 d-col md:mx-3">
                <small className="input-label">Restaurant Zone*</small>
                <Dropdown
                  label="Select"
                  options={zoneLabels()}
                  isMulti
                  selected={provider.zones}
                  onChange={values => dispatch({ field: 'zones', value: values })}
                />
              </div>
            </div>
          </div>

          <div className="d-row items-end mt-2 flex-wrap">
            <div className="w-full md:w-1/2">
              <small className="input-label">Special delivery instructions</small>
              <input
                autoComplete="off"
                className="input-light"
                name="deliveryInstructions"
                id="deliveryInstructions"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
              />
            </div>

            <div className="w-full md:w-1/2 px-0 md:px-3">
              <small className="input-label">Restaurant Phone Number</small>
              <Input
                defaultCountry="CA"
                className="input-light"
                value={provider.phone}
                placeholder="Example: (506) 234-5678"
                onChange={value => dispatch({ field: 'phone', value: value ? value : '' })}
              />
            </div>
          </div>

          <div className="d-row justify-end mt-2">
            <button className="btn-purple text-xxs md:text-xs mr-2" type="submit">
              Save
            </button>

            <button
              className="btn-outline text-xxs md:text-xs"
              onClick={() => {
                closeModal();
              }}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default observer(AddRestaurant);
