import React, { useEffect, useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Validation from 'utils/helpers/validation';
import Select from 'react-select';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { Tooltip } from 'components/Shared';
import Screen from 'utils/helpers/ScreenSize';
import { Dropdown } from 'components/Shared';
import classnames from 'classnames';

const roles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Employee', value: 'employee' },
];

const _dummyData = [
  { name: 'Rohan Admin', email: 'rohan3+freshchat@tuffmail.com', team: 'Front End' },
  { name: 'Rohan Admin', email: 'rohan3+freshchat@tuffmail.com', team: 'Front End' },
  { name: 'Rohan Admin', email: 'rohan3+freshchat@tuffmail.com', team: 'Front End' },
];

const CheckBox = ({ label }) => (
  <label className="flex items-center mt-2">
    <input autoComplete="off" type="checkbox" className="form-checkbox" />
    <span className="ml-2 text-xxs">{label}</span>
  </label>
);

function RestTagMgmtDetails({ visible, closeModal, employeeDetails, removeEmployee }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const screenType = Screen.ScreenType();
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    changePassword: '',
    role: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (employeeDetails?.id) {
      dispatch({ field: 'phone', value: employeeDetails.phone });
      dispatch({ field: 'email', value: employeeDetails.email });
      dispatch({ field: 'firstName', value: employeeDetails.first_name });
      dispatch({ field: 'lastName', value: employeeDetails.last_name });
      dispatch({
        field: 'role',
        value: { label: employeeDetails.role, value: employeeDetails.role },
      });
    } else {
      dispatch({ type: 'reset', payload: initialState });
      dispatch({ field: 'role', value: roles[0] });
    }
  }, [employeeDetails]);

  const submit = e => {
    e.preventDefault();
    let payload = {
      email: provider.email,
      first_name: provider.firstName,
      last_name: provider.lastName,
      role: provider.role.value,
      phone: provider.phone,
    };
    isValidPhoneNumber(provider.phone)
      ? employeeDetails?.id
        ? updateEmployee(payload)
        : addNewEmployee(payload)
      : alertHandler({
          title: 'You have entered an invalid phone number.',
          body: 'At this time only numbers with country code +1 are accepted. And in the following format: (123) 234-5678',
        });
  };

  const updateEmployee = payload => {
    store.userStore
      .updateEmployee(
        employeeDetails?.id,
        { restaurant_id: userStore?.defaultRestaurant?.value },
        payload
      )
      .then(response => {
        if (response?.data?.employee?.id) closeModal();
      });
  };

  const addNewEmployee = payload => {
    store.userStore
      .addEmployee({ restaurant_id: userStore?.defaultRestaurant?.value }, payload)
      .then(response => {
        if (response?.data?.employee?.id) closeModal();
      });
  };

  const onChange = selected => {
    dispatch({ field: 'role', value: selected });
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down h-5/6"
        role="dialog"
        aria-modal="true"
        style={{ minWidth: '790px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <span className="font-inter-semibold text-lg">Tag Name</span>

          <div className="w-full md:w-1/2 d-col mt-5">
            <small className="font-inter-regular">Select Menu</small>
            <Dropdown label="Select" />
          </div>

          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg mt-7">
            <table className="w-full md:table-fixed">
              <thead className="bg-white border-b">
                <tr>
                  <th
                    scope="col"
                    className="t-head text-left px-2 border-r"
                    style={{ width: screenType.isMobile ? '5%' : '30%' }}>
                    <small className="t-row">Menu</small>
                  </th>

                  <th
                    scope="col"
                    className="t-head px-2 border-r text-left"
                    style={{ width: screenType.isMobile ? '20%' : '70%' }}>
                    <small className="t-row"></small>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {_dummyData?.map(({ name, email, team }) => (
                  <tr key={0} className="odd:bg-table-row hover:bg-gray-100 cursor-pointer">
                    <td className="py-3 px-2 align-middle break-words text-left">
                      <div className="d-col">
                        <small className="text-xs ml-0 md:ml-2 text-black font-inter-regular capitalize">
                          Testing Menu
                        </small>
                        <small className="text-xs ml-0 md:ml-2 text-dark-gray font-inter-regular capitalize">
                          Gabby's Restaurant
                        </small>
                      </div>
                    </td>

                    <td className="py-3 px-2 align-middle break-words text-left">
                      <button className="btn-outline text-xs text-dark-gray">Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button
            onClick={() => {
              closeModal();
            }}
            className="absolute bottom-4 right-4 btn-purple text-xs">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default RestTagMgmtDetails;
