import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class deliveryManagementStore {
  locations = [];
  restaurants = [];
  dropoffTasks = [];
  pickupTasks = [];
  shipment = null;
  selectedPickupDropoffTask = [];
  marketsInfo = [];
  markets = [];
  shipmentsByMarket = null;
  zones = [];
  sortedData = [];
  deliveryTimes = [];

  meals = null;
  shipmentByRestaurants = null;
  shipmentsByZone = null;
  shipmentsByDeliveryTime = null;
  groupByChoice = '1';
  passedActiveShipments = [];

  refreshRate = { label: 'Off', value: 'off' };

  constructor() {
    makePersistable(this, {
      name: 'deliveryManagementStore',
      properties: ['refreshRate'],
      storage: window.localStorage,
    });
    makeObservable(this, {
      locations: observable,
      meals: observable,
      dropoffTasks: observable,
      shipment: observable,
      pickupTasks: observable,
      selectedPickupDropoffTask: observable,
      restaurants: observable,
      shipmentByRestaurants: observable,
      marketsInfo: observable,
      markets: observable,
      shipmentsByMarket: observable,
      zones: observable,
      sortedData: observable,
      deliveryTimes: observable,
      shipmentsByZone: observable,
      shipmentsByDeliveryTime: observable,
      refreshRate: observable,
      groupByChoice: observable,
      passedActiveShipments: observable,
    });
  }

  async getLocations(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.deliveryLocations()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.locations = data?.data?.map(location => {
            return { label: location.name?.trim(), value: location.id };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurants(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.restaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.restaurants = data?.data?.map(location => {
            return { label: location.name?.trim(), value: location.id };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  resetMeals() {
    this.meals = null;
  }

  async getMeals(locationId, params) {
    this.meals = null;
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.clients()}/${locationId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.meals = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async shipmentsByRestaurant(restaurantId, params) {
    this.shipmentByRestaurants = null;
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.restaurants()}/${restaurantId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.shipmentByRestaurants = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentDetail(shipmentId, params) {
    this.meals = null;
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.staffShipments()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.shipment = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateOrderStatus(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.ODCuncateringOrders()}/${1}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOnfleetTasks(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.onFleetTask()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          if (params.task_type === 'pickup')
            this.pickupTasks = data?.map(task => {
              return { label: task.data, value: task.id };
            });
          else
            this.dropoffTasks = data?.map(task => {
              return { label: task?.data, value: task.id };
            });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createTask(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.onFleetTask()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateShipment(params, shipmentId, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.staffShipments()}/${shipmentId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async setActiveTask(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.shipmentTask()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  setSelectedPickupDropoffTask(shipmentId, task, type) {
    let helper = toJS(this.selectedPickupDropoffTask);

    let foundIndex = helper.findIndex(
      shipment => shipment.id === shipmentId && shipment.type === type
    );
    if (foundIndex > -1) {
      let shipment = helper[foundIndex];
      shipment.id = shipmentId;
      shipment.type = type;
      shipment.task = task;
    } else helper = [...helper, { id: shipmentId, type, task }];

    this.selectedPickupDropoffTask = helper;
  }

  async notifyUsers(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.notifyUsers()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCopyTaskInformation(shipmentId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.onFleetTask()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async stopResumeOrders(shipmentId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.restaurantShipments()}/${shipmentId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getMarketsInfo(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.markets()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.marketsInfo = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getMarkets(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.markets()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.markets = data?.data?.map(market => {
            return { label: market?.attributes?.name, value: market?.id };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentsByMarket(marketId, params) {
    this.shipmentsByMarket = [];
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.markets()}/${marketId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.shipmentsByMarket = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getZones(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.zones()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.zones = data?.data?.map(market => {
            return { label: market?.attributes?.name, value: market?.id };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentsByZone(zoneId, params, toSave = false) {
    if (!toSave) this.shipmentsByZone = null;
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.zones()}/${zoneId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          if (!toSave) this.shipmentsByZone = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getDeliveryTimes(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.deliveryTimes()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.deliveryTimes = data?.data?.map((market, index) => {
            return {
              value: index,
              label: `${dateTime.to12hourformat(
                market?.start_time,
                data.tzinfo
              )} - ${dateTime.to12hourformat(market?.end_time, data.tzinfo)} `,
              startTime: market?.start_time,
              endTime: market?.end_time,
            };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentsByDeliveryTime(date, params) {
    this.shipmentsByDeliveryTime = null;
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.deliveryTimes()}/${date}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.shipmentsByDeliveryTime = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  setRefreshRate(rate) {
    this.refreshRate = rate;
  }

  setSortedData(data) {
    this.sortedData = data;
  }

  setGroupByChoice(choice) {
    this.groupByChoice = choice;
  }

  resetStoreData(stateName) {
    if (stateName === 'shipmentsByMarket') this.shipmentsByMarket = null;
    else if (stateName === 'shipmentsByZone') this.shipmentsByZone = null;
    else if (stateName === 'meals') this.meals = null;
    else if (stateName === 'shipmentsByDeliveryTime') this.shipmentsByDeliveryTime = null;
  }

  fetchPassedActiveShipments(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.staffShipments()}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.passedActiveShipments = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default deliveryManagementStore;
