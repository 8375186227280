import React, { useEffect, useReducer, useContext, useState } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import {
  CreateMealSeriesFields,
  TimePicker,
  RadioSelector,
  ModalBackground,
  RestaurantSlotsField,
} from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import dateTime from 'utils/helpers/dateTime';
import moment from 'moment';
import { alertHandler } from 'utils/middlewares/alertHandler';
import validation from 'utils/helpers/validation';

function EditMealSettings({ visible, meal, closeModal, timezone }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const [settingType, setSettingType] = useState('1');
  const { employeeId } = new useEmployeeId();
  const mealManagementStore = toJS(store.mealManagementStore);

  const initialState = {
    cutoff: '',
    expectedBags: 1,
    targetTime: '',
    manualRestaurantSlots: false,
    restaurantSlots: 0,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (meal) {
      dispatch({
        field: 'cutoff',
        value:
          meal?.id && meal?.cutoff
            ? dateTime.localWithUTCoffset(meal?.cutoff, timezone)
            : meal?.meal_series_cutoff,
      });
      dispatch({
        field: 'targetTime',
        value: meal?.id && meal?.target_time ? meal?.target_time : meal?.meal_series_target_time,
      });
      dispatch({
        field: 'expectedBags',
        value: meal?.id ? meal?.expected_bags : meal?.meal_series_expected_bags,
      });
      dispatch({
        field: 'manualRestaurantSlots',
        value: meal?.manual_restaurant_slots,
      });
      dispatch({
        field: 'restaurantSlots',
        value: meal?.restaurant_slots,
      });
    } else dispatch({ type: 'reset', payload: initialState });
    setSettingType('1');
  }, [visible]);

  const submit = e => {
    e.preventDefault();

    if (meal?.id) {
      let payload = {
        meal: {
          cutoff: dateTime.utcwithtzInfo(provider.cutoff, meal.meal_date, timezone),
          expected_bags: provider.expectedBags !== '' ? provider.expectedBags : 1,
          target_time: provider.targetTime,
          manual_restaurant_slots: provider.manualRestaurantSlots,
          restaurant_slots: provider.restaurantSlots,
        },
      };
      store.mealManagementStore
        .updateMeal(meal.id, { employee_id: employeeId }, payload)
        .then(response => closeModal('refresh'));
    } else {
      let payload = {
        meal: {
          cutoff: dateTime.utcwithtzInfo(provider.cutoff, meal.meal_date, timezone),
          // expected_bags: provider.expectedBags,
          meal_series_id: meal?.meal_series_id,
          meal_date: meal?.meal_date,
          target_time: provider.targetTime,
          manual_restaurant_slots: provider.manualRestaurantSlots,
          restaurant_slots: provider.restaurantSlots,
        },
      };
      store.userStore.setLoader(true);
      store.mealManagementStore.createMeal({ employee_id: employeeId }, payload).then(response => {
        store.userStore.setLoader(false);
        closeModal({
          mealId: parseInt(response?.data?.id),
          mealDate: meal?.meal_date,
          tempId: meal?.tempId,
        });
      });
    }
  };

  return (
    <div
      className={
        visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-30`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ maxHeight: '95%', width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex flex-col">
          <h5 className="font-inter-semibold px-6 pt-6">Meal Settings</h5>

          <button
            onClick={() => {
              closeModal('close');
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <div className="mt-5 px-6">
            <RadioSelector
              firstLabel={`${meal?.name} - ${meal?.meal_date}`}
              secondLabel={`${meal?.name} - Meal Series`}
              selectorValue={settingType}
              setSelector={value => setSettingType(value)}
            />
          </div>

          {settingType === '1' ? (
            <form className="rounded pt-4 w-full p-6 relative" onSubmit={e => submit(e)}>
              <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full  mb-2 md:mb-0">
                  <small className="input-label">
                    Order Deadline {dateTime.checkAndGetTimezone(timezone)}
                  </small>
                  <TimePicker
                    onChange={time => {
                      if (moment(provider.targetTime, 'hh:mm')?.isAfter(moment(time, 'hh:mm')))
                        dispatch({ field: 'cutoff', value: time });
                      else
                        alertHandler({
                          title: 'Order deadline should be before the delivery time.',
                        });
                    }}
                    value={provider.cutoff}
                  />
                </div>

                <div className="d-col">
                  <small className="input-label">
                    Delivery Time* {dateTime.checkAndGetTimezone(timezone)}
                  </small>
                  <TimePicker
                    onChange={time => {
                      if (moment(provider.cutoff, 'hh:mm')?.isBefore(moment(time, 'hh:mm')))
                        dispatch({ field: 'targetTime', value: time });
                      else
                        alertHandler({
                          title: 'Delivery time should be after the order deadline.',
                        });
                    }}
                    value={provider.targetTime}
                  />
                </div>
              </div>

              <div className="w-full mt-2">
                <small className="input-label">Expected Orders</small>
                <input
                  autoComplete="off"
                  className="input-light"
                  name="expectedBags"
                  id="expectedBags"
                  value={provider.expectedBags}
                  disabled={!meal?.id}
                  type="number"
                  onWheelCapture={e => {
                    e.target.blur();
                  }}
                  onChange={e => {
                    if (e.target.value > -1)
                      dispatch({ field: e.target.name, value: e.target.value });
                  }}
                  onKeyPress={validation.preventMinus}
                />
              </div>

              <RestaurantSlotsField dispatch={dispatch} provider={provider} />
              {/* <button type="button" className="btn-outline text-xs mt-3">
                Cancel Meal
              </button> */}

              <div className="items-center pt-24">
                <button className="btn-purple w-full text-sm" type="submit">
                  {meal?.id ? 'Update' : 'Save'}
                </button>
              </div>
            </form>
          ) : (
            <CreateMealSeriesFields
              update={true}
              meal={meal}
              timezone={timezone}
              closeModal={() => closeModal('refresh')}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditMealSettings;
