import { makeObservable, observable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';
import { client } from '../store';
import axios from 'axios';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import dateTime from 'utils/helpers/dateTime';

const initialCart = {
  all_items: { data: [] },
  subsidized_tip: 0,
  subsidized_hst: 0,
  tip: 0,
  hst: 0,
  hst_rate: 0.13,
  subtotal: 0,
  subsidized_subtotal: 0,
  grand_total: 0,
  payable_amount: 0,
  is_weekly: true,
  available_credit: 0,
  isSubsidy: false,
};

class UserManagementStore {
  employees = {};
  employeeDetail = null;
  restaurantandOfficeUsers = [];
  officeList = [];
  restaurantList = [];
  officeTeams = [];
  officeUserCredit = null;
  officeUserOrders = [];
  associatedAccounts = [];
  creditCards = [];
  order = null;
  optionCategories = null;
  tip = { label: '0%', value: 0 };
  officeOrderETA = [];
  cart = initialCart;

  constructor() {
    makeObservable(this, {
      employees: observable,
      employeeDetail: observable,
      officeList: observable,
      restaurantList: observable,
      officeTeams: observable,
      officeUserCredit: observable,
      officeUserOrders: observable,
      associatedAccounts: observable,
      creditCards: observable,
      order: observable,
      optionCategories: observable,
      tip: observable,
      cart: observable,
      officeOrderETA: observable,
    });
  }

  async searchRestaurantandOfficeUsers(params, marketParams = {}) {
    this.restaurantandOfficeUsers = [];
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.users()}?${paramsString}&${marketParams}`)
      .then(
        action('fetchSuccess', data => {
          let hold = [];

          data?.data?.data?.map(user => {
            if (array.isPresent(user.attributes?.location_employees))
              hold = [
                ...hold,
                {
                  ...user.attributes,
                  account_type: 'Location',
                  employees: user.attributes.location_employees,
                },
              ];
            if (array.isPresent(user.attributes?.restaurant_employees))
              hold = [
                ...hold,
                {
                  ...user.attributes,
                  account_type: 'Restaurant',
                  employees: user.attributes.restaurant_employees,
                },
              ];
          });

          this.restaurantandOfficeUsers = { data: hold, pagy: data?.data?.pagy };
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurantList(employeeId) {
    this.restaurantList = [];
    let paramsString = qs.stringify({ employee_id: employeeId, only_name_and_id: true });
    return client()
      .get(`${api.restaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.restaurantList = data?.data?.data?.map(restaurant => {
            return { label: restaurant.name, value: restaurant.id };
          });
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurantListWithShipments(params) {
    this.restaurantList = [];
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.restaurantList = data?.data?.data?.map(restaurant => {
            return { label: restaurant.name, value: restaurant.id };
          });
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOfficeList(employeeId) {
    this.officeList = [];
    let paramsString = qs.stringify({ employee_id: employeeId, only_name_and_id: true });
    return client()
      .get(`${api.clients()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.officeList = data?.data?.data?.map(office => {
            return { label: office.name, value: office.id };
          });
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOfficeTeams(params) {
    this.officeTeams = [];
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.teams()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.officeTeams = data?.data?.data?.map(office => {
            return { label: office.attributes?.name, value: office.id };
          });
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addOfficeEmployee(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.officeEmployee()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateOfficeEmployee(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.userDetail(id)}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async sendAccountConfirmationEmail(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.orderReceives(id)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async updateOfficeEmployeeRole(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.officeEmployees()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getUserDetail(id, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.userDetail(id)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.employeeDetail = data?.data?.data;
          return data?.data?.data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async addRestaurantEmployee(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.employees()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOfficeUserCredit(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.officeUserCredit()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.officeUserCredit = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async setOfficeUserCredit(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.officeUserCredit()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getOfficeUserCredit(params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOfficeUserOrders(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.officeUserOrder()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.officeUserOrders = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getOrderDetails(orderId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.orders()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.order = data?.data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateOrderAdjustments(orderId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.orders()}/${orderId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async cancelOrDeleteOrder(orderId, params, fetchOrderParams) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.orders()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.getOfficeUserOrders(fetchOrderParams);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async cancelOrderByUser(orderId, params, fetchOrderParams) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.userOrders()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (fetchOrderParams) this.getOfficeUserOrders(fetchOrderParams);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async cancelOrderByRestaurant(orderId, params, fetchOrderParams) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.restaurantOrders()}/${orderId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (fetchOrderParams) this.getOfficeUserOrders(fetchOrderParams);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAssociatedAccounts(userId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.associatedAccounts(userId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.associatedAccounts = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteOfficeEmployee(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.officeEmployee()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getEmployeeCards(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.creditCards()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.creditCards = data?.data?.credit_cards;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployeeCard(cardId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.creditCards()}/${cardId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.getEmployeeCards(params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addEmployeeCard(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.creditCards()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployeeCards(params);
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async fetchUser(email, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.fetchUser(email)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          return error;
        })
      );
  }

  // *************************Place order*************************

  async getMenus(empId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.companyEmployees()}/${empId}/menus?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          return error;
        })
      );
  }

  async getMenuDetails(menuId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.companyMenus()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          return error;
        })
      );
  }

  clearOptionCategories() {
    this.optionCategories = null;
  }

  async getOptionCategories(itemId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menuOptions()}/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          this.optionCategories = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async addItemToCart(empId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.companyEmployees()}/${empId}/cart_items?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCart(empId, showTip, params, hideError) {
    let paramsString = qs.stringify(params);
    return client()
      .get(
        `${api.companyEmployees()}/${empId}/cart_items?${paramsString}&tip_percentage=${
          this.tip.value / 100
        }`
      )
      .then(
        action('fetchSuccess', ({ data }) => {
          this.cart = data;
          return data;
        }),
        action('fetchError', error => {
          if (!hideError) alertHandler(error.response);
          return error;
        })
      );
  }

  setTip = (tip, empId, showTip, params) => {
    this.tip = tip;
    this.getCart(empId, showTip, params);
  };

  resetCart = tip => {
    this.cart = initialCart;
  };

  async removeItemFromCart(empId, itemId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.companyEmployees()}/${empId}/cart_items/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async clearCart(empId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.companyEmployees()}/${empId}/cart_items?${paramsString}`)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async placeOrder(empId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.placeOrder()}/${empId}/orders?${paramsString}`, payload)
      .then(
        action('fetchSuccess', ({ data }) => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCompanyShipmentsETA(params, shipmentIds) {
    let paramsString = qs.stringify(params);

    let shipmentParams = qs.stringify(
      {
        shipment_ids: shipmentIds,
      },
      { arrayFormat: 'brackets' }
    );

    return client()
      .get(`${api.companyETA()}?${paramsString}&${shipmentParams}`)
      .then(
        action('fetchSuccess', data => {
          let arr = toJS(this.officeOrderETA);

          for (const property in data?.data) {
            arr = arr.filter(shipment => shipment.shipmentId !== property);
          }

          for (const property in data?.data) {
            arr = [...arr, { shipmentId: property, eta: data?.data[property] }];
          }

          this.officeOrderETA = arr;
          return data;
        }),
        action('fetchError', error => {
          // errorHandler(error.response);
          return error;
        })
      );
  }
}

export default UserManagementStore;
