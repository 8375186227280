import React, { useReducer, useContext, useEffect } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, ModalBackground } from 'components/Shared';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { timezones } from 'utils/constants/market';
import useEmployeeId from 'utils/helpers/user';
import classnames from 'classnames';

function AddMarket({ visible, closeModal, editMarket }) {
  const store = useContext(StoreContext);
  let { employeeId } = useEmployeeId();

  const initialState = {
    name: '',
    code: '',
    timezone: '',
    taxName: '',
    taxRate: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  const submit = e => {
    e.preventDefault();

    if (!provider.timezone) {
      alertHandler({ title: 'Select timezone!' });
      return;
    }

    let payload = {
      market: {
        name: provider.name,
        code: provider.code,
        timezone: provider.timezone.value,
        tax_rate: provider.taxRate,
        tax_name: provider.taxName,
      },
    };

    store.userStore.setLoader(true);

    if (editMarket !== null) {
      store.marketManagementStore
        .updateMarket(editMarket.id, { employee_id: employeeId }, payload)
        .then(response => {
          store.userStore.setLoader(false);

          if (response?.attributes) closeModal();
        });
    } else
      store.marketManagementStore
        .createMarket({ employee_id: employeeId }, payload)
        .then(response => {
          store.userStore.setLoader(false);

          if (response?.attributes) closeModal();
        });
  };

  useEffect(() => {
    dispatch({ type: 'reset', payload: initialState });

    if (editMarket !== null) {
      dispatch({ field: 'name', value: editMarket?.attributes?.name });
      dispatch({
        field: 'code',
        value: editMarket?.attributes?.code,
      });
      dispatch({ field: 'taxName', value: editMarket?.attributes?.tax_name });
      dispatch({ field: 'taxRate', value: editMarket?.attributes?.tax_rate });
      dispatch({
        field: 'timezone',
        value: { label: editMarket?.attributes?.timezone, value: editMarket?.attributes?.timezone },
      });
    }
  }, [visible, editMarket]);

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll md:overflow-hidden shadow-2xl transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="w-full">
              <small className="input-label">Market Name</small>
              <input
                autoComplete="off"
                className="input-light"
                name="name"
                id="name"
                value={provider.name}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full">
              <small className="input-label">Market Code</small>
              <input
                autoComplete="off"
                className="input-light"
                name="code"
                id="code"
                value={provider.code}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full">
              <small className="input-label">Select Time Zone</small>
              <Dropdown
                label="Select"
                options={timezones}
                selected={provider.timezone}
                onChange={selected => dispatch({ field: 'timezone', value: selected })}
                required
              />
            </div>

            <div className="w-full">
              <small className="input-label">Tax Name</small>
              <input
                autoComplete="off"
                className="input-light"
                name="taxName"
                id="taxName"
                value={provider.taxName}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full">
              <small className="input-label">Tax Rate</small>
              <input
                autoComplete="off"
                className="input-light"
                name="taxRate"
                id="taxRate"
                type={'number'}
                value={provider.taxRate}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="items-center flex flex-col pt-2 md:pt-24">
              <button className="btn-purple w-full text-sm" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddMarket;
