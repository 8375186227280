import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { StoreContext } from 'index';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { toJS } from 'mobx';

function ActionCableWrapper({ children }) {
  const store = useContext(StoreContext);

  const handleReceived = message => {
    console.log('message', message);
    store.restaurantManagementStore.setActionCablePayload(message);
  };

  return (
    <ActionCableConsumer
      channel="Noticed::NotificationChannel"
      onReceived={handleReceived}
      onDisconnected={() => console.log('Disconnected')}
      onConnected={() => console.log('Connected')}>
      {children}
    </ActionCableConsumer>
  );
}

export default observer(ActionCableWrapper);
