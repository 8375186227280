import React, { useEffect, useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { toJS } from 'mobx';
import { ModalBackground, ToggleButton, PasswordInput } from 'components/Shared';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { useQueryMethods } from 'hooks/useQueryMethods';
import { Tooltip } from 'components/Shared';
import { useParams } from 'react-router-dom';
import useEmployeeId from 'utils/helpers/user';
import object from 'utils/helpers/object';
import { useEmployeeMethods } from 'hooks/useEmployeeMethods';
import { restaurantEmployeeRoles } from 'utils/constants/employee';
import classnames from 'classnames';

const CheckBox = ({ label, value, onChange, name, id, disabled = false }) => (
  <label className="flex items-center mt-2 ">
    <input
      autoComplete="off"
      checked={value}
      onChange={onChange}
      disabled={disabled}
      name={name}
      type="checkbox"
      id={id}
      className="form-checkbox cursor-pointer"
    />
    <span className={`ml-2 text-xs cursor-pointer ${disabled && 'opacity-50'}`}>{label}</span>
  </label>
);

function EmployeeDetails({
  visible,
  closeModal,
  employeeDetails,
  removeEmployee,
  showNotifications,
  pageNumber = 1,
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { employeeId } = new useEmployeeId();
  const { id } = useParams();
  const { resetPasswordInstructions } = useEmployeeMethods();
  const { showPageId } = useQueryMethods();

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    changePassword: '',
    role: '',
    invalidRole: false,

    orderReminderEmail: true,
    orderReminderPortalAlert: true,
    scheduleUpdatesEmail: true,
    scheduleUpdatesPortalAlert: true,
    orderUpdatesEmail: true,
    orderUpdatesPortalAlert: true,
    orderConfirmationEmail: true,
    orderConfirmationPortalAlert: true,
    orderConfirmationPhoneCall: false,
    pushNotifications: false,
    muteAll: false,
    isSoftDeleted: false,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (employeeDetails?.id) {
      dispatch({ field: 'phone', value: employeeDetails.phone });
      dispatch({ field: 'email', value: employeeDetails.email });
      dispatch({ field: 'firstName', value: employeeDetails.first_name });
      dispatch({ field: 'lastName', value: employeeDetails.last_name });
      dispatch({
        field: 'role',
        value: { label: employeeDetails.role?.replace(/_/g, ' '), value: employeeDetails.role },
      });

      dispatch({
        field: 'orderReminderEmail',
        value: employeeDetails?.restaurant_employee?.order_reminder_email,
      });
      dispatch({
        field: 'orderReminderPortalAlert',
        value: employeeDetails?.restaurant_employee?.order_reminder_portal_alert,
      });
      dispatch({
        field: 'scheduleUpdatesEmail',
        value: employeeDetails?.restaurant_employee?.schedule_updates_email,
      });
      dispatch({
        field: 'scheduleUpdatesPortalAlert',
        value: employeeDetails?.restaurant_employee?.schedule_updates_portal_alert,
      });
      dispatch({
        field: 'orderUpdatesEmail',
        value: employeeDetails?.restaurant_employee?.order_updates_email,
      });
      dispatch({
        field: 'orderUpdatesPortalAlert',
        value: employeeDetails?.restaurant_employee?.order_updates_portal_alert,
      });
      dispatch({
        field: 'orderConfirmationEmail',
        value: employeeDetails?.restaurant_employee?.order_confirmation_email,
      });
      dispatch({
        field: 'muteAll',
        value: employeeDetails?.restaurant_employee?.mute_notifications,
      });
      dispatch({
        field: 'orderConfirmationPortalAlert',
        value: employeeDetails?.restaurant_employee?.order_confirmation_portal_alert,
      });
      dispatch({
        field: 'orderConfirmationPhoneCall',
        value: employeeDetails?.restaurant_employee?.order_confirmation_phone_call,
      });
      dispatch({
        field: 'pushNotifications',
        value: employeeDetails?.restaurant_employee?.push_notifications,
      });
    } else dispatch({ type: 'reset', payload: initialState });
  }, [employeeDetails]);

  const submit = e => {
    e.preventDefault();
    let payload = employeeDetails?.id
      ? {
          restaurant_employee: {
            role: provider.role?.value,
            push_notifications: provider.pushNotifications,
            order_reminder_email: provider.orderReminderEmail,
            order_reminder_portal_alert: provider.orderReminderPortalAlert,
            schedule_updates_email: provider.scheduleUpdatesEmail,
            schedule_updates_portal_alert: provider.scheduleUpdatesPortalAlert,
            order_updates_portal_alert: provider.orderUpdatesPortalAlert,
            order_confirmation_email: provider.orderConfirmationEmail,
            order_confirmation_portal_alert: provider.orderConfirmationPortalAlert,
            order_confirmation_phone_call: provider.orderConfirmationPhoneCall,
            mute_notifications: provider.muteAll,
            order_updates_email: provider.orderUpdatesEmail,
            employee_attributes: {
              id: employeeDetails?.id,
              user_attributes: {
                id: employeeDetails?.user_id,
                password: provider.changePassword,
                password_confirmation: provider.changePassword,
                email: provider.email,
                first_name: provider.firstName,
                last_name: provider.lastName,
                phone: provider.phone,
              },
            },
          },
        }
      : {
          email: provider.email,
          first_name: provider.firstName,
          last_name: provider.lastName,
          phone: provider.phone,
          restaurant_employee: {
            role: provider.role?.value,
            order_confirmation_phone_call: provider.orderConfirmationPhoneCall,
          },
        };

    if (!employeeDetails?.id && provider.isSoftDeleted) {
      payload.restore = true;
    }

    if (provider.orderConfirmationPhoneCall && !provider.phone) {
      alertHandler({
        title: 'Please add a phone number before enabling this notification.',
      });
      return;
    }

    if (!provider.role) dispatch({ field: 'invalidRole', value: true });
    else
      !provider.phone || isValidPhoneNumber(provider.phone)
        ? employeeDetails?.id
          ? updateEmployee(payload)
          : addNewEmployee(payload)
        : alertHandler({
            title: 'You have entered an invalid phone number.',
            body: 'At this time only numbers with country code +1 are accepted. And in the following format: (123) 234-5678',
          });
  };

  const updateEmployee = payload => {
    store.userStore.setLoader(true);
    store.restaurantManagementStore
      .updateEmployee(
        employeeDetails?.id,
        { employee_id: employeeId, restaurant_id: id, page: pageNumber },
        payload
      )
      .then(response => {
        if (response?.data?.data?.id) {
          closeModal();
          store.userStore.setLoader(false);
          store.userStore.setErrorAlert(true, {
            title: 'Successfully updated!',
            body: '',
          });
        }
      });
  };

  const addNewEmployee = payload => {
    store.userStore.setLoader(true);
    store.restaurantManagementStore
      .addEmployee({ employee_id: employeeId, restaurant_id: id }, payload)
      .then(response => {
        store.userStore.setLoader(false);

        if (response?.data?.employee?.id) {
          closeModal();
          store.userStore.setErrorAlert(true, {
            title: 'Successfully created!',
            body: '',
          });
        }
      });
  };

  const onChange = selected => {
    dispatch({ field: 'invalidRole', value: false });
    dispatch({ field: 'role', value: selected });
  };

  const onNotificationChange = e => {
    dispatch({ field: e.target.id, value: e.target.checked });
  };

  const sendResetPasswordInstructions = () => {
    closeModal();
    let name = `${employeeDetails.first_name || ''} ${employeeDetails?.last_name || ''}`;
    resetPasswordInstructions(name, 'Restaurant', employeeDetails.email);
  };

  const fetchAndFillUser = email => {
    dispatch({ field: 'userPrefilled', value: false });
    store.userStore.setLoader(true);
    store.userManagementStore.fetchUser(email, { employee_id: employeeId }).then(response => {
      store.userStore.setLoader(false);
      if (response?.status === 200) {
        dispatch({ field: 'userPrefilled', value: true });
        dispatch({ field: 'phone', value: response?.data?.data?.attributes?.phone });
        dispatch({ field: 'firstName', value: response?.data?.data?.attributes?.first_name });
        dispatch({ field: 'lastName', value: response?.data?.data?.attributes?.last_name });

        let employees = response?.data?.data?.attributes?.employees;
        let obj = employees.find(
          employee =>
            employee.attributes?.organization_type === 'Restaurant' &&
            Number(employee.attributes?.organization_id) === Number(showPageId()) &&
            employee.attributes?.soft_deleted
        );

        if (object.isPresent(obj)) {
          dispatch({ field: 'isSoftDeleted', value: true });
        }
      } else dispatch({ field: 'isSoftDeleted', value: false });
    });
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px', maxHeight: '90%' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="d-row flex-wrap">
              <div className="w-full md:w-1/2 md:pr-1">
                <small className="input-label">First Name</small>
                <input
                  autoComplete="off"
                  className="input-light"
                  name="firstName"
                  id="firstName"
                  type="text"
                  value={provider.firstName}
                  placeholder="Enter Name"
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                  required
                  autoFocus={true}
                />
              </div>

              <div className="w-full md:w-1/2">
                <small className="input-label">Last Name</small>
                <input
                  autoComplete="off"
                  className="input-light"
                  name="lastName"
                  id="lastName"
                  value={provider.lastName}
                  placeholder="Enter Name"
                  type="text"
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                  required
                />
              </div>
            </div>

            <div className="d-row flex-wrap">
              <div className="w-full md:w-1/2 md:pr-1">
                <small className="input-label">Email</small>
                <input
                  autoComplete="off"
                  className="input-light"
                  placeholder="Enter Email"
                  name="email"
                  id="email"
                  value={provider.email}
                  onBlur={e => {
                    fetchAndFillUser(e.target.value);
                  }}
                  type="email"
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                  required
                />
              </div>

              <div className="w-full md:w-1/2">
                <small className="input-label">Phone Number</small>
                <Input
                  defaultCountry="CA"
                  value={provider.phone}
                  className="input-light"
                  placeholder="Example: (506) 234-5678"
                  onChange={value => {
                    if (value) dispatch({ field: 'phone', value });
                    else {
                      dispatch({ field: 'phone', value: value ? value : '' });
                      dispatch({ field: 'orderConfirmationPhoneCall', value: value ? value : '' });
                    }
                  }}
                />
              </div>
            </div>

            {employeeDetails?.id && (
              <div>
                <small className="input-label">Change Password</small>
                {/* <input
autoComplete="off"
                  className="input-light"
                  placeholder="Enter a new password and Update Details"
                  name="changePassword"
                  id="changePassword"
                  value={provider.changePassword}
                  type="text"
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                /> */}

                <PasswordInput
                  inputLight
                  placeholder="Enter a new password and Update Details"
                  onChange={e => dispatch({ field: 'changePassword', value: e.target.value })}
                />
              </div>
            )}

            <div className="d-col mt-1">
              <small className="input-label">Role</small>
              <Select
                className="font-sf-regular capitalize text-xs focus:outline-none ring-transparent shadow-none"
                options={restaurantEmployeeRoles}
                value={provider.role}
                onChange={onChange}
              />
              <small
                className={`input-label text-red-500 ${
                  provider.invalidRole ? 'visible' : 'invisible'
                }`}>
                Select Role*
              </small>
            </div>

            {showNotifications && (
              <>
                <div className="d-col pt-3">
                  <div className="d-row w-full justify-between">
                    <div className="d-row overflow-visible">
                      <small className="font-inter-medium text-xs">Mute All Notification's</small>
                      <Tooltip
                        tooltipText={'Stop all notifications to your account.'}
                        id="mute-notification"
                      />
                    </div>

                    <ToggleButton
                      toggleState={provider.muteAll}
                      setToggleState={value =>
                        onNotificationChange({
                          target: { id: 'muteAll', name: 'mute_notifications', checked: value },
                        })
                      }
                    />
                  </div>

                  <div className="d-row w-full justify-between mt-2">
                    <div className="d-row overflow-visible">
                      <small className="font-inter-medium text-xs">Push Notifications</small>
                      <Tooltip
                        tooltipText={'Stop all mobile app notifications to your account.'}
                        id="mute-notification"
                      />
                    </div>

                    <ToggleButton
                      toggleState={provider.pushNotifications}
                      disabled={!provider.pushNotifications}
                      setToggleState={value =>
                        onNotificationChange({
                          target: {
                            id: 'pushNotifications',
                            name: 'push_notifications',
                            checked: value,
                          },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="d-col pt-3">
                  <div className="d-row">
                    <div className="d-col w-1/2">
                      <div className="d-row">
                        <small className="font-inter-medium text-xs">Order Reminder</small>
                        <Tooltip
                          tooltipText="Receive a notification 60 minutes prior to the order deadline. At this point orders cannot be removed or modified."
                          id="mute-notification"
                        />
                      </div>

                      <CheckBox
                        value={provider.orderReminderEmail}
                        name="order_reminder_email"
                        onChange={e => onNotificationChange(e)}
                        id="orderReminderEmail"
                        label="Email"
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                      />

                      <CheckBox
                        value={provider.orderReminderPortalAlert}
                        name="order_reminder_portal_alert"
                        id="orderReminderPortalAlert"
                        onChange={e => onNotificationChange(e)}
                        label="Portal Alert"
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                      />
                    </div>

                    <div className="d-col w-1/2">
                      <div className="d-row">
                        <small className="font-inter-medium text-xs">Schedule Updates</small>
                        <Tooltip
                          tooltipText="Receive a notification for your upcoming week's schedule along with any in week adjustments."
                          id="mute-notification"
                        />
                      </div>

                      <CheckBox
                        value={provider.scheduleUpdatesEmail}
                        name="schedule_updates_email"
                        id="scheduleUpdatesEmail"
                        onChange={e => onNotificationChange(e)}
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                        label="Email"
                      />

                      <CheckBox
                        value={provider.scheduleUpdatesPortalAlert}
                        name="schedule_updates_portal_alert"
                        id="scheduleUpdatesPortalAlert"
                        onChange={e => onNotificationChange(e)}
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                        label="Portal Alert"
                      />
                    </div>
                  </div>

                  <div className="d-row mt-4">
                    <div className="d-col w-1/2">
                      <div className="d-row">
                        <small className="font-inter-medium text-xs">Order Updates</small>
                        <Tooltip
                          tooltipText="Receive a notification for all changes(late orders, order modifications, pickup time) to your order after confirmation."
                          id="mute-notification"
                        />
                      </div>

                      <CheckBox
                        value={provider.orderUpdatesEmail}
                        name="order_updates_email"
                        id="orderUpdatesEmail"
                        onChange={e => onNotificationChange(e)}
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                        label="Email"
                      />

                      <CheckBox
                        value={provider.orderUpdatesPortalAlert}
                        name="order_updates_portal_alert"
                        id="orderUpdatesPortalAlert"
                        onChange={e => onNotificationChange(e)}
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                        label="Portal Alert"
                      />
                    </div>

                    <div className="d-col w-1/2">
                      <div className="d-row">
                        <small className="font-inter-medium text-xs">Order Confirmation</small>
                        <Tooltip
                          tooltipText="Receive a notification at the order deadline. All order's must be confirmed at this point."
                          id="mute-notification"
                        />
                      </div>

                      <CheckBox
                        value={provider.orderConfirmationEmail}
                        name="order_confirmation_email"
                        id="orderConfirmationEmail"
                        onChange={e => onNotificationChange(e)}
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                        label="Email"
                      />

                      <CheckBox
                        value={provider.orderConfirmationPortalAlert}
                        name="order_confirmation_portal_alert"
                        id="orderConfirmationPortalAlert"
                        onChange={e => onNotificationChange(e)}
                        disabled={object.isEmpty(employeeDetails) || provider.muteAll}
                        label="Portal Alert"
                      />

                      <CheckBox
                        value={provider.orderConfirmationPhoneCall}
                        name="order_confirmation_phone_call"
                        id="orderConfirmationPhoneCall"
                        onChange={e => onNotificationChange(e)}
                        disabled={provider.muteAll}
                        label="Phone call"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="items-center flex flex-col pt-2 md:pt-8">
              {employeeDetails?.id && (
                <div className="w-full">
                  <button
                    type="button"
                    className="btn-purple text-sm w-full bg-light-red break-normal"
                    onClick={() => {
                      removeEmployee({
                        id: employeeDetails.id,
                        fullName: employeeDetails.first_name + ' ' + employeeDetails.last_name,
                      });
                    }}>
                    Delete {employeeDetails?.first_name}
                  </button>

                  <button
                    onClick={sendResetPasswordInstructions}
                    className="btn-purple text-sm w-full my-2 bg-pink-purple break-normal"
                    type="button">
                    Reset Password
                  </button>
                </div>
              )}

              <button className="btn-purple w-full text-xs mt-2" type="submit">
                {employeeDetails?.id
                  ? 'Update Details'
                  : provider.isSoftDeleted
                  ? 'Revert Team Member'
                  : ' Add Team Member'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EmployeeDetails;
