export const officeEmployeeRoles = [
  { label: 'Admin', value: 'admin' },
  { label: 'Full Time', value: 'full_time' },
  { label: 'Part Time', value: 'part_time' },
  { label: 'Guest', value: 'guest' },
];

export const restaurantEmployeeRoles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Employee', value: 'employee' },
  { label: 'Accounting', value: 'accounting' },
];

export const officeEmployeeReminders = [
  { label: 'no reminder', value: 0 },
  { label: '15 minutes', value: 1 },
  { label: '30 minutes', value: 2 },
  { label: '45 minutes', value: 3 },
  { label: '60 minutes', value: 4 },
  { label: '90 minutes', value: 5 },
  { label: '120 minutes', value: 6 },
  { label: '180 minutes', value: 7 },
];

export const staffEmployeeRoles = [
  { label: 'Customer Service', value: 'customer_service' },
  { label: 'Operations', value: 'operations' },
  { label: 'Restaurant Management', value: 'restaurant_management' },
  { label: 'Account Management', value: 'account_management' },
  { label: 'hungerhub admin', value: 'hungerhub_admin' },
  { label: 'hungerhub super admin', value: 'hungerhub_super_admin' },
  {
    label: 'Restaurant Support',
    value: 'restaurant_support',
  },
  {
    label: 'Delivery Support',
    value: 'delivery_support',
  },
  {
    label: 'Account Management Support',
    value: 'account_management_support',
  },
  {
    label: 'Meal Management Support',
    value: 'meal_management_support',
  },
  {
    label: 'Delivery Overview',
    value: 'delivery_overview',
  },
  {
    label: 'Menu Editor',
    value: 'menu_editor',
  },
  {
    label: 'Restaurant Accounting',
    value: 'restaurant_accounting',
  },
];

export const getReminderValue = reminder => {
  return {
    label:
      reminder == '0' || reminder == null || reminder == 'no reminder'
        ? 'no reminder'
        : `${reminder} minutes`,
    value: reminder,
  };
};
