import DOMPurify from 'dompurify';
const EmbedHTML = ({ text, color = 'text-white', append = null, isRow, appendClass }) => {
  return (
    <>
      {!isRow ? (
        <div
          className={`font-inter ${color} ${append && 'd-row'}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text ?? '' + (append || '')) }}
        />
      ) : (
        <div className="d-row">
          <div
            className={`font-inter ${color} ${append && 'd-row'}`}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text ?? '') }}
          />

          <div className={`${color} ${appendClass}`}>{append}</div>
        </div>
      )}
    </>
  );
};

export default EmbedHTML;
