import React, { useReducer, useContext, useEffect, useState } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import Screen from 'utils/helpers/ScreenSize';
import { ModalBackground, NotFound } from 'components/Shared';
import useEmployeeId from 'utils/helpers/user';
import { visaCard, cardIcon } from 'assets/img';
import { useQueryMethods } from 'hooks/useQueryMethods';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { observer } from 'mobx-react';
import { ConfirmationPopup } from 'components/Shared';
import classnames from 'classnames';
import { showDisableCardsForOfficeAlert } from 'utils/constants/placeOrder';

function PlaceOrderConfirmation({
  visible,
  closeModal,
  needToPay,
  subsidizedSubtotal,
  orderTotal,
  selectedOffice,
  office,
  displayName,
  resetAll,
  cart,
  cutlery,
  getCart,
  userId,
  clearCart,
  selectedOptions,
}) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const userManagementStore = toJS(store.userManagementStore);
  const screenType = Screen.ScreenType();

  let { employeeId } = useEmployeeId();
  let { showPageId } = useQueryMethods();
  let { creditCards, employeeDetail } = userManagementStore;

  const initialState = {
    picked: 'credit_card',
    cardId: '',
    bypassPlaceorder: false,
    placeOrderPressed: false,
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (visible) {
      dispatch({ type: 'reset', payload: initialState });

      store.userStore.setLoader(true);
      store.userManagementStore
        .getEmployeeCards({
          employee_id: employeeId,
          user_id: showPageId() || userId,
        })
        .then(() => store.userStore.setLoader(false));
    }
  }, [visible]);

  useEffect(() => {
    if (creditCards?.length > 0) dispatch({ field: 'cardId', value: creditCards[0].card_id });
  }, [creditCards?.length]);

  const pay = (skipStopShipment = false, skipCuttoff = false) => {
    dispatch({ field: 'placeOrderPressed', value: true });
    if (provider.cardId === '' && provider.picked == 'credit_card') {
      alertHandler({ title: 'Please select a card.' });
      dispatch({ field: 'placeOrderPressed', value: false });
      return;
    }

    if (provider.picked == 'credit_card') {
      if (office?.organization?.disable_credit_cards) {
        showDisableCardsForOfficeAlert(office?.organization?.name);
        dispatch({ field: 'placeOrderPressed', value: false });
        return;
      }
    }

    let payload = {
      card_id: provider.cardId,
      amount: needToPay,
      paid_by_invoice: provider.picked == 'paid_by_invoice',
      cutlery: cutlery,
      skip_stopped_shipment_check: skipStopShipment,
      order_billing_references_attributes: selectedOptions,
    };

    if (skipCuttoff) payload.skip_cutoff_check = true;

    store.userStore.setLoader(true);

    let currentItemsCount = cart?.all_items?.data?.length;

    getCart().then(response => {
      if (currentItemsCount !== response?.all_items?.data?.length) {
        alertHandler({
          title: `${
            employeeDetail ? employeeDetail?.attributes?.first_name : displayName
          } has made changes to the cart while this order was being placed, please review the updated cart before placing the order and then update the cart.`,
        });
        store.userStore.setLoader(false);
        dispatch({ field: 'placeOrderPressed', value: false });
      } else {
        store.userManagementStore
          .placeOrder(selectedOffice, { employee_id: employeeId }, payload)
          .then(response => {
            store.userStore.setLoader(false);
            if (response?.orders) {
              closeModal();
              getCart();
              setTimeout(() => resetAll(), 1500);
              alertHandler({
                title: `The order for ${
                  employeeDetail ? employeeDetail?.attributes?.first_name : displayName
                } has successfully been placed.`,
              });
            } else {
              if (
                !response?.valid &&
                response?.message?.includes('The order deadline has passed.')
              ) {
                store.userStore.setLoader(true);
                pay(skipStopShipment, true);
              } else if (
                !response?.valid &&
                response?.message?.includes('There is a cart item with a stopped shipment')
              ) {
                dispatch({ field: 'bypassPlaceorder', value: true });
              } else if (!response?.valid && response?.message?.includes('Unfortunately')) {
                alertHandler({ title: response?.message });
                closeModal();
                clearCart();
              } else alertHandler({ title: response?.message });
            }
          });
      }
    });
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all animate-fade-in-down p-5"
        role="dialog"
        aria-modal="true"
        style={{ width: screenType.isDesktop ? '40%' : '100%', maxHeight: '550px' }}
        aria-labelledby="modal-headline">
        <h5 className="font-inter-semibold">Place Order</h5>
        <button
          onClick={() => {
            closeModal();
          }}>
          <FontAwesomeIcon
            icon={faTimes}
            color={'#ADB4C3'}
            size="lg"
            className="absolute top-4 right-4"
          />
        </button>

        <div className="d-row justify-between items-start flex-wrap">
          <div className="w-full border px-3 rounded-md">
            <div className="d-row justify-between border-b py-4">
              <span className="input-label">Order Total</span>
              <small className="input-label">$ {orderTotal}</small>
            </div>
            <div className="d-row justify-between border-b py-2">
              <span className="input-label">Subsidized Total</span>
              <small className="input-label">$ {subsidizedSubtotal}</small>
            </div>
            <div className="d-row justify-between py-2">
              <span className="input-label">Need to Pay</span>
              <small className="input-label">$ {needToPay}</small>
            </div>
          </div>
        </div>
        {needToPay > subsidizedSubtotal && (
          <span className="text-xs text-dark-gray">
            *Food total exceeds remaining user subsidy.
          </span>
        )}

        <div className="mt-5">
          <small className="input-label">Choose Payment Method</small>
          <div className="d-col mt-3">
            <div className="d-row mr-4">
              <label className="inline-flex items-center">
                <input
                  autoComplete="off"
                  type="radio"
                  className="form-radio"
                  name="radio"
                  value="credit_card"
                  checked={provider.picked == 'credit_card'}
                  onChange={e => dispatch({ field: 'picked', value: e.target.value })}
                />
                <span className="ml-2 text-xs font-inter-regular">Pay with credit card</span>
              </label>
            </div>
            <div className="d-row mt-3">
              <label className="inline-flex items-center">
                <input
                  autoComplete="off"
                  type="radio"
                  className="form-radio"
                  name="radio"
                  value="paid_by_invoice"
                  checked={provider.picked == 'paid_by_invoice'}
                  onChange={e => dispatch({ field: 'picked', value: e.target.value })}
                />
                <span className="ml-2 text-xs font-inter-regular">
                  Override subsidy and bill to account invoice
                </span>
              </label>
            </div>
          </div>
        </div>

        {provider.picked === 'credit_card' && (
          <div className="mt-5 d-col">
            <small className="input-label">Choose card to complete Payment</small>
            <small className="input-label leading-normal leading-light text-xxs text-dark-gray">
              Please note that choosing a card saved on a user’s profile means they will be charged
              on any saved credit cards, including personal cards
            </small>

            {creditCards?.length ? (
              creditCards?.map(card => (
                <div
                  key={card.card_id}
                  className="rounded-md d-row w-full p-2 mt-2 justify-between">
                  <div className="d-row">
                    <label className="inline-flex items-center">
                      <input
                        autoComplete="off"
                        type="radio"
                        className="form-radio"
                        name="card"
                        value={card.card_id}
                        checked={provider.cardId == card.card_id}
                        onChange={e => dispatch({ field: 'cardId', value: e.target.value })}
                      />
                    </label>
                    <img
                      className="h-10 w-10 ml-2 rounded-ful"
                      src={card.card_type == 'VI' ? visaCard : cardIcon}
                    />
                    <div className="d-col ml-2">
                      <small className="input-label">{card.card_type}</small>
                      <small className="input-label">
                        **** **** **** {card.number.substring(card.number.length - 4)}
                      </small>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NotFound data={creditCards} message={'No credit cards found for this user'} />
            )}
          </div>
        )}

        <div className="d-row justify-end">
          <button onClick={() => closeModal()} className="btn-outline text-xs">
            Close
          </button>

          {!provider.placeOrderPressed && (
            <button onClick={() => pay(false, false)} className="btn-purple text-xs ml-3">
              Place Order
            </button>
          )}
        </div>

        <ConfirmationPopup
          visible={provider.bypassPlaceorder}
          btnLeft="Place Order"
          btnRight="Cancel"
          title="Cart item with a stopped shipment"
          subtitle="Restaurant has indicated that they cannot accept any additional orders at this time due to capacity limitations. Are you sure you want to place the order?"
          handleSuccess={() => {
            pay(true);
          }}
          handleReject={() => {
            dispatch({ field: 'bypassPlaceorder', value: false });
          }}
        />
      </div>
    </div>
  );
}

export default observer(PlaceOrderConfirmation);
