import React, { useReducer, useContext, useEffect, useMemo } from 'react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { Dropdown, Button } from 'components/Shared';
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input';
import useEmployeeId from 'utils/helpers/user';
import { observer } from 'mobx-react';
import Validation from 'utils/helpers/validation';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { officeEmployeeReminders, getReminderValue } from 'utils/constants/employee';
import array from 'utils/helpers/array';
import employee from 'utils/helpers/employee';

function OfficeUser({ visible, closeModal, selected, defaultTeam }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const userManagementStore = toJS(store.userManagementStore);
  const { officeTeams } = userManagementStore;
  const { employeeId } = new useEmployeeId();
  const { getCreateOfficeUserRole, getOfficeUserSubsidy } = employee;
  const history = useHistory();
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    team: '',
    role: '',
    reminder1: officeEmployeeReminders[0],
    reminder2: officeEmployeeReminders[0],
    reminder3: officeEmployeeReminders[0],
    subsidyType: 'daily',
    dailySubsidies: [
      { name: 'monday', value: 0.0 },
      { name: 'tuesday', value: 0.0 },
      { name: 'wednesday', value: 0.0 },
      { name: 'thursday', value: 0.0 },
      { name: 'friday', value: 0.0 },
      { name: 'saturday', value: 0.0 },
      { name: 'sunday', value: 0.0 },
    ],
    teamAdmin: false,
    weeklySubsidy: 0,
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'reset', payload: initialState });
  }, []);

  const savePressed = e => {
    e.preventDefault();

    if (provider.phone && !isValidPhoneNumber(provider.phone))
      alertHandler({
        title: 'You have entered an invalid phone number.',
        body: 'At this time only numbers with country code +1 are accepted. And in the following format: (123) 234-5678',
      });
    else if (!array.isEmpty(officeTeams) && !provider.team && !defaultTeam) {
      alertHandler({
        title: 'Please select a team.',
        body: '',
      });
    } else {
      let payload = {
        email: provider.email,
        first_name: provider.firstName,
        last_name: provider.lastName,
        first_reminder: provider.reminder1?.value,
        second_reminder: provider.reminder2?.value,
        third_reminder: provider.reminder3?.value,
        phone: provider.phone,
        send_email: e.nativeEvent.submitter.name === 'withEmail',
        location_id: selected?.value,
        location_employee: {
          subsidy_type: provider.subsidyType,
          role: provider.role?.value === 'admin' ? 'admin' : 'full_time',
        },
      };
      payload = getOfficeUserSubsidy(provider, payload);
      payload = getCreateOfficeUserRole(provider, payload, defaultTeam);

      store.userStore.setLoader(true);
      store.userStore.setButtonLoadingId(e.nativeEvent.submitter.name);
      store.userManagementStore
        .addOfficeEmployee({ employee_id: employeeId }, payload)
        .then(response => {
          store.userStore.setLoader(false);
          store.userStore.setButtonLoadingId(false);
          if (response.employee?.id) {
            closeModal();
            history.push(
              `/user-detail/${response?.employee?.user_id}?user_type=${
                response?.employee?.organization_type
              }&tab=${response?.employee?.organization_type === 'Location' ? 0 : 2}`
            );
          }
        });
    }
  };

  const fetchAndFillUser = email => {
    store.userStore.setLoader(true);
    store.userManagementStore.fetchUser(email, { employee_id: employeeId }).then(response => {
      store.userStore.setLoader(false);
      if (response?.status === 200) {
        dispatch({ field: 'phone', value: response?.data?.data?.attributes?.phone });
        dispatch({ field: 'firstName', value: response?.data?.data?.attributes?.first_name });
        dispatch({ field: 'lastName', value: response?.data?.data?.attributes?.last_name });
        dispatch({
          field: 'reminder1',
          value: getReminderValue(response?.data?.data?.attributes?.first_reminder),
        });
        dispatch({
          field: 'reminder2',
          value: getReminderValue(response?.data?.data?.attributes?.second_reminder),
        });
        dispatch({
          field: 'reminder3',
          value: getReminderValue(response?.data?.data?.attributes?.third_reminder),
        });
      }
    });
  };

  return (
    <div>
      <form className="rounded pt-4 w-full" onSubmit={e => savePressed(e)}>
        <fieldset disabled={selected === null}>
          <div className="d-row flex-wrap" key={officeTeams}>
            <div className="w-full md:w-1/3">
              <small className="input-label">First Name*</small>
              <input
                autoComplete="off"
                className="input-light"
                name="firstName"
                id="firstName"
                value={provider.firstName}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full md:w-1/3">
              <div className="mx-0 md:mx-3">
                <small className="input-label">Last Name*</small>
                <input
                  autoComplete="off"
                  className="input-light"
                  name="lastName"
                  id="lastName"
                  value={provider.lastName}
                  required
                  onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                />
              </div>
            </div>

            <div className="w-full md:w-1/3">
              <small className="input-label">Email*</small>
              <input
                autoComplete="off"
                className="input-light"
                name="email"
                type="email"
                id="email"
                value={provider.email}
                onBlur={e => {
                  fetchAndFillUser(e.target.value);
                }}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <small className="input-label">Phone Number</small>
            <Input
              defaultCountry="CA"
              value={provider.phone}
              className="input-light"
              placeholder="Example: (506) 234-5678"
              onChange={value => dispatch({ field: 'phone', value: value ? value : '' })}
            />
          </div>

          <div className="flex items-end mt-5 flex-wrap">
            <div className="w-full md:w-1/3 d-col md:pr-4">
              <small className="input-label">Team {officeTeams?.length > 0 && '*'}</small>

              <Select
                placeholder="Select"
                className="input-label capitalize overflow-visible mt-1"
                options={officeTeams}
                closeMenuOnSelect
                value={provider.team ? provider.team : defaultTeam}
                isClearable
                onChange={selected => {
                  dispatch({ field: 'role', value: '' });
                  dispatch({ field: 'team', value: selected });
                }}
              />
            </div>

            <div className="w-full md:w-1/3">
              <small className="input-label">Role</small>
              <Dropdown
                isClearable={true}
                isSearchable={false}
                selected={provider.role}
                options={
                  provider?.teamAdmin
                    ? [{ label: 'Full Time', value: 'full_time' }]
                    : [
                        { label: 'Full Time', value: 'full_time' },
                        { label: 'Account Admin', value: 'admin' },
                      ]
                }
                onChange={selected => dispatch({ field: 'role', value: selected })}
              />
            </div>

            {provider?.role?.value !== 'admin' && (
              <div className="d-row m-2">
                <label className="inline-flex items-center">
                  <input
                    autoComplete="off"
                    type="checkbox"
                    className="form-checkbox"
                    onClick={e => dispatch({ field: 'teamAdmin', value: e.target.checked })}
                    checked={provider.teamAdmin}
                  />
                  <span className="ml-1 text-xs font-inter-regular">Team Admin</span>
                </label>
              </div>
            )}
          </div>

          <div className="d-row flex-wrap mt-5">
            <div className="w-full md:w-1/3 d-col">
              <small className="input-label mb-1">Reminder 1</small>
              <Dropdown
                options={officeEmployeeReminders}
                selected={provider.reminder1}
                isSearchable={false}
                onChange={selected => dispatch({ field: 'reminder1', value: selected })}
              />
            </div>

            <div className="w-full md:w-1/3">
              <div className="w-full px-0 md:px-4 d-col">
                <small className="input-label mb-1">Reminder 2</small>
                <Dropdown
                  options={officeEmployeeReminders}
                  selected={provider.reminder2}
                  isSearchable={false}
                  onChange={selected => dispatch({ field: 'reminder2', value: selected })}
                />
              </div>
            </div>

            <div className="w-full md:w-1/3 d-col">
              <small className="input-label mb-1">Reminder 3</small>
              <Dropdown
                options={officeEmployeeReminders}
                selected={provider.reminder3}
                isSearchable={false}
                onChange={selected => dispatch({ field: 'reminder3', value: selected })}
              />
            </div>
          </div>

          <div className="d-col mt-5">
            <small className="text-black text-sm font-inter-regular">Subsidy Type</small>
            <div className="d-row mt-3">
              <div className="d-row mr-4">
                <label className="inline-flex items-center">
                  <input
                    autoComplete="off"
                    type="radio"
                    className="form-radio"
                    name="radio"
                    value="daily"
                    checked={provider.subsidyType === 'daily'}
                    onClick={e => dispatch({ field: 'subsidyType', value: e.target.value })}
                  />
                  <span className="ml-2 text-xs font-inter-regular">Daily</span>
                </label>
              </div>
              <div className="d-row">
                <label className="inline-flex items-center">
                  <input
                    autoComplete="off"
                    type="radio"
                    className="form-radio"
                    name="radio"
                    value="weekly"
                    checked={provider.subsidyType === 'weekly'}
                    onClick={e => dispatch({ field: 'subsidyType', value: e.target.value })}
                  />
                  <span className="ml-2 text-xs font-inter-regular">Weekly</span>
                </label>
              </div>
            </div>
          </div>

          <div className="d-col mt-5">
            {provider.subsidyType === 'daily' ? (
              <div className="d-row flex flex-wrap">
                {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(
                  item => (
                    <div className="mr-1 md:mr-2 w-1/4">
                      <small className="input-label capitalize">{item}</small>

                      <input
                        autoComplete="off"
                        className="input-light"
                        name={item}
                        id={item}
                        min={0}
                        onWheel={event => event.currentTarget.blur()}
                        value={
                          [
                            ...provider.dailySubsidies.filter(subsidy => subsidy.name == item),
                          ].shift()?.value ?? ''
                        }
                        onChange={e => {
                          dispatch({
                            field: 'dailySubsidies',
                            value: [
                              ...provider.dailySubsidies.filter(
                                subsidy => subsidy.name !== e.target.id
                              ),
                              { name: e.target.id, value: e.target.value },
                            ],
                          });
                        }}
                        type="number"
                        step={'0.01'}
                      />
                    </div>
                  )
                )}
              </div>
            ) : (
              <input
                autoComplete="off"
                className="input-light w-1/4"
                name="weeklySubsidy"
                id="weeklySubsidy"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                type="number"
                step={'0.01'}
                required
              />
            )}
          </div>

          <div className="w-full"></div>

          <div className="flex flex-col md:flex-row justify-end mt-5">
            <div className={`d-row ${!selected && 'opacity-50'}`}>
              <Button
                id="withoutEmail"
                name="withoutEmail"
                type="submit"
                disabled={!selected}
                label="Save"
                className="btn-purple"
              />

              <Button
                disabled={!selected}
                id="withEmail"
                name="withEmail"
                type="submit"
                className="btn-purple text-xs mx-2 "
                label="Save and Email"
              />
            </div>

            <button
              className="btn-outline text-xs mt-2 md:mt-0"
              onClick={() => {
                dispatch({ type: 'reset', payload: initialState });
                closeModal();
              }}
              type={'button'}>
              <span className="text-xs p-3">Close</span>
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default observer(OfficeUser);
