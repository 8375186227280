import React, { useState, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ placeholder, disabled = false, onChange, value, id }) => {
  if (disabled)
    return (
      <div>
        <small className="input-label text-red-500">Permission denied</small>
      </div>
    );

  return (
    <div data-testid={id}>
      <ReactQuill
        theme="snow"
        value={value}
        modules={{
          toolbar: [['bold', 'italic', 'underline', 'strike'], ['clean']],
        }}
        onChange={typedInput => {
          if (value?.includes('<s>') && typedInput?.includes('<u>')) {
            typedInput = typedInput?.replace(/<s>/, '');
            typedInput = typedInput?.replace(/<\/s>/g, '');
          }
          if (value?.includes('<u>') && typedInput?.includes('<s>')) {
            typedInput = typedInput?.replace(/<u>/, '');
            typedInput = typedInput?.replace(/<\/u>/g, '');
          }

          onChange(typedInput === '<p><br></p>' ? '' : typedInput);
        }}
      />
    </div>
  );
};

export default Editor;
