const ColorIndicators = ({ restaurantCapacity, closeToStop, aboveCapacity, pickup, dropoff }) => {
  return (
    <div className="d-row mb-2 justify-end">
      {pickup && (
        <div className="d-row items-center">
          <div className="h-3 w-3 bg-green-700 mr-1" />
          <small className="input-label">Pickup</small>
        </div>
      )}

      {dropoff && (
        <div className="d-row items-center px-3">
          <div className="h-3 w-3 bg-orange-brown mr-1" />
          <small className="input-label">Dropoff</small>
        </div>
      )}

      {restaurantCapacity && (
        <div className="d-row items-center">
          <div className="h-3 w-3 bg-purple-300 mr-1" />
          <small className="input-label">Restaurant Capacity</small>
        </div>
      )}

      {closeToStop && (
        <div className="d-row items-center px-3">
          <div className="h-3 w-3 bg-closeToStop mr-1" />
          <small className="input-label">Close to stop</small>
        </div>
      )}

      {aboveCapacity && (
        <div className="d-row items-center">
          <div className="h-3 w-3 bg-aboveCapacity mr-1" />
          <small className="input-label">Above capacity</small>
        </div>
      )}
    </div>
  );
};

export default ColorIndicators;
