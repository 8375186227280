import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { StoreContext } from 'index';
import ClipLoader from 'react-spinners/ClipLoader';
import { toJS } from 'mobx';

const override = css`
  display: block;
  opacity: 1;
`;

function Button({ label, className, type = 'button', onClick, isDisabled = false, name, id }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const { buttonLoading } = userStore;

  return type === 'submit' ? (
    <button
      type="submit"
      name={name}
      className={'d-row justify-center ' + className}
      disabled={buttonLoading === id || isDisabled}>
      {buttonLoading === id && (
        <ClipLoader
          color={className?.search('btn-outline' ? '#393686' : '#fff')}
          loading={true}
          css={override}
          size={15}
        />
      )}
      <small className={`${buttonLoading === id && 'ml-2'} text-xs font-inter-regular`}>
        {label}
      </small>
    </button>
  ) : (
    <button
      onClick={() => {
        if (id) store.userStore.setButtonLoadingId(id);
        onClick();
      }}
      name={name}
      type="button"
      className={'d-row justify-center ' + className}
      disabled={buttonLoading === id || isDisabled}>
      {buttonLoading === id && (
        <ClipLoader
          color={className?.search('btn-outline' ? '#393686' : '#fff')}
          loading={true}
          css={override}
          size={15}
        />
      )}
      <small className={`${buttonLoading === id && 'ml-2'} text-xs font-inter-regular`}>
        {label}
      </small>
    </button>
  );
}
export default Button;
