import validation from 'utils/helpers/validation';
import { ToggleButton } from 'components/Shared';

function RestaurantSlotsField({ provider, dispatch }) {
  return (
    <>
      <div className="w-full d-col my-1.5">
        <small className="input-label my-1">Manual restaurant slots calculation</small>

        <ToggleButton
          toggleState={provider.manualRestaurantSlots}
          setToggleState={value => dispatch({ field: 'manualRestaurantSlots', value: value })}
        />
      </div>

      <div className="w-full mt-2">
        <small className="input-label">Restaurant Slots</small>

        <input
          autoComplete="off"
          className="input-light"
          name="restaurantSlots"
          id="restaurantSlots"
          value={provider.restaurantSlots}
          type="number"
          disabled={!provider.manualRestaurantSlots}
          onWheelCapture={e => {
            e.target.blur();
          }}
          onKeyPress={validation.preventMinus}
          onChange={e => {
            if (e.target.value > -1) dispatch({ field: e.target.name, value: e.target.value });
          }}
        />
      </div>
    </>
  );
}

export default RestaurantSlotsField;
