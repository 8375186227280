import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Screen from 'utils/helpers/ScreenSize';
import { toJS } from 'mobx';
import { ModalBackground, OfficeUserOrderStatus, EmbedHTML } from 'components/Shared';
import orderHelper from 'utils/helpers/order';
import moment from 'moment';
import { useQueryMethods } from 'hooks/useQueryMethods';
import useEmployeeId from 'utils/helpers/user';
import { SpecialInstruction } from 'assets/img';
import classnames from 'classnames';

const TotalDetail = ({ title, simple, value }) => (
  <span className="font-inter-regular text-dark-gray text-xs pt-3">
    {title}
    {!simple ? (
      <small className="text-black font-inter-semibold">$ {Number(value).toFixed(2)}</small>
    ) : (
      <small className="text-black font-inter-semibold">{value}</small>
    )}
  </span>
);

function AccountOrderDetails({ visible, orderDetails, closeModal }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const screenType = Screen.ScreenType();
  const [orderStatus, setOrderStatus] = useState(orderDetails?.attributes?.status);
  const [eta, setETA] = useState(null);
  let { getOrderStatus, extractOfficeUserOrderETA } = orderHelper;
  let { showPageId, getParam } = useQueryMethods();
  let { employeeId } = useEmployeeId();
  let etaTimer = null;
  let orderStatusTimer = null;

  useEffect(() => {
    if (orderDetails) {
      setOrderStatus(orderDetails?.attributes?.status);

      checkOrderStatus();
    }

    return () => {
      if (orderStatusTimer) clearInterval(orderStatusTimer);
      if (etaTimer) clearInterval(etaTimer);
    };
  }, [orderDetails, orderStatus]);

  const getETAData = () => {
    store.accountManagementStore
      .getETA(orderDetails.attributes.id, { employee_id: employeeId, location_id: showPageId() })
      .then(({ data }) => {
        setETA(extractOfficeUserOrderETA(data));
      });
  };

  const startETATimer = () => {
    etaTimer = setInterval(() => {
      if (eta === 'completed' || eta === 'ORDER COMPLETED' || eta === 'Completed') {
        clearInterval(etaTimer);
      } else {
        getETAData();
      }
    }, 120000);
  };

  const checkOrderStatus = () => {
    let currentDay = moment();
    let isToday = currentDay.isSame(moment(orderDetails.attributes.order_date), 'day');

    if (
      orderStatus === 'pending' ||
      orderStatus === 'preview' ||
      (orderStatus === 'sent' && isToday)
    ) {
      orderStatusTimer = setInterval(() => {
        store.accountManagementStore
          .getOrderDetails(orderDetails.attributes.id, {
            employee_id: employeeId,
            location_id: showPageId(),
          })
          .then(data => {
            setOrderStatus(data.attributes?.status);
            setETA(getOrderStatus(data.attributes?.status));
            if (orderStatus === 'confirmed') {
              getETAData();
              startETATimer();

              clearInterval(orderStatusTimer);
            }
          });
      }, 120000);
    }
    if (orderStatus === 'confirmed') {
      clearInterval(orderStatusTimer);
      getETAData();
      startETATimer();
    } else {
      setETA(getOrderStatus(orderDetails.attributes.status));
      clearInterval(etaTimer);
    }
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{
          width: screenType.isDesktop ? '60%' : '100%',
          height: screenType?.isDesktop ? '100%' : '90%',
        }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <strong>{orderDetails?.attributes?.user_name}</strong>

          <div className="d-row justify-between flex-wrap my-8 w-2/3">
            <div className="d-col">
              <small className="text-dark-gray text-xs">Office:</small>
              <small className="pt-2 font-inter-regular">
                {orderDetails?.attributes?.location_name}
              </small>
            </div>
            <div className="d-col">
              <small className="text-dark-gray text-xs">Order Date:</small>
              <small className="pt-2 font-inter-regular">
                {orderDetails?.attributes?.order_date}
              </small>
            </div>

            <div className="d-col">
              <small className="text-dark-gray text-xs">Meal Type:</small>
              <small className="pt-2 font-inter-regular">
                {orderDetails?.attributes?.meal_name}
              </small>
            </div>
          </div>

          <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="w-full md:table-fixed">
              <thead className="bg-white border-b">
                <tr>
                  <th
                    scope="col"
                    className="t-head text-left px-2 border-r"
                    style={{ width: screenType.isMobile ? '10%' : '60%' }}>
                    <small className="t-row">Item</small>
                  </th>

                  <th
                    scope="col"
                    className="t-head px-2 border-r text-left"
                    style={{ width: screenType.isMobile ? '5%' : '20%' }}>
                    <small className="t-row">Quantity</small>
                  </th>

                  <th
                    scope="col"
                    className="t-head px-2 border-r text-left"
                    style={{ width: screenType.isMobile ? '10%' : '20%' }}>
                    <small className="t-row">Food Total</small>
                  </th>
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td
                    colSpan={3}
                    className="py-3 px-2 align-middle break-words text-left font-inter-regular text-xs text-dark-gray">
                    <table className="w-full">
                      {orderDetails?.attributes?.order_items?.map((order_item, item_index) => (
                        <tr key={item_index}>
                          <td colSpan={3} style={{ width: '100%' }}>
                            <table style={{ width: '100%' }}>
                              <tr>
                                <td style={{ width: '75%' }}>
                                  <EmbedHTML
                                    text={order_item.attributes.menu_item.display_name_with_html}
                                    color="text-black mt-2 text-xs"
                                  />

                                  <small className="input-label capitalize text-black">
                                    {order_item.attributes?.permanent_instructions}
                                  </small>

                                  {order_item?.attributes?.special_instructions && (
                                    <p className="overflow-ellipsis items-start d-row overflow-hidden text-xs lg:text-xs text-pink-500 font-inter-regular">
                                      <img
                                        src={SpecialInstruction}
                                        className="object-contain h-6 w-6 pr-2"
                                      />
                                      {order_item.attributes?.special_instructions}
                                    </p>
                                  )}
                                </td>

                                <td className="overflow-ellipsis overflow-hidden text-black font-inter-regular lg:text-sm text-xs text-left py-3 px-2 align-middle break-words input-label d-row">
                                  {order_item.attributes.quantity}
                                </td>

                                <td className="py-2 px-2 align-middle break-words font-inter-regular lg:text-sm text-xs text-left input-label">
                                  ${parseFloat(order_item.attributes.price || 0).toFixed(2)}
                                </td>
                              </tr>

                              {order_item.attributes.order_item_options.map(option => (
                                <tr key={option.id}>
                                  <td style={{ width: '75%' }}>
                                    <div>
                                      <div className="d-row">
                                        <EmbedHTML
                                          text={
                                            option.attributes.option_group_display_name_with_html
                                          }
                                          append={': '}
                                          color="text-gray-500 text-xs break-normal"
                                        />
                                        <EmbedHTML
                                          text={option.attributes.option_display_name_with_html}
                                          color="text-gray-500 text-xs break-normal"
                                        />
                                      </div>
                                    </div>
                                  </td>

                                  <td className="py-1 px-2 align-middle break-words text-left input-label"></td>

                                  <td className="py-1 px-2 align-middle break-words text-left">
                                    <div>
                                      <small className="font-inter-regular overflow-ellipsis overflow-hidden text-xs d-row">
                                        ${parseFloat(option.attributes.price || 0).toFixed(2)}
                                      </small>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex flex-col md:flex-row justify-between md:w-2/3 w-full items-start">
            <div className="my-7 d-col">
              <TotalDetail
                simple
                title={'Total Items: '}
                value={orderDetails?.attributes?.order_items.length}
              />

              <TotalDetail title={'Food Total: '} value={orderDetails?.attributes?.subtotal} />

              <TotalDetail
                title={'Tax: '}
                value={
                  parseFloat(orderDetails?.attributes?.hst) +
                  parseFloat(orderDetails?.attributes?.tax_on_delivery_and_service_fee)
                }
              />

              <TotalDetail title={'Tip: '} value={orderDetails?.attributes?.tip} />

              <TotalDetail title={'Service Fee: '} value={orderDetails?.attributes?.service_fee} />

              <TotalDetail
                title={'Delivery Fee: '}
                value={orderDetails?.attributes?.delivery_fee}
              />

              {orderDetails?.attributes?.discounted_amount > 0 && (
                <TotalDetail
                  title={'Discount: '}
                  value={orderDetails?.attributes?.discounted_amount}
                />
              )}

              <TotalDetail title={'Grand Total: '} value={orderDetails?.attributes?.grand_total} />
            </div>

            <div className="my-7 d-col">
              <TotalDetail
                title={'Paid By User: '}
                value={orderDetails?.attributes?.amount_paid_by_employee}
              />

              <TotalDetail
                title={'Subsidized By Office: '}
                value={orderDetails?.attributes?.amount_paid_by_employer}
              />
            </div>
          </div>

          <div className="d-row justify-between my-4">
            <OfficeUserOrderStatus status={eta || orderStatus} />

            <button className="btn-purple text-xs" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountOrderDetails;
