import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import classnames from 'classnames';

const FixedTable = ({
  headings,
  data,
  softDeleted = false,
  isLoading = false,
  withOutBorder = false,
}) => (
  <div className="d-col" key={isLoading}>
    <table
      className={classnames('w-full table-fixed overflow-visible rounded-md sm:rounded-lg ', {
        'border-gray-200 border shadow-sm': !withOutBorder,
      })}>
      <thead className="border-b bg-white">
        <tr>
          {headings.map((heading, index) => (
            <th key={index} className="w-1/6 py-4 px-2 heading-medium text-xs">
              {heading}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        <tr className="align-top bg-gray-100">
          {data?.map((colData, index) => (
            <td
              key={index}
              className={classnames('break-words input-label p-2 ', {
                'text-red-700 opacity-60': softDeleted,
              })}>
              {isLoading ? <Skeleton count={1} height="40px" /> : colData}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  </div>
);

export default FixedTable;
