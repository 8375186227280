import React, { useEffect, useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import { alertHandler } from 'utils/middlewares/alertHandler';
import useEmployeeId from 'utils/helpers/user';
import { useQueryMethods } from 'hooks/useQueryMethods';
import classnames from 'classnames';

function CreateTeam({ visible, closeModal, employeeDetails, getEmployees, removeEmployee }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  let { showPageId } = useQueryMethods();
  let { employeeId } = useEmployeeId();

  const initialState = {
    teamName: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (visible) {
      dispatch({ type: 'reset', payload: initialState });
    }
  }, [visible]);

  const updateEmployee = payload => {
    store.userStore
      .updateEmployee(
        employeeDetails?.id,
        { restaurant_id: userStore?.defaultRestaurant?.value },
        payload
      )
      .then(response => {
        if (response?.data?.employee?.id) closeModal();
      });
  };

  const addTeam = () => {
    if (!provider.teamName) {
      alertHandler({ title: 'Enter team name.' });
      return;
    }

    let payload = {
      name: provider.teamName,
    };

    store.userStore.setLoader(true);
    store.accountManagementStore
      .addTeam(
        {
          employee_id: employeeId,
          location_id: showPageId(),
        },
        payload
      )
      .then(response => {
        closeModal();
        getEmployees();
        store.userStore.setLoader(false);
      });
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down h-3/5"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px', maxHeight: '400px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <span className="font-inter-semibold text-lg">Add New Team</span>

          <div className="w-full md:w-1/2 mt-5">
            <small>Team Name</small>
            <input
              autoComplete="off"
              className="input-light"
              required
              value={provider.teamName}
              onChange={e => dispatch({ field: 'teamName', value: e.target.value })}
            />
          </div>

          <button
            onClick={() => addTeam()}
            className="absolute bottom-4 right-4 btn-purple text-xs">
            Add Team
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateTeam;
