import React, { useEffect, useReducer, useContext, useState } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import { ErrorToast, PasswordInput } from 'components/Shared';
import Validation from 'utils/helpers/validation';
import classnames from 'classnames';

function ResetPassword({ visible, closeModal }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const [toastVisible, setToastVisible] = useState(false);
  const initialState = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setToastVisible(false);
    dispatch({ type: 'reset', payload: initialState });
  }, [visible]);

  const submit = e => {
    e.preventDefault();
    let payload = {
      current_password: provider.currentPassword,
      user: {
        password: provider.newPassword,
      },
    };

    let result = Validation.validatePassword(provider.newPassword);
    if (provider.newPassword !== provider.newPasswordConfirm)
      dispatchError("New password doesn't match");
    else if (!result)
      dispatchError(
        'The password should contain at least one number and letter with a minimum of 8 characters'
      );
    else
      store.userStore.updateUserProfile(payload).then(response => {
        if (response?.data?.data) closeModal();
      });
  };

  const dispatchError = message => {
    dispatch({
      field: 'errorMessage',
      value: message,
    });
    setToastVisible(true);
  };

  const isInfoFilled = () => {
    return provider.currentPassword && provider.newPassword && provider.newPasswordConfirm;
  };

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll md:overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <h5 className="font-inter-semibold">Change Password</h5>
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="w-full ">
              <small className="input-label">Current Password</small>

              <PasswordInput
                inputLight
                placeholder="Current Password"
                onChange={e => dispatch({ field: 'currentPassword', value: e.target.value })}
              />
            </div>
            <div className="w-full">
              <small className="input-label">New Password</small>

              <PasswordInput
                inputLight
                placeholder="New Password"
                onChange={e => dispatch({ field: 'newPassword', value: e.target.value })}
              />
            </div>
            <div className="w-full">
              <small className="input-label">Confirm New Password</small>

              <PasswordInput
                inputLight
                placeholder="Confirm Password"
                onChange={e => dispatch({ field: 'newPasswordConfirm', value: e.target.value })}
              />
            </div>

            <ErrorToast visible={toastVisible} error={provider.errorMessage} />

            <div className="items-center flex flex-col pt-2 md:pt-24">
              <button
                className={`btn-purple w-full text-sm ${
                  !isInfoFilled() && 'opacity-50 cursor-not-allowed'
                }`}
                type="submit"
                disabled={!isInfoFilled()}>
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
