import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SpecialInstruction } from 'assets/img';
import Validation from 'utils/helpers/validation';
import Screen from 'utils/helpers/ScreenSize';
import { ModalBackground } from 'components/Shared';
import dateTime from 'utils/helpers/dateTime';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

function CartDetail({ orderData, closeModal }) {
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  const { restaurantDetail } = restaurantManagementStore;
  const timeZone = restaurantDetail?.data?.attributes?.tzinfo;

  return (
    <div
      className={classnames({
        'modal-visible': orderData,
        hidden: !orderData,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6 flex-col">
          <button onClick={closeModal}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <div className="flex flex-col pr-5">
            <div className="flex flex-col">
              <small className="text-sm font-inter-regular">
                <strong className="font-inter-semibold">Client:</strong>{' '}
                {orderData?.attributes?.user_name}
              </small>
            </div>

            <div className="flex flex-col mt-3">
              <span className="font-inter-regular text-sm">
                <strong className="font-inter-semibold">Ordered at:</strong>{' '}
                {dateTime.formatDate(
                  orderData?.attributes?.order_date || orderData?.attributes?.created_at,
                  'DD-MM-YYYY'
                )}
              </span>
            </div>
          </div>

          <table className="w-full table-fixed">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="lg:pr-6 pt-3 w-1/6 text-left tracking-wider">
                  <small className="text-xs text-black tracking-wider font-inter-semibold font-normal">
                    Qty
                  </small>
                </th>

                <th scope="col" className="px-1.5 lg:px-6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-semibold font-normal">Item</small>
                </th>

                <th scope="col" className="px-1.5 w-1/6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-semibold font-normal">
                    Price
                  </small>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {orderData?.attributes?.order_items?.map(({ id, attributes }) => (
                <tr key={id} className="relative cursor-pointer">
                  <td className="lg:pr-6 py-3 whitespace-nowrap align-top">
                    <small className="text-xs font-inter-regular text-dark-gray">
                      {attributes?.quantity}
                    </small>
                  </td>

                  <td className="px-1.5 lg:px-6 py-3 align-top" colSpan="2">
                    <table className="w-full table-fixed">
                      <tbody>
                        <tr>
                          <td colSpan={screenType.isMobile ? '5' : '7'}>
                            <span className="text-sm text-black font-inter-regular flex flex-row items-start">
                              {attributes?.special_instructions && (
                                <img
                                  src={SpecialInstruction}
                                  className="object-contain h-7 w-7 pr-2"
                                  alt=""
                                />
                              )}
                              <p className="break-normal font-inter-regular text-xs text-black">
                                {attributes?.menu_item?.title} ({attributes.category}){' '}
                              </p>
                            </span>

                            <p className="break-normal text-xs text-pink-500 font-inter-regular">
                              {attributes?.special_instructions}
                            </p>
                          </td>
                          <td className="flex items-start">
                            <small className="text-xs md:text-xs text-black font-inter-regular">
                              {Validation.priceFormatter(attributes?.menu_item?.price)}
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}

              <tr key={2} className="relative cursor-pointer border-t border-gray-200">
                <td className="lg:pr-6 py-3 whitespace-nowrap align-top"></td>

                <td className="px-1.5 lg:px-6 py-3 align-top whitespace-nowrap flex flex-col">
                  <div className="flex flex-col">
                    <small className="font-inter-semibold text-sm pt-2">Subtotal :</small>

                    <small className="font-inter-semibold text-sm pt-2">Tax :</small>

                    <small className="font-inter-semibold text-sm pt-2">Grand Total :</small>
                  </div>
                </td>

                <td className="px-1.5 py-3 whitespace-nowrap align-top ">
                  <div className="flex flex-col">
                    <small className="font-inter-regular pt-2">
                      {Validation.priceFormatter(parseFloat(orderData?.attributes?.subtotal))}
                    </small>

                    <small className="font-inter-regular pt-2">
                      {Validation.priceFormatter(parseFloat(orderData?.attributes?.tax))}
                    </small>

                    <small className="font-inter-regular pt-2">
                      {Validation.priceFormatter(orderData?.attributes?.grand_total)}
                    </small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default observer(CartDetail);
