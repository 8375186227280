import React, { useReducer, useContext } from 'react';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { Dropdown, ModalBackground } from 'components/Shared';
import { CreateMealSeriesFields } from 'components/Shared';

function CreateMealSeries({ visible, update, meal, closeModal, locationId, timezone }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);

  const initialState = {
    transitNumber: '',
    institutionNumber: '',
    accountNumber: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  return (
    <div
      className={
        visible
          ? `fixed pin inset-x-0 px-4 pb-4 inset-0 flex items-center justify-center z-30`
          : 'hidden'
      }>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ maxHeight: '95%', width: '1290px' }}
        aria-labelledby="modal-headline">
        <CreateMealSeriesFields
          meal={meal}
          update={update}
          timezone={timezone}
          closeModal={() => closeModal()}
          locationId={locationId}
        />
      </div>
    </div>
  );
}

export default CreateMealSeries;
