import { makeObservable, observable, action } from 'mobx';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import { alertHandler } from 'utils/middlewares/alertHandler';

class CateringOrdersStore {
  cateringOrders = [];

  constructor() {
    makeObservable(this, {
      cateringOrders: observable,
    });
  }

  async getCateringOrders(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.cateringOrders()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.cateringOrders = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateGroupOrder(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.groupOrders()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
}

export default CateringOrdersStore;
