import React from 'react';
import { css } from '@emotion/react';
import PropagateLoader from 'react-spinners/PropagateLoader';

const override = css`
  display: block;
  opacity: 1;
`;

function ButtonLoader({ dark }) {
  return (
    <PropagateLoader color={dark ? '#DA2D8B' : '#fff'} loading={true} css={override} size={8} />
  );
}

export default ButtonLoader;
