import React, { useContext } from 'react';
import { toJS } from 'mobx';
import { ModalBackground } from 'components/Shared';
import { StoreContext } from 'index';
import useEmployeeId from 'utils/helpers/user';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

function ActionCableAlert({ visible }) {
  const store = useContext(StoreContext);
  let history = useHistory();
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  let { actionCablePayload } = restaurantManagementStore;
  const { employeeId } = new useEmployeeId();

  const getClientRanks = () => {
    let params = { employee_id: employeeId, page: 1, per_page: 8 };
    store.userStore.setLoader(true);
    store.clientRankStore.getClientRanks(params).then(() => {
      store.userStore.setLoader(false);
    });
  };

  const navigate = () => {
    let navigateTo = '';
    if (actionCablePayload?.message?.includes('Clients ranks are updated!')) {
      getClientRanks();
      navigateTo = '/client-score';
    } else {
      navigateTo = `/restaurant-detail/${actionCablePayload?.data?.restaurant_id}?tab=3`;
    }

    store.restaurantManagementStore.setActionCablePayload(null);
    store.restaurantManagementStore.setDefaultSummaryDate(new Date());
    history.push(navigateTo);
  };

  let navigateButton = (
    <button onClick={navigate} className="bg-light-purple btn-orange mb-3 mt-11">
      <small className="text-sm font-inter-medium">Okay</small>
    </button>
  );

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '400px' }}
        aria-labelledby="modal-headline">
        {actionCablePayload?.message?.includes('Clients ranks are updated!') ? (
          <div className="flex bg-white p-6 border-l-8 flex-col border-light-purple">
            <small className="text-dim-black font-inter-regular text-sm py-3 break-words">
              Clients ranks are updated!
            </small>

            {navigateButton}
          </div>
        ) : (
          <div className="flex bg-white p-6 border-l-8 flex-col border-light-purple">
            <h5 className="font-inter-medium capitalize">{actionCablePayload?.data?.menu_name}</h5>

            <small className="text-dim-black font-inter-regular text-sm py-3 break-words">
              Your menu is created successfully and all of the internal menu items are copied.
            </small>

            {navigateButton}
          </div>
        )}
      </div>
    </div>
  );
}

export default ActionCableAlert;
