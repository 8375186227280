import { useQueryMethods } from 'hooks/useQueryMethods';
import { useCallback, useContext } from 'react';
import { StoreContext } from 'index';
import useEmployeeId from 'utils/helpers/user';
import { useParams } from 'react-router-dom';
import { toJS } from 'mobx';
import { alertHandler } from 'utils/middlewares/alertHandler';

export const useEmployeeMethods = () => {
  const store = useContext(StoreContext);
  const { employeeId } = new useEmployeeId();
  const { id } = useParams();
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  const { restaurantDetail, defaultDestinationCode } = restaurantManagementStore;
  const { getParam } = useQueryMethods();

  const isOfficeUser = (userType = getParam('user_type')) => {
    return userType === 'Location';
  };

  const isRestaurantUser = (userType = getParam('user_type')) => {
    return userType === 'Restaurant';
  };

  const resetPasswordInstructions = (name, organization, email) => {
    store.userStore.setLoader(true);
    store.userStore.forgotPassword({ email, organization }).then(response => {
      store.userStore.setLoader(false);
      if (response?.data?.message) {
        alertHandler({
          title: 'Reset password instructions',
          body: `A password reset email has been sent to ${name}`,
        });
      }
    });
  };

  return { isOfficeUser, isRestaurantUser, resetPasswordInstructions };
};
