import React from 'react';
import { Logo } from '../../assets/img';
import Dropdown from './Dropdown';
import { useLocation, matchPath } from 'react-router-dom';
import Screen from 'utils/helpers/ScreenSize';
import { ActionCableWrapper } from 'components/Shared';

export default function Navbar({ sidebar, toggleSidebar }) {
  const location = useLocation();
  const screenType = Screen.ScreenType();

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Daily Summary';
      case '/profile':
        return 'Profile';
      case '/team-management':
        return 'Team Management';
      case '/restaurant-profile':
        return 'Restaurant Profile';
      case '/accounting':
        return 'Accounting';
      case '/menu':
        return 'Menu';
      case '/schedule':
        return 'Schedule';
      default:
        return 'Daily Summary';
    }
  };

  return (
    <>
      <nav className="flex flex-wrap items-center justify-between px-2 pt-0 bg-white border shadow-md z-20 sticky top-0">
        <div className="w-full flex flex-wrap items-center justify-between">
          <div className="relative d-row justify-between w-full">
            <div className="flex flex-row items-center">
              {screenType.isTabletOrMobile && (
                <div
                  className="icon cursor-pointer"
                  style={{ fontSize: 35 }}
                  onClick={() => toggleSidebar(!sidebar)}>
                  &equiv;
                </div>
              )}

              <Screen.Desktop>
                <div>
                  <img src={Logo} className="h-20 w-56 self-start object-contain" />
                </div>
              </Screen.Desktop>
            </div>

            {/* <small className="text-center font-inter-regular w-1/2 md:hidden">
              {getPageTitle()}
             </small> */}

            <ActionCableWrapper>
              <div>
                <Dropdown />
              </div>
            </ActionCableWrapper>
          </div>
        </div>
      </nav>
    </>
  );
}
