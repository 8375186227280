import { makeObservable, observable, action } from 'mobx';
import { client } from '../store';
import api from '../store/api';
import qs from 'qs';
import array from 'utils/helpers/array';
import LocalStorage from 'utils/helpers/localStorage';
import { alertHandler } from 'utils/middlewares/alertHandler';
import { toJS } from 'mobx';
import axios from 'axios';
import dateTime from 'utils/helpers/dateTime';

class RestaurantManagementStore {
  restaurants = {};
  restaurantDetail = null;
  markets = [];
  zones = [];
  bankingInformation = null;
  accountingEmails = null;
  schedules = [];
  allSchedules = [];

  cateringOrders = [];
  eta = null;
  shipmentsETA = [];

  menus = [];
  menu = null;
  optionCategories = null;
  menuTypes = [];
  defaultMenuType = null;
  menuCategories = [];
  tags = [];
  employees = {};
  reportHistory = [];

  //orders
  orderSummaries = {};
  cateringSummaries = {};
  hungerhubCateringSummaries = {};
  directCateringSummaries = {};
  ordersPrepare = [];

  ordersPrepareTotalBags = 0;
  ordersPrepareTotalItems = 0;
  defaultDateSelect = new Date();

  destinationCodes = [];
  defaultDestinationCode = {};

  //menu editing page
  menusList = [];
  categoriesList = [];
  itemsList = [];
  optionGroupList = [];
  menuOptionsList = [];

  specificMenuCategories = [];
  specificMenu = null; //to hold the data for filtered menu
  specificCategory = null;
  specificItem = null;
  specificOptionGroup = null;
  specificOption = null;
  forceUpdateOptionCategories = false;

  itemBadges = [];
  entireMenus = [];
  fortnightlyStatus = null;

  fortnightlyStatus = false;
  parentHierarchy = [];
  parentTemplateInfo = null;
  actionCablePayload = null;

  selectedFilter = null;
  sortableDragging = false;

  upcomingOrders = [];
  upcomingOrdersPagy = null;
  upcomingOrderStats = null;
  overwriteStatus = null;

  constructor() {
    makeObservable(this, {
      restaurants: observable,
      schedules: observable,
      allSchedules: observable,
      cateringOrders: observable,
      restaurantDetail: observable,
      markets: observable,
      zones: observable,
      searchRestaurants: action,
      menus: observable,
      menu: observable,
      optionCategories: observable,
      menuTypes: observable,
      defaultMenuType: observable,
      menuCategories: observable,
      employees: observable,
      reportHistory: observable,

      defaultDateSelect: observable,
      ordersPrepareTotalBags: observable,
      ordersPrepareTotalItems: observable,

      destinationCodes: observable,
      defaultDestinationCode: observable,
      orderSummaries: observable,
      cateringSummaries: observable,
      hungerhubCateringSummaries: observable,
      directCateringSummaries: observable,

      ordersPrepare: observable,
      eta: observable,
      shipmentsETA: observable,

      menusList: observable,
      categoriesList: observable,
      itemsList: observable,
      optionGroupList: observable,
      menuOptionsList: observable,
      specificMenu: observable,
      specificMenuCategories: observable,
      specificCategory: observable,
      specificItem: observable,
      specificOptionGroup: observable,
      specificOption: observable,
      itemBadges: observable,
      forceUpdateOptionCategories: observable,
      fortnightlyStatus: observable,
      entireMenus: observable,
      fortnightlyStatus: observable,
      parentHierarchy: observable,
      actionCablePayload: observable,
      selectedFilter: observable,
      sortableDragging: observable,
      upcomingOrders: observable,
      upcomingOrdersPagy: observable,
      upcomingOrderStats: observable,
      overwriteStatus: observable,
    });
  }

  async getMarkets(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.markets()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.markets = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  setActionCablePayload = payload => {
    this.actionCablePayload = payload;
    return payload;
  };

  async getZones(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.zones()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.zones = data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }
  async searchRestaurants(params) {
    this.restaurants = {};
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.restaurants = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurantDetail(restaurantId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}/${restaurantId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.restaurantDetail = data?.data;
          return data?.data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async createRestaurant(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.restaurants()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAccountingEmails(restaurantId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.accountingEmails(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.accountingEmails = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async updateAccountingEmails(restaurantId, params, emails) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.accountingEmails(restaurantId)}?${paramsString}`, {
        accounting_emails: emails,
      })
      .then(
        action('fetchSuccess', data => {
          this.accountingEmails = data?.data?.data;
          alertHandler({
            title: 'Accounting emails updated',
            body: 'Accounting emails have been updated successfully.',
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async getBankingInformation(restaurantId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.bankingInformation(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.bankingInformation = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account.',
          });
          return error;
        })
      );
  }

  async updateBankingInformation(restaurantId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.bankingInformation(restaurantId)}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.bankingInformation = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async exportSalesReport(email, restaurantId, employeeId, startDate, endDate) {
    const payload = {
      email,
      restaurant_id: restaurantId,
      employee_id: employeeId,
      start_date: startDate,
      end_date: endDate,
    };

    return client()
      .post(api.salesReport(), payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: 'Report generated',
            body: `Usage report generated from ${startDate} to ${endDate} and emailed.`,
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: error?.response?.data?.message || 'Unable to generate sales report',
            body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async getRestaurantProfile(restaurantId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurantProfile(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async updateRestaurantProfile(restaurantId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantProfile(restaurantId)}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: 'Restaurant Information Updated.',
            body: '',
          });
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(
            error.response,
            null,
            'The following orders are currently in progress and fall outside the new hours of operations. The orders need to be completed or cancelled prior to adjusting the hours of operations.'
          );
          return error;
        })
      );
  }

  async updateRestaurantAvailability(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.operatingHoursAvailable(id)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          // alertHandler({
          //   title: 'Restaurant Information Updated.',
          //   body: '',
          // });
          return data?.data?.data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSchedules(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.schedules()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.schedules = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getAllRestaurantsSchedules(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.allSchedules()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.allSchedules = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateSchedule(id, employeeId, payload) {
    return client()
      .put(`${api.schedules()}/${id}?employee_id=${employeeId}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async confirmOrSendReminder(employeeId, payload) {
    return client()
      .put(`${api.allSchedules()}?employee_id=${employeeId}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getRestaurantMenus(params, selectionType, menuId) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let _menuTypes = data?.data?.map(function (x) {
            return {
              value: x[0],
              label: x[1],
            };
          });

          this.menuTypes = _menuTypes;

          if (this.defaultMenuType === null && !menuId) {
            this.defaultMenuType = this.menuTypes[0];
          } else if (selectionType === 'update') {
            let selectedMenu = toJS(this.defaultMenuType);
            this.defaultMenuType = _menuTypes.find(obj => {
              return Number(obj.value) === Number(selectedMenu.value);
            });
          } else if (menuId) {
            this.defaultMenuType = _menuTypes.find(obj => {
              return Number(obj.value) === Number(menuId);
            });
          }

          let defaultMenu = toJS(this.defaultMenuType);
          if (defaultMenu)
            this.getMenuDetails(defaultMenu?.value, {
              employee_id: params.employee_id,
              exclude_option_groups: true,
            });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  getMenuDetails(menuId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menus()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.menu = data?.data?.data;
          this.pluckAndSetCategories();
        })
      );
  }

  resetDefaultMenu() {
    this.defaultMenuType = null;
  }

  updateDefaultMenu(selected, params) {
    this.defaultMenuType = selected;
    this.menuCategories = [];
    this.menu = null;
    if (params) return this.getMenuDetails(selected?.value, params);
  }

  pluckAndSetCategories() {
    let menu = toJS(this.menu);
    let _categories = menu?.attributes?.menu_categories.map(
      ({ attributes: { id, name, display_name, display_name_with_html } }) => ({
        id,
        name,
        display_name,
        display_name_with_html,
      })
    );

    this.menuCategories = _categories;
  }

  clearOptionCategories() {
    this.optionCategories = null;
  }

  async getOptionCategories(itemId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.optionCategories(itemId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.optionCategories = data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createMenu(selectionType, payload, employeeId, restaurantId, menuId = null) {
    return (
      selectionType === 'update'
        ? client().put(`/v1/staff/restaurant/menus/${menuId}?employee_id=${employeeId}`, payload)
        : client().post(`/v1/staff/restaurant/menus?employee_id=${employeeId}`, payload)
    ).then(
      action('fetchSuccess', data => {
        this.getRestaurantMenus(
          { employee_id: employeeId, restaurant_id: restaurantId, only_name_and_id: true },
          selectionType,
          data?.data?.data?.id
        );
        if (selectionType === 'new') {
          this.getSelectorOptions(restaurantId, {
            requested_for: 'menus',
            employee_id: employeeId,
          });
          this.getRestaurantDetail(restaurantId, { employee_id: employeeId });
        }
        return data?.data;
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async updateMenuHoursOfOperation(payload, employeeId, restaurantId, menuId = null) {
    return client()
      .put(`/v1/staff/restaurant/menus/${menuId}?employee_id=${employeeId}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getRestaurantMenus(
            { employee_id: employeeId, restaurant_id: restaurantId, only_name_and_id: true },
            'update',
            data?.data?.data?.id
          );

          return data?.data;
        }),
        action('fetchError', error => {
          alertHandler(
            error.response,
            null,
            'The following orders are currently in progress and fall outside the new hours of operations. The orders need to be completed or cancelled prior to adjusting the hours of operations.'
          );
          return error;
        })
      );
  }

  async getMenu(menuId, employeeId) {
    let params = { employee_id: employeeId };
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getTags(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.tags()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          console.log('data', data);
          this.tags = data?.data?.data;
          //  _tags.map(({ attributes: { name } }) => {
          //   return { label: name, value: name };
          // });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipments(params, timeZone) {
    let paramsString = qs.stringify(params);
    this.orderSummaries = [];
    return client()
      .get(`${api.restaurantShipments()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.orderSummaries = data.data;
          let _destinationCodes = [];
          data?.data?.data?.map(summary =>
            _destinationCodes.push({
              label: `${dateTime.formatTime(summary.cutoff, timeZone)} - ${
                summary.destination_code
              }`,
              value: summary,
              shipment_id: summary.shipment_id,
            })
          );
          this.destinationCodes = _destinationCodes;

          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getCateringShipments(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.restaurantShipments()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.cateringSummaries = data.data;

          this.hungerhubCateringSummaries = {
            data: data.data.data.filter(order => order.source_of_business == 'hungerhub'),
          };

          this.directCateringSummaries = {
            data: data.data.data.filter(order => order.source_of_business == 'restaurant'),
          };

          let _destinationCodes = [];
          data?.data?.data?.map(summary =>
            _destinationCodes.push({
              label: `${summary.destination_code} - ${
                summary.source_of_business == 'hungerhub' ? 'hungerhub Catering' : 'Direct Catering'
              }`,
              value: summary,
              shipment_id: summary.shipment_id,
            })
          );
          this.destinationCodes = this.destinationCodes.concat(_destinationCodes);

          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  setDefaultSummaryDate(date) {
    this.defaultDateSelect = date;
  }

  async getOrderDetails(shipmentId, params) {
    let paramsString = qs.stringify(params);
    //setting up axios here again because this specific request required Accept: 'application/json' in the headers. Else it would't work
    const API_URL = process.env.REACT_APP_API_URL_PROD;

    let access_token = LocalStorage.getAccessToken();
    let url = `${API_URL}${api.restaurantShipments()}/${shipmentId}?${paramsString}`;

    axios({
      url,
      method: 'GET',
      headers: {
        'ACCESS-TOKEN': access_token,
        Accept: 'application/json',
      },
    }).then(
      action('fetchSuccess', data => {
        this.ordersPrepare = data?.data;
        return data;
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  setDefaultDestinationCode(destinationCode) {
    this.defaultDestinationCode = destinationCode;
  }

  async exportXLS(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/v1/staff/restaurant/shipments/${params.shipment_id}.xlsx?employee_id=${params.employee_id}&restaurant_id=${params.restaurant_id}`;

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/xlsx' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.destination_code}.xlsx`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async exportPDF(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/v1/staff/restaurant/shipments/${params.shipment_id}.pdf?employee_id=${params.employee_id}&restaurant_id=${params.restaurant_id}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.destination_code}.pdf`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async exportLabels(params) {
    let access_token = LocalStorage.getAccessToken();
    const API_URL = process.env.REACT_APP_API_URL_PROD;
    let url = `${API_URL}/v1/staff/restaurant/shipments/${params.shipment_id}.pdf?employee_id=${params.employee_id}&restaurant_id=${params.restaurant_id}&order_labels_export=true`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'ACCESS-TOKEN': access_token,
      },
    }).then(
      action('fetchSuccess', response => {
        let newBlob = new Blob([response.data], { type: 'application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.download = `${params.restaurantName}_${params.destination_code}.pdf`;
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(url);
        }, 100);
      }),
      action('fetchError', error => {
        alertHandler(error.response);
        return error;
      })
    );
  }

  async getETA(shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.eta()}/${shipmentId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.eta = data.data;
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async getShipmentsETA(params, arr) {
    let paramsString = qs.stringify(params);

    let shipmentParams = qs.stringify(
      {
        shipment_ids: arr,
      },
      { arrayFormat: 'brackets' }
    );
    return client()
      .get(`${api.eta()}?${paramsString}&${shipmentParams}`)
      .then(
        action('fetchSuccess', data => {
          let arr = toJS(this.shipmentsETA);

          for (const property in data?.data) {
            arr = arr.filter(shipment => shipment.shipmentId !== property);
          }

          for (const property in data?.data) {
            arr = [...arr, { shipmentId: property, eta: data?.data[property] }];
          }

          this.shipmentsETA = arr;
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  clearShipmentsETA = () => {
    this.shipmentsETA = [];
  };

  async confirmShipment(restaurantId, shipmentId, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantShipments()}/${shipmentId}?${paramsString}`, {
        restaurant_id: restaurantId,
      })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async acceptDeclineCancellation(orderId, params, approved) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantOrders()}/${orderId}?${paramsString}`, { approved: approved })
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getEmployees(params, perPage = 8, pageNumber = 1) {
    let _params = params;
    _params.per_page = perPage;
    if (!params.page) _params.page = pageNumber;

    let paramsString = qs.stringify(_params);
    return client()
      .get(`${api.employees()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.employees = data?.data;
          return data;
        }),
        action('fetchError', error => {
          // alertHandler(error.response);
          return error;
        })
      );
  }

  async addEmployee(params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.employees()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateRestaurantNotifications(restaurantEmployeeId, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.employees()}/${restaurantEmployeeId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateEmployee(id, params, payload) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.employees()}/${id}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteEmployee(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .delete(`${api.employees()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.getEmployees(params);
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  //menu editor page methods
  async getSelectorOptions(restaurantId, params, updateStore = false) {
    if (updateStore)
      this.specificMenu =
        this.specificCategory =
        this.specificItem =
        this.specificOptionGroup =
        this.optionCategories =
          null;

    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurantProfile(restaurantId)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (params.requested_for === 'menus')
            this.menusList = data?.data?.data?.map(menu => {
              return { label: menu.name, value: menu.id };
            });
          else if (params.requested_for === 'menu categories') {
            this.categoriesList = [];
            let standaloneTemplates = data?.data?.data?.standalone;
            standaloneTemplates = standaloneTemplates.map(template => {
              return {
                label: template.name,
                value: template.id,
                menus: template.menus,
                isInstance: false,
              };
            });

            let instanceTemplates = data?.data?.data?.instances;
            instanceTemplates = instanceTemplates.map(instance => {
              const menus = instance.associated_menus.map(menu => menu.name);
              return {
                label: `${instance.menu_category_template_name} (${menus})`,
                value: instance.menu_category_templates_id,
                menus: instance.menus,
                isInstance: true,
              };
            });

            this.categoriesList = standaloneTemplates.concat(instanceTemplates);
          } else if (params.requested_for === 'menu items') {
            let standaloneTemplates = data?.data?.data?.standalone;
            standaloneTemplates = standaloneTemplates.map(template => {
              return {
                label: template.title,
                value: template.id,
                menus: template.menus,
                isInstance: false,
              };
            });

            let instanceTemplates = data?.data?.data?.instances;
            instanceTemplates = instanceTemplates.map(instance => {
              const menus = instance.associated_menus.map(menu => menu.name);
              return {
                label: `${instance.menu_item_template_name} (${menus})`,
                value: instance.menu_item_template_id,
                menus: instance.menus,
                isInstance: true,
              };
            });

            this.itemsList = standaloneTemplates.concat(instanceTemplates);
          } else if (params.requested_for === 'menu option categories') {
            let standaloneTemplates = data?.data?.data?.standalone;
            standaloneTemplates = standaloneTemplates.map(template => {
              return {
                label: template.name,
                value: template.id,
                menus: template.menus,
                isInstance: false,
              };
            });

            let instanceTemplates = data?.data?.data?.instances;
            instanceTemplates = instanceTemplates.map(instance => {
              const menus = instance.associated_menus.map(menu => menu.name);
              return {
                label: `${instance.option_group_template_name} (${menus})`,
                value: instance.option_group_template_id,
                menus: instance.menus,
                isInstance: true,
              };
            });

            this.optionGroupList = standaloneTemplates.concat(instanceTemplates);
          } else if (params.requested_for === 'menu options') {
            let standaloneTemplates = data?.data?.data?.standalone;
            standaloneTemplates = standaloneTemplates.map(template => {
              return {
                label: template.name,
                value: template.id,
                menus: template.menus,
                isInstance: false,
              };
            });

            let instanceTemplates = data?.data?.data?.instances;
            instanceTemplates = instanceTemplates.map(instance => {
              const menus = instance.associated_menus.map(menu => menu.name);
              return {
                label: `${instance.option_template_name} (${menus})`,
                value: instance.option_template_id,
                menus: instance.menus,
                isInstance: true,
              };
            });
            this.menuOptionsList = standaloneTemplates.concat(instanceTemplates);
          }

          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'No Information Found',
            body: 'No user associated with this account. If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async setSelectedFilter(filter) {
    this.selectedFilter = filter;
  }

  async getSpecificMenu(menuId, params) {
    this.specificMenu = null;
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menuFilters()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.specificMenu = data?.data?.data;
          let categories = data?.data?.data?.attributes?.menu_categories.map(({ attributes }) => ({
            id: attributes.id,
            name: attributes.name,
            display_name: attributes.display_name,
            display_name_with_html: attributes?.display_name_with_html,
          }));
          this.specificMenuCategories = categories;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificCategory(categoryId, params, updateStore = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${categoryId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (updateStore) this.specificCategory = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificItem(itemId, params, updateStore = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${itemId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (updateStore) this.specificItem = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificOptionGroup(optionGroupId, params, updateStore = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${optionGroupId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (updateStore) this.specificOptionGroup = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getSpecificOption(optionId, params, updateStore = false) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menuFilters()}/${optionId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          if (updateStore) this.specificOption = data?.data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  clearSpecificCategory() {
    this.specificCategory = null;
  }

  clearSpecificItem() {
    this.specificItem = null;
  }

  clearSpecificOptionGroup() {
    this.specificOptionGroup = null;
  }

  clearSpecificOption() {
    this.specificOption = null;
  }

  clearSpecificMenu() {
    this.specificMenu = null;
    this.specificMenuCategories = [];
  }

  clearMenu() {
    this.menu = null;
    this.menuCategories = [];
  }

  updateCategory(categoryId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.menuCategories()}/${categoryId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.forceUpdateOptionCategories = true; //to refresh the items within the category
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createCategory(payload, params, standaloneTemplate = false) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.menuCategories()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          if (this.specificMenu && !standaloneTemplate) {
            let specificMenu = toJS(this.specificMenu);

            this.specificMenu = {
              ...specificMenu,
              attributes: {
                ...specificMenu.attributes,
                menu_categories: [...specificMenu?.attributes?.menu_categories, data?.data?.data],
              },
            };

            const updatedMenu = toJS(this.specificMenu);

            let categories = updatedMenu?.attributes?.menu_categories.map(({ attributes }) => ({
              id: attributes.id,
              name: attributes.name,
              display_name: attributes.display_name,
              display_name_with_html: attributes.display_name_with_html,
            }));
            this.specificMenuCategories = categories;
          }
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  updatePositions(restaurantId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.menus()}/${restaurantId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  getItemBadges(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.badges()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.itemBadges = data?.data?.data?.map(badge => {
            return { label: badge, value: { name: badge } };
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  updateItem(itemId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.menuItems()}/${itemId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createItem(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.menuItems()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          if (this.specificMenu) {
            let categoryId = payload?.menu_category_ids[0];
            //to locally push the new item into the menu
            let specificMenu = toJS(this.specificMenu);
            this.specificMenu = {
              ...specificMenu,
              attributes: {
                ...specificMenu.attributes,
                menu_categories: specificMenu.attributes.menu_categories?.map(category => {
                  if (parseInt(category.id) === categoryId) {
                    category.attributes.menu_items.push(data?.data?.data);
                  }
                  return category;
                }),
              },
            };
          }

          if (this.specificCategory) {
            // to locally push the new item to the category
            let specificCategory = toJS(this.specificCategory);
            let updatedCategory = {
              ...specificCategory,
              attributes: {
                ...specificCategory.attributes,
                menu_items: [...specificCategory.attributes.menu_items, data?.data?.data].flat(),
              },
            };
            this.specificCategory = updatedCategory;
          }
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  updateOptionGroup(optionGroupId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.optionGroups()}/${optionGroupId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          this.forceUpdateOptionCategories = true;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createOptionGroup(payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.optionGroups()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          let optionCategories = toJS(this.optionCategories);
          if (optionCategories)
            this.optionCategories = {
              ...optionCategories,
              data: {
                data: {
                  ...optionCategories.data.data,
                  attributes: {
                    ...optionCategories.data.data.attributes,
                    option_groups: [
                      ...optionCategories.data.data.attributes.option_groups,
                      data?.data?.data,
                    ],
                  },
                },
              },
            };
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  updateOption(optionId, payload, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.options()}/${optionId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  createOption(payload, params) {
    this.forceUpdateOptionCategories = false;
    let optionCategoryId =
      array.isPresent(payload?.option_group_ids) && payload?.option_group_ids[0];
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.options()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          let optionCategories = toJS(this.optionCategories);
          let specificOptionGroup = toJS(this.specificOptionGroup);

          if (optionCategories) {
            this.optionCategories = {
              ...optionCategories,
              data: {
                data: {
                  ...optionCategories.data.data,
                  attributes: {
                    ...optionCategories.data.data.attributes,
                    option_groups: optionCategories.data.data.attributes.option_groups?.map(
                      optionCategory => {
                        if (
                          array.isPresent(payload?.option_group_ids) &&
                          parseInt(optionCategory.id) === optionCategoryId
                        ) {
                          optionCategory.attributes.options.push(data?.data?.data);
                        }
                        return optionCategory;
                      }
                    ),
                  },
                },
              },
            };
          }
          if (specificOptionGroup) {
            this.specificOptionGroup = {
              ...specificOptionGroup,
              attributes: {
                ...specificOptionGroup.attributes,
                options: [...specificOptionGroup.attributes.options, data?.data?.data].flat(),
              },
            };
          }

          this.forceUpdateOptionCategories = true;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createPresignedUrl(file, byte_size, checksum, params) {
    let payload = {
      file: {
        filename: file.name,
        byte_size: byte_size,
        checksum: checksum,
        content_type: 'image/webp',
        metadata: {
          message: 'item image',
        },
      },
    };

    let paramsString = qs.stringify(params);
    return client()
      .post(`${api.preSignedUrl()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getEntireMenus(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.menus()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          let helper = data?.data?.data?.map(menu => {
            return {
              value: menu?.id,
              label: menu?.attributes?.name,
              restaurant: menu?.attributes?.restaurant_name,
            };
          });
          this.entireMenus = helper;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getReportsHistory(params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.reports()}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.reportHistory = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async getFornightlyStatus(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.restaurants()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.fortnightlyStatus = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async updateFortnightlyStatus(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .put(`${api.restaurantProfile(id)}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async createNewReport(emails, restaurantId, employeeId, startDate, endDate, paymentDate) {
    const payload = {
      emails,
      restaurant_id: restaurantId,
      employee_id: employeeId,
      start_date: startDate,
      end_date: endDate,
      payment_date: paymentDate,
    };

    return client()
      .post(api.reports(), payload)
      .then(
        action('fetchSuccess', data => {
          alertHandler({
            title: data?.data?.message,
          });
          return data;
        }),
        action('fetchError', error => {
          alertHandler({
            title: 'Unable to generate sales report',
            body: 'If you need assistance please start a live chat or email us at help@hungerhub.com.',
          });
          return error;
        })
      );
  }

  async getParentHierarchy(id, params) {
    let paramsString = qs.stringify(params);
    return client()
      .get(`${api.associatedMenus()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.parentHierarchy = data?.data;
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  async deleteMenu(id, employeeId, restaurantId, skipShipmentValidation) {
    const params = { employee_id: employeeId, skip_shipment_validation: skipShipmentValidation };
    const paramsString = qs.stringify(params);

    return client()
      .delete(`${api.menus()}/${id}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          this.resetDefaultMenu();
          this.getRestaurantDetail(restaurantId, { employee_id: employeeId });
          this.getRestaurantMenus({
            employee_id: employeeId,
            restaurant_id: restaurantId,
            only_name_and_id: true,
          });
          return data;
        }),

        action('fetchError', error => {
          if (error.response?.status === 422) {
            let upcomingOrders = error.response?.data?.errors?.upcoming_orders;
            let unconfirmedShipments = error.response?.data?.errors?.upcoming_shipments;

            if (array.isPresent(unconfirmedShipments)) return unconfirmedShipments;
            else if (array.isPresent(upcomingOrders)) {
              alertHandler({
                title: 'Unable to remove the menu as there are upcoming orders.',
                body: upcomingOrders?.flat()?.map((order, index) => {
                  return (
                    <small
                      className={`input-label text-xs ${
                        index % 2 === 0 ? 'text-light-purple' : 'text-black'
                      }`}>
                      {`${order?.client_name} - Date: ${order.shipment_date}\n`}{' '}
                    </small>
                  );
                }),
              });
            }
          } else alertHandler(error.response);
          return error;
        })
      );
  }

  setParentTemplateInfo(info) {
    this.parentTemplateInfo = info;
  }

  setSortableDragging(value) {
    this.sortableDragging = value;
  }

  async getUpcomingOrders(params, orderFilter) {
    let newParams = params;
    if (orderFilter?.value === 'uncatering') {
      params.shipment_service_eq = 1;
    } else if (orderFilter?.value === 'direct catering') {
      params.shipment_service_eq = 2;
      params.shipment_source_of_business_eq = 2;
    } else if (orderFilter?.value === 'hungerhub catering') {
      params.shipment_service_eq = 2;
      params.shipment_source_of_business_eq = 1;
    }

    let paramsString = qs.stringify(newParams);
    return client()
      .get(`${api.menuOverwriteOrders()}?${paramsString}`)
      .then(
        action('fetchSuccess', response => {
          const { data, pagy, orders_stats } = response?.data;

          this.upcomingOrders = [...this.upcomingOrders, data].flat();
          this.upcomingOrdersPagy = pagy;
          this.upcomingOrderStats = orders_stats;

          return response;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  resetUpcomingOrders() {
    this.upcomingOrders = [];
    this.upcomingOrdersPagy = null;
  }

  async cancelOrder(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.menuOverwriteOrders()}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  cancelOrKeepInStore(orderId, status) {
    const updatedOrders = this.upcomingOrders.map(order => {
      if (order.id === orderId) {
        return {
          ...order,
          locallyCancelledOrKept: status,
        };
      }
      return order;
    });

    this.upcomingOrders = updatedOrders;
  }

  keepOrCancelAllOrders(status) {
    const updatedOrders = this.upcomingOrders.map(order => {
      if (order?.attributes?.status === 'pending')
        return {
          ...order,
          locallyCancelledOrKept: status,
        };

      return order;
    });

    this.upcomingOrders = updatedOrders;
  }

  overwriteMenu(targetMenuId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.menus()}/${targetMenuId}?${paramsString}`, payload)
      .then(
        action('fetchSuccess', data => {
          return data;
        }),
        action('fetchError', error => {
          alertHandler(error.response);
          return error;
        })
      );
  }

  getMenuNameOnly(menuId, params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.menus()}/${menuId}?${paramsString}`)
      .then(
        action('fetchSuccess', data => {
          return data?.data?.data;
        })
      );
  }

  getOverwriteMenuStatus(menuId, params) {
    let status = toJS(this.overwriteStatus);

    if (status?.overwrite) return;
    else {
      let paramsString = qs.stringify(params);

      return client()
        .get(`${api.menus()}/${menuId}?${paramsString}`)
        .then(
          action('fetchSuccess', data => {
            this.overwriteStatus = data?.data;
            return data?.data;
          })
        );
    }
  }

  resetOverwriteStates() {
    this.overwriteStatus = null;
    this.actionCablePayload = null;
  }

  updateMenuEditorLocally(type, response, parentId) {
    if (this.specificItem) {
      if (type === 'option_group') {
        this.specificItem?.attributes?.option_groups.push(response);

        const specificItemCopy = toJS(this.specificItem);
        this.specificItem = {
          ...specificItemCopy,
        };

        this.forceUpdateOptionCategories = true; //to refresh the items within the category
      } else if (type === 'option') {
        const optionGroup = this.specificItem?.attributes?.option_groups.find(
          group => Number(group.id) === Number(parentId)
        );

        if (optionGroup) {
          optionGroup.attributes.options.push(response);

          const specificMenuCopy = toJS(this.specificMenu);

          this.specificMenu = {
            ...specificMenuCopy,
          };

          this.forceUpdateOptionCategories = true; //to refresh the items within the category
        }
      }
    } else if (this.specificCategory) {
      if (type === 'option_group') {
        const menuItem = this.specificCategory?.attributes?.menu_items.find(
          item => Number(item.id) === Number(parentId)
        );

        if (menuItem) {
          menuItem?.attributes?.option_groups.push(response);

          const specificMenuCopy = toJS(this.specificMenu);

          this.specificMenu = {
            ...specificMenuCopy,
            menu_categories: specificMenuCopy?.attributes?.menu_categories.map(category => {
              return {
                ...category,
                menu_items: category?.attributes?.menu_items.map(item => {
                  if (Number(item.id) === Number(parentId)) {
                    return menuItem;
                  }
                  return item;
                }),
              };
            }),
          };

          this.forceUpdateOptionCategories = true; //to refresh the items within the category
        }
      } else if (type === 'option') {
        const optionGroup = this.specificCategory?.attributes?.menu_items
          .flatMap(item => item.attributes.option_groups)
          .find(group => Number(group.id) === Number(parentId));

        if (optionGroup) {
          optionGroup.attributes.options.push(response);

          const specificMenuCopy = toJS(this.specificMenu);

          this.specificMenu = {
            ...specificMenuCopy,
          };

          this.forceUpdateOptionCategories = true; //to refresh the items within the category
        }
      }
    } else if (this.specificMenu) {
      if (type === 'option_group') {
        const menuItem = this.specificMenu?.attributes?.menu_categories
          .flatMap(category => category?.attributes?.menu_items)
          .find(item => Number(item.id) === Number(parentId));

        if (menuItem) {
          menuItem?.attributes?.option_groups.push(response);

          const specificMenuCopy = toJS(this.specificMenu);

          this.specificMenu = {
            ...specificMenuCopy,
            menu_categories: specificMenuCopy?.attributes?.menu_categories.map(category => {
              return {
                ...category,
                menu_items: category?.attributes?.menu_items.map(item => {
                  if (Number(item.id) === Number(parentId)) {
                    return menuItem;
                  }
                  return item;
                }),
              };
            }),
          };

          this.forceUpdateOptionCategories = true; //to refresh the items within the category
        }
      } else if (type === 'option') {
        const optionGroup = this.specificMenu.attributes.menu_categories
          .flatMap(category => category.attributes.menu_items)
          .flatMap(item => item.attributes.option_groups)
          .find(group => Number(group.id) === Number(parentId));

        if (optionGroup) {
          optionGroup.attributes.options.push(response);

          const specificMenuCopy = toJS(this.specificMenu);

          this.specificMenu = {
            ...specificMenuCopy,
          };

          this.forceUpdateOptionCategories = true; //to refresh the items within the category
        }
      }
    }
  }
}

export default RestaurantManagementStore;
