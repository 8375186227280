import React, { useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { StoreContext } from 'index';
import sideBarOptions from 'config/sideBarOptions';
import Screen from 'utils/helpers/ScreenSize';

function SideBar({ toggleSidebar }) {
  const store = useContext(StoreContext);
  const location = useLocation();
  const userStore = toJS(store.userStore);
  const { defaultRegion, expandScreen } = userStore;
  const screenType = Screen.ScreenType();

  return (
    <>
      <div className="py-10 md:flex">
        <nav className="overflow-y-scroll">
          {sideBarOptions[defaultRegion?.role]?.routes?.map(
            ({ label, icon, url, childRoutes, disabled }, index) => (
              <NavLink
                onClick={() => {
                  screenType.isTabletOrMobile && toggleSidebar(false);
                }}
                key={index}
                to={disabled ? '/' : url}
                activeStyle={{ color: '#6D69D3' }}
                isActive={() => childRoutes?.some(sub => location?.pathname?.includes(sub))}
                className={`py-4 px-3 rounded transition duration-200 hover:bg-table-row hover:text-black d-row items-center no-underline ${
                  disabled && 'opacity-50 cursor-not-allowed'
                }`}>
                <div className="w-6">
                  <FontAwesomeIcon icon={icon} size="1x" />
                </div>

                {!expandScreen && (
                  <small className="pl-1 lg:pl-3 font-inter-regular text-xxs lg:text-xs">
                    {label}
                  </small>
                )}
              </NavLink>
            )
          )}
        </nav>
      </div>
    </>
  );
}

export default observer(SideBar);
