import UserStore from './user';
import RestaurantManagementStore from './restaurantManagement';
import MealManagementStore from './mealManagement';
import StaffManagementStore from './staffManagement';
import UserManagementStore from './userManagement';
import AccountManagementStore from './accountManagement';
import PlaceOrderStore from './placeOrder';
import MarketManagementStore from './marketManagement';
import DeliveryManagementStore from './deliveryManagement';
import CateringOrdersStore from './cateringOrders';
import RestaurantBatchPaymentStore from './restaurantBatchPayments';
import ClientRankStore from './clientRanks';
import { create } from 'mobx-persist';

const hydrate = create({
  storage: localStorage,
  jsonify: true,
});

class RootStore {
  UserStore = new UserStore(this);
  RestaurantManagementStore = new RestaurantManagementStore(this);
  MealManagementStore = new MealManagementStore(this);
  StaffManagementStore = new StaffManagementStore(this);
  UserManagementStore = new UserManagementStore(this);
  AccountManagementStore = new AccountManagementStore(this);
  PlaceOrderStore = new PlaceOrderStore(this);
  MarketManagementStore = new MarketManagementStore(this);
  DeliveryManagementStore = new DeliveryManagementStore(this);
  CateringOrdersStore = new CateringOrdersStore(this);
  RestaurantBatchPaymentStore = new RestaurantBatchPaymentStore(this);
  ClientRankStore = new ClientRankStore(this);

  constructor() {
    // hydrate('Store', this.UserStore);
    // hydrate('Store', this.RestaurantManagementStore);
  }
}

export default RootStore;
