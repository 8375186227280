import React, { useReducer, useContext, useEffect, createRef } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toJS } from 'mobx';
import { Dropdown, ModalBackground } from 'components/Shared';
import classnames from 'classnames';

const roles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Manager', value: 'manager' },
  { label: 'Employee', value: 'employee' },
];

function CreateNewTag({ visible, closeModal, bankDetails }) {
  const store = useContext(StoreContext);
  const userStore = toJS(store.userStore);
  const uncateringBadgeRef = createRef();
  const cateringBadgeRef = createRef();
  const itemBadgeRef = createRef();

  const initialState = {
    transitNumber: '',
    institutionNumber: '',
    accountNumber: '',
  };
  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  const submit = e => {
    e.preventDefault();
    let payload = {
      transit_number: provider.transitNumber,
      account_number: provider.accountNumber,
      institution_number: provider.institutionNumber,
    };

    store.userStore.updateBankingInformation(payload).then(response => {
      if (response?.data?.data) closeModal();
    });
  };

  useEffect(() => {
    dispatch({ field: 'transitNumber', value: bankDetails?.attributes?.transit_number });
    dispatch({
      field: 'institutionNumber',
      value: bankDetails?.attributes?.institution_number,
    });
    dispatch({ field: 'accountNumber', value: bankDetails?.attributes?.account_number });
  }, [bankDetails]);

  const triggerCateringBadge = () => cateringBadgeRef.current.click();
  const triggerUncateringBadge = () => uncateringBadgeRef.current.click();
  const triggerItemRef = () => itemBadgeRef.current.click();

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg bg-white overflow-y-scroll md:overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>
          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="w-full">
              <small className="input-label">Tag Name</small>
              <input
                autoComplete="off"
                className="input-light"
                name="transitNumber"
                id="transitNumber"
                type="number"
                value={provider.transitNumber}
                placeholder="Please add transit number"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="w-full">
              <small className="input-label">Display Name Uncatering</small>
              <input
                autoComplete="off"
                className="input-light"
                name="institutionNumber"
                id="institutionNumber"
                value={provider.institutionNumber}
                placeholder="Please add institution number"
                type="number"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="w-full">
              <small className="input-label">Display Name Item</small>
              <input
                autoComplete="off"
                className="input-light"
                placeholder="Please add account number"
                name="accountNumber"
                id="accountNumber"
                value={provider.accountNumber}
                type="number"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>
            <div className="w-full">
              <small className="input-label">Display Name Catering</small>
              <input
                autoComplete="off"
                className="input-light"
                placeholder="Please add account number"
                name="accountNumber"
                id="accountNumber"
                value={provider.accountNumber}
                type="number"
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="d-col">
              <small className="input-label">Select filter</small>
              <Dropdown label="Filter" />
            </div>

            <div className="mt-4 d-row justify-center">
              <input
                autoComplete="off"
                type="file"
                ref={cateringBadgeRef}
                className="text-xs"
                id="img"
                name="img"
                hidden
                accept="image/*"
              />
              <button onClick={triggerCateringBadge} className="btn-outline text-xs" type="button">
                Catering Badge
              </button>

              <input
                autoComplete="off"
                type="file"
                ref={uncateringBadgeRef}
                className="text-xs"
                id="img"
                name="img"
                hidden
                accept="image/*"
              />
              <button
                onClick={triggerUncateringBadge}
                className="btn-outline ml-2 text-xs"
                type="button">
                Uncatering Badge
              </button>

              <input
                autoComplete="off"
                type="file"
                ref={itemBadgeRef}
                className="text-xs"
                id="img"
                name="img"
                hidden
                accept="image/*"
              />
              <button onClick={triggerItemRef} className="btn-outline ml-2 text-xs" type="button">
                Item Badge
              </button>
            </div>

            <div className="items-center flex flex-col pt-2 md:pt-24">
              <button className="btn-purple w-full text-sm" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateNewTag;
