import React, { useReducer, useContext, useEffect, createRef } from 'react';
import { StoreContext } from 'index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, ModalBackground, ImageEdit } from 'components/Shared';
import { alertHandler } from 'utils/middlewares/alertHandler';
import useEmployeeId from 'utils/helpers/user';
import ProgressiveImage from 'react-progressive-image';
import { Placeholder } from 'assets/img';
import Image from 'utils/helpers/image';
import classnames from 'classnames';

function AddZone({ visible, closeModal, zoneMarket, editZone, getZones, marketLabels }) {
  const modalRef = createRef();
  const store = useContext(StoreContext);

  let { employeeId } = useEmployeeId();

  const initialState = {
    name: '',
    market: null,
    odcShortDes: '',
    odcLongDes: '',
    displayOnODC: false,
    imageBlobId: null,
    image: null,
    smallImageBlobId: null,
    smallImage: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'reset':
        return action.payload;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }
  const [provider, dispatch] = useReducer(reducer, initialState);

  const submit = e => {
    e.preventDefault();

    if (!provider.market) {
      alertHandler({ title: 'Select market' });
      return;
    }

    let payload = {
      zone: {
        name: provider.name,
        market_id: provider.market.value,
        display_on_odc: provider.displayOnODC,
        description_odc_long: provider.odcLongDes,
        description_odc_short: provider.odcShortDes,
      },
    };

    if (provider.imageBlobId) payload.image = provider.imageBlobId;

    if (provider.smallImageBlobId) payload.small_image = provider.smallImageBlobId;

    store.userStore.setLoader(true);

    if (editZone !== null) {
      store.marketManagementStore
        .updateZone(editZone.id, { employee_id: employeeId }, payload)
        .then(response => {
          store.userStore.setLoader(false);

          if (response?.attributes) {
            getZones();
            closeModal();
          }
        });
    } else
      store.marketManagementStore
        .createZone({ employee_id: employeeId }, payload)
        .then(response => {
          store.userStore.setLoader(false);

          if (response?.attributes) {
            getZones();
            alertHandler({ title: `Successfully created ${response?.attributes?.name} as Zone.` });
            closeModal();
          }
        });
  };

  useEffect(() => {
    dispatch({ type: 'reset', payload: initialState });

    if (zoneMarket) {
      dispatch({
        field: 'market',
        value: zoneMarket,
      });
    }

    if (editZone !== null) {
      dispatch({ field: 'name', value: editZone?.attributes?.name });
      dispatch({
        field: 'displayOnODC',
        value: editZone?.attributes?.display_on_odc,
      });
      dispatch({ field: 'odcLongDes', value: editZone?.attributes?.description_odc_long });
      dispatch({ field: 'odcShortDes', value: editZone?.attributes?.description_odc_short });
      dispatch({
        field: 'market',
        value: {
          label: marketLabels.filter(mar => mar.value == editZone?.attributes?.market_id)[0].label,
          value: editZone?.attributes?.market_id,
        },
      });

      dispatch({
        field: 'image',
        value: {
          image: editZone?.attributes?.image_url,
        },
      });

      dispatch({
        field: 'smallImage',
        value: {
          image: editZone?.attributes?.small_image_url,
        },
      });
    }
  }, [visible, editZone]);

  return (
    <div
      className={classnames({
        'modal-visible': visible,
        hidden: !visible,
      })}>
      <ModalBackground />

      <div
        ref={modalRef}
        className="rounded-lg bg-white overflow-y-scroll shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px', maxHeight: '95%' }}
        aria-labelledby="modal-headline">
        <div className="flex p-6 flex-col">
          <button
            onClick={() => {
              closeModal();
            }}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <form className="rounded pt-4 w-full" onSubmit={e => submit(e)}>
            <div className="w-full">
              <small className="input-label">Zone Name</small>
              <input
                autoComplete="off"
                className="input-light"
                placeholder="Zone name"
                name="name"
                id="name"
                value={provider.name}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
                required
              />
            </div>

            <div className="w-full">
              <small className="input-label">Market</small>
              <Dropdown
                label="Select"
                selected={provider.market}
                options={marketLabels}
                onChange={selected => dispatch({ field: 'market', value: selected })}
              />
            </div>

            <div className="">
              <small className="input-label">ODC short description</small>

              <input
                autoComplete="off"
                className="input-light"
                name="odcShortDes"
                id="odcShortDes"
                type="text"
                value={provider.odcShortDes}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
              />

              <small className="input-label">ODC long description</small>

              <input
                autoComplete="off"
                className="input-light"
                name="odcLongDes"
                id="odcLongDes"
                type="text"
                value={provider.odcLongDes}
                onChange={e => dispatch({ field: e.target.name, value: e.target.value })}
              />

              <div className="d-row justify-between mt-3">
                <div className="w-full">
                  <ProgressiveImage src={provider.image?.image} placeholder={Placeholder}>
                    {src => (
                      <img
                        src={src}
                        className="h-24 w-full object-contain rounded-xl my-1 shadow-md border"
                        alt="an image"
                      />
                    )}
                  </ProgressiveImage>

                  <ImageEdit
                    btnLabel="ODC Banner Image"
                    aspectRatio={1900 / 800}
                    afterEditDone={(image, imageFile) => {
                      dispatch({
                        field: 'image',
                        value: { image },
                      });
                      store.userStore.setLoader(true);
                      Image.fileChecksum(imageFile).then(checksum => {
                        store.restaurantManagementStore
                          .createPresignedUrl(imageFile, imageFile.size, checksum, {
                            employee_id: employeeId,
                          })
                          .then(async response => {
                            let presignedFileParams = response.data;
                            const s3PutOptions = {
                              method: 'PUT',
                              headers: presignedFileParams?.headers,
                              body: imageFile,
                            };
                            fetch(presignedFileParams?.url, s3PutOptions)
                              .then(response => {
                                store.userStore.setLoader(false);
                                if (response.status === 200)
                                  dispatch({
                                    field: 'imageBlobId',
                                    value: presignedFileParams?.blob_signed_id,
                                  });
                                else
                                  alertHandler({
                                    title: 'Please try again',
                                    body: 'Try uploading the image again.',
                                  });
                                return response.json();
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          });
                      });
                    }}
                  />

                  <small className="input-label self-start mt-1">Select 1900 X 800 image</small>
                </div>
              </div>

              <div className="d-row justify-between mt-3">
                <div className="w-full">
                  <ProgressiveImage src={provider.smallImage?.image} placeholder={Placeholder}>
                    {src => (
                      <img
                        src={src}
                        className="h-24 w-full object-contain rounded-xl my-1 shadow-md border"
                        alt="an image"
                      />
                    )}
                  </ProgressiveImage>
                  <ImageEdit
                    btnLabel="ODC Card Image"
                    aspectRatio={250 / 250}
                    afterEditDone={(image, imageFile) => {
                      dispatch({
                        field: 'smallImage',
                        value: { image },
                      });
                      store.userStore.setLoader(true);
                      Image.fileChecksum(imageFile).then(checksum => {
                        store.restaurantManagementStore
                          .createPresignedUrl(imageFile, imageFile.size, checksum, {
                            employee_id: employeeId,
                          })
                          .then(async response => {
                            let presignedFileParams = response.data;
                            const s3PutOptions = {
                              method: 'PUT',
                              headers: presignedFileParams?.headers,
                              body: imageFile,
                            };
                            fetch(presignedFileParams?.url, s3PutOptions)
                              .then(response => {
                                store.userStore.setLoader(false);
                                if (response.status === 200)
                                  dispatch({
                                    field: 'smallImageBlobId',
                                    value: presignedFileParams?.blob_signed_id,
                                  });
                                else
                                  alertHandler({
                                    title: 'Please try again',
                                    body: 'Try uploading the image again.',
                                  });
                                return response.json();
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          });
                      });
                    }}
                  />

                  <small className="input-label self-start mt-1">Select 250 X 250 image</small>
                </div>
              </div>

              <div className="d-row mt-5">
                <label className="inline-flex items-center">
                  <input
                    autoComplete="off"
                    type="checkbox"
                    className="form-checkbox"
                    onClick={e => dispatch({ field: 'displayOnODC', value: e.target.checked })}
                    checked={provider.displayOnODC}
                  />
                  <span className="ml-1 text-xs font-inter-regular">Display on ODC</span>
                </label>
              </div>
            </div>

            <div className="items-center flex flex-col pt-2 md:pt-24">
              <button className="btn-purple w-full text-sm" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddZone;
