import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useQueryMethods = () => {
  let allParams = useLocation().search;
  let path = useLocation().pathname;
  let query = useQuery();
  const { id } = useParams(); //office user id

  const getParam = useCallback(key => {
    return query.get(key);
  });

  const showPageId = useCallback(() => {
    return id;
  });

  const allSearchParams = () => {
    return allParams;
  };

  const currentPath = () => {
    return path;
  };

  return { getParam, showPageId, allSearchParams, currentPath };
};
