import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faUtensils, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SpecialInstruction } from 'assets/img';
import Validation from 'utils/helpers/validation';
import Screen from 'utils/helpers/ScreenSize';
import { ModalBackground, EmbedHTML } from 'components/Shared';
import dateTime from 'utils/helpers/dateTime';
import { StoreContext } from 'index';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

function BagDetail({ orderData, closeModal, hideAllTotals }) {
  const screenType = Screen.ScreenType();
  const store = useContext(StoreContext);
  const restaurantManagementStore = toJS(store.restaurantManagementStore);
  const { shipmentsETA, restaurantDetail } = restaurantManagementStore;
  const timeZone = restaurantDetail?.data?.attributes?.tzinfo;

  return (
    <div
      className={classnames({
        'modal-visible': orderData,
        hidden: !orderData,
      })}>
      <ModalBackground />

      <div
        className="rounded-lg max-h-screen overflow-hidden shadow-md transform transition-all sm:max-w-lg sm:w-full animate-fade-in-down"
        role="dialog"
        aria-modal="true"
        style={{ width: '1290px' }}
        aria-labelledby="modal-headline">
        <div className="flex bg-white p-6 flex-col">
          <button onClick={closeModal}>
            <FontAwesomeIcon
              icon={faTimes}
              color={'#ADB4C3'}
              size="lg"
              className="absolute top-4 right-4"
            />
          </button>

          <div className="flex flex-row justify-between items-center pr-5">
            <div className="flex flex-col">
              {orderData?.status === 'cancelled' ? (
                <small className="text-red-500 font-inter-regular">Bag removed</small>
              ) : (
                <h5 className="font-inter-regular">Bag {orderData?.bag_number}</h5>
              )}
              <small className="text-sm font-inter-regular">{orderData?.user_name}</small>
            </div>

            <div className="flex flex-col">
              <small className="font-inter-regular">Ordered at : </small>
              <span className="font-inter-regular text-sm">
                {dateTime.formatDateWithTimezone(orderData?.ordered_at, 'YYYY-MM-DD', timeZone)},
                {'  '}
                <small className="text-red-500 text-sm">
                  *{dateTime.formatTime(orderData?.ordered_at, timeZone)}
                </small>
              </span>
            </div>
          </div>

          <table className="w-full table-fixed">
            <thead className="bg-white">
              <tr>
                <th scope="col" className="lg:pr-6 pt-3 w-1/6 text-left tracking-wider">
                  <small className="text-xs text-black tracking-wider font-inter-regular font-normal">
                    Qty
                  </small>
                </th>

                <th scope="col" className="px-1.5 lg:px-6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">Item</small>
                </th>

                <th scope="col" className="px-1.5 w-1/6 pt-3 text-left tracking-wider">
                  <small className="text-xs text-black font-inter-regular font-normal">Price</small>
                </th>
              </tr>
            </thead>

            <tbody className="bg-white">
              {orderData?.order_items?.map(({ id, attributes }) => (
                <tr key={id} className="relative cursor-pointer">
                  <td className="lg:pr-6 py-3 whitespace-nowrap align-top">
                    <small className="text-xs font-inter-regular text-dark-gray">
                      {attributes?.quantity}
                    </small>
                  </td>

                  <td className="px-1.5 lg:px-6 py-3 align-top" colSpan="2">
                    <table className="w-full table-fixed">
                      <tbody>
                        <tr>
                          <td colSpan={screenType.isMobile ? '5' : '7'}>
                            <EmbedHTML
                              text={attributes.menu_item?.display_name_with_html}
                              color="text-black text-xs break-normal"
                            />

                            <EmbedHTML
                              text={attributes.category_display_name_with_html}
                              color="text-black text-xs break-normal"
                            />

                            <small className="input-label capitalize text-yellow-600">
                              {orderData?.permanent_instructions}
                            </small>

                            <span className="text-sm text-black font-inter-regular flex flex-row items-center">
                              {attributes?.special_instructions && (
                                <img
                                  src={SpecialInstruction}
                                  className="object-contain h-7 w-7 pr-2"
                                  alt=""
                                />
                              )}

                              <p className="break-normal text-xs text-pink-500 font-inter-regular">
                                {attributes?.special_instructions}
                              </p>
                            </span>
                          </td>

                          <td className="flex items-start">
                            <small className="text-xs md:text-xs text-black font-inter-regular">
                              {Validation.priceFormatter(attributes?.total_price)}
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="w-full table-fixed">
                      <tbody>
                        {attributes?.order_item_options?.map(({ attributes }) => (
                          <tr>
                            <td colSpan={screenType.isMobile ? '5' : '7'}>
                              <div className="d-col">
                                <EmbedHTML
                                  text={attributes?.option_group_display_name_with_html}
                                  color="text-xs text-dark-blue break-normal pt-2 mr-3"
                                />

                                <EmbedHTML
                                  text={attributes?.option_display_name_with_html}
                                  color="text-xs text-black break-normal"
                                />
                              </div>
                            </td>

                            <td>
                              <small className="font-inter-regular text-xs flex text-dark-gray flex-col">
                                {Validation.priceFormatter(attributes?.price)}
                              </small>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}

              <tr key={2} className="relative cursor-pointer border-t border-gray-200">
                <td className="lg:pr-6 py-3 whitespace-nowrap align-top"></td>

                <td className="px-1.5 lg:px-6 py-3 align-top whitespace-nowrap flex flex-col">
                  <div className="flex flex-col">
                    <small className="font-inter-regular text-xs pt-2">Food Total :</small>

                    <small className="font-inter-regular text-xs pt-2">Tax :</small>
                    {!hideAllTotals && (
                      <>
                        <small className="font-inter-regular text-xs pt-2">Tip :</small>
                        <small className="font-inter-regular text-xs pt-2">Service Fee :</small>
                        <small className="font-inter-regular text-xs pt-2">Delivery Fee :</small>
                        <small className="font-inter-regular text-xs pt-2">Paid By User :</small>
                        <small className="font-inter-regular text-xs pt-2">
                          Subsidized By Office :
                        </small>
                        {orderData?.discounted_amount > 0 && (
                          <small className="font-inter-regular text-xs pt-2">Discount :</small>
                        )}
                      </>
                    )}

                    <small className="font-inter-regular text-sm pt-2">Grand Total :</small>
                  </div>
                </td>

                <td className="px-1.5 py-3 whitespace-nowrap align-top">
                  <div className="flex flex-col">
                    <small className="font-inter-regular text-xs pt-2">
                      {Validation.priceFormatter(orderData?.subtotal)}
                    </small>

                    <small className="font-inter-regular text-xs pt-2">
                      {Validation.priceFormatter(
                        !hideAllTotals
                          ? parseFloat(orderData?.hst) +
                              parseFloat(orderData?.tax_on_delivery_and_service_fee)
                          : parseFloat(orderData?.hst)
                      )}
                    </small>

                    {!hideAllTotals && (
                      <>
                        {' '}
                        <small className="font-inter-regular text-xs pt-2">
                          {Validation.priceFormatter(orderData?.tip)}
                        </small>
                        <small className="font-inter-regular text-xs pt-2">
                          {Validation.priceFormatter(orderData?.service_fee)}
                        </small>
                        <small className="font-inter-regular text-xs pt-2">
                          {Validation.priceFormatter(orderData?.delivery_fee)}
                        </small>
                        <small className="font-inter-regular text-xs pt-2">
                          {Validation.priceFormatter(orderData?.amount_paid_by_employee)}
                        </small>
                        <small className="font-inter-regular text-xs pt-2">
                          {Validation.priceFormatter(orderData?.amount_paid_by_employer)}
                        </small>
                        {orderData?.discounted_amount > 0 && (
                          <small className="font-inter-regular text-xs pt-2">
                            {Validation.priceFormatter(orderData?.discounted_amount)}
                          </small>
                        )}
                      </>
                    )}

                    <small className="font-inter-regular text-sm pt-2">
                      {Validation.priceFormatter(
                        !hideAllTotals
                          ? orderData?.grand_total
                          : orderData?.grand_total_for_restaurant
                      )}
                    </small>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {orderData?.permanent_instructions && (
            <div className="bg-light-gray py-2 mt-3 px-3 w-full rounded-lg">
              <FontAwesomeIcon icon={faExclamationTriangle} color={'#FF9966'} size="sm" />
              <small className="text-dark-gray text-xs font-inter-regular pl-3">
                {orderData?.permanent_instructions}
              </small>
            </div>
          )}

          {orderData?.cutlery && (
            <div className="flex justify-end my-2 mt-5">
              <FontAwesomeIcon icon={faUtensils} color={'#6D69D3'} size="sm" />
              <small className="text-dark-gray font-inter-regular pl-3">Cutlery required</small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(BagDetail);
