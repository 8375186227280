import SweetAlert from 'react-bootstrap-sweetalert';

function ConfirmationPopup({
  visible,
  title,
  subtitle,
  handleSuccess,
  handleReject,
  threeActions = false,
  btnLeft = null,
  btnRight,
  btnMiddle,
  handleLeft,
  handleRight,
  handleMiddle,
}) {
  if (threeActions)
    return (
      <SweetAlert show={visible} title="" showCancel={false} showConfirm={false}>
        <div className="d-col text-left">
          <span className="heading-medium">{title}</span>
          <small className="pt-4 input-label">{subtitle}</small>

          <div className="flex justify-end items-end content-end mt-4">
            {btnLeft && (
              <button className="btn-purple text-xs px-8 mr-2" onClick={handleLeft}>
                {btnLeft}
              </button>
            )}
            {btnMiddle && (
              <button className="btn-purple text-xs px-8 mr-2" onClick={handleMiddle}>
                {btnMiddle}
              </button>
            )}

            <button className="btn-outline text-xs px-8 border-gray-500" onClick={handleRight}>
              {btnRight}
            </button>
          </div>
        </div>
      </SweetAlert>
    );
  else
    return (
      <SweetAlert show={visible} title="" showCancel={false} showConfirm={false}>
        <div className="d-col text-left">
          <span className="heading-medium">{title}</span>
          <small className="pt-4 input-label">{subtitle}</small>

          <div className="flex justify-end items-end content-end mt-4">
            <button className="btn-purple text-xs px-8 mr-2" onClick={handleSuccess}>
              {btnLeft ? btnLeft : 'Yes'}
            </button>
            <button className="btn-outline text-xs px-8 border-gray-500" onClick={handleReject}>
              {btnRight ? btnRight : 'No'}
            </button>
          </div>
        </div>
      </SweetAlert>
    );
}

export default ConfirmationPopup;
